import { Box, Button, Checkbox, Collapse, Divider, Flex, FormControl, FormHelperText, FormLabel, Grid, Input, Spacer, Text, Textarea, useDisclosure, useToast } from "@chakra-ui/react";
import { faFile, faPaperclip, faTags } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "date-fns";
import LoremIpsum from "react-lorem-ipsum";
import { ICategory, IDossierRequest, pollTypeToString } from "../../types";
import { useRef, useState } from "react";
import { manageDossierRequest } from "../../API/dossier";
import { CheckBox } from "@syncfusion/ej2/buttons";
import { downloadFile } from "../../API/file";

export interface PendingDossierCardProps {
    dossier:IDossierRequest;
    categories:ICategory[];
    handle_callback:Function;
}

const PendingDossierCard: React.FC<PendingDossierCardProps> = ({dossier, categories, handle_callback}) => {
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
    const categoriesString = categories.map((category:ICategory) => category.name).join(", ");
    const reason = useRef<HTMLTextAreaElement>(null);
    const [refusing, setRefusing] = useState<boolean>(false);
    const toast = useToast();
    const [isBureau, setIsBureau] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const handleRefuse = () => {
        setSubmitting(true);
        manageDossierRequest(dossier._id, false, reason.current?.value ?? "", false, highPriorityAccepted)
            .then((resp) => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                toast({
                    title: "Demande refusée",
                    description: "La demande a bien été refusée",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                handle_callback(dossier._id);
                onClose();
            })
            .catch((err) => {})
            .finally(() => {
                setSubmitting(false);
            });
    }

    const handleAccept = () => {
        setSubmitting(true);
        manageDossierRequest(dossier._id, true, "", isBureau, highPriorityAccepted)
            .then((resp) => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                toast({
                    title: "Demande acceptée",
                    description: "La demande a bien été acceptée",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                handle_callback(dossier._id);
                onClose();
            })
            .catch((err) => {})
            .finally(() => {
                setSubmitting(false);
            });
    }

    const download_file = (file_id:string) => {
        downloadFile(file_id).then((resp) => {
          const contentDisposition = resp.headers['content-disposition'];
          const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        });
    }

    const open_card_wrapper = () => {
        onToggle();
    }

    const [highPriorityAccepted, setHighPriorityAccepted] = useState<boolean>(false);
    const update_priority = (value:boolean) => {
        setHighPriorityAccepted(value);
    }

    return <Box mb={5} onClick={isOpen ? () => {}: open_card_wrapper} cursor={isOpen ? "default" : "pointer"} width={"100%"} bgColor={"white"} p={6} pb={5} border={"1pt solid #e2e2dd"} position={'relative'}>
    <Flex>
        <Text letterSpacing={"-.01rem"} lineHeight={"1.2"} fontWeight={'700'} fontSize={'lg'} color={"#00707f"}>{dossier.title}</Text>
        <Spacer />
        <Text cursor={'pointer'} onClick={() => { if (isOpen) onClose();}} color={"#00707f"} fontSize={"xs"} fontWeight={900} pt={2} pe={2}>
            {isOpen ? "FERMER" : "CONSULTER"}
        </Text>
    </Flex>
    <Text fontWeight={'400'} fontSize={'sm'} color={"#7f8591"}>Demandé par {dossier.author} le {formatDate(dossier.createdAt ?? new Date(), "dd/MM/yyyy à HH:mm")}</Text>

    {dossier.high_priority &&
        <Text pt={2} fontWeight={'700'} fontSize={'sm'} color={"#ba0105"}>HAUTE PRIORITÉ DEMANDÉE</Text>
    }

    <Collapse in={isOpen} animateOpacity>
        <Divider mt={5} mb={5} />

        {dossier.deadline &&
        <Text>
            Clôture du dossier demandée pour le <Text as={"span"} fontWeight={'700'} color={"#ba0105"}>{formatDate(dossier.deadline ?? new Date(), "dd/MM/yyyy à HH:mm")}</Text>
        </Text>
        }
            
        {!dossier.deadline &&
        <Text>
            Clôture manuelle demandée (pas de deadline indiquée)
        </Text>
        }

        <Box mt={8}>
            <Text mb={3} ms={3} color={"#004851"} fontWeight={'800'} fontSize={'sm'}>DESCRIPTION</Text>
            <Box mb={5} height={'4px'} bgColor={"#004851"} width={'100%'} />
        </Box>

        <Box ms={2} mb={2}>
            Catégories: <Text as={"span"} fontWeight={'700'} color={"#004851"}>{categoriesString}</Text>
        </Box>

        <Box border={"1pt solid #e2e2dd"} bgColor={"white"} p={5} textAlign={"justify"}>
            <Box dangerouslySetInnerHTML={{__html: dossier.description}} />
        </Box>

        <Box ps={2} pt={2}>
            <Text fontSize={'sm'}><FontAwesomeIcon style={{marginRight:"3px", position:"relative", top:"2px"}} icon={faTags} /> <Text as={"span"} fontWeight={'700'}>Mots-clés: </Text> {dossier.tags.join(", ")}</Text>
        </Box>

        {dossier.attached_files.length > 0 &&
        <Box p={3} mt={2}>
            <Text fontSize={'lg'} fontWeight={'700'} color={"#314e89"} mb={2}>
                <FontAwesomeIcon icon={faPaperclip} style={{marginRight:"5px"}} />Fichiers joints
            </Text>
            <Grid templateColumns='repeat(4, 2fr)' gap={6}>
                {dossier.attached_files.map((attachment, index) => {
                    return <Box key={index} cursor={'pointer'} bgColor={'white'} p={3} textAlign={'center'} border={"1pt solid #e2e2dd"} onClick={() => {download_file(attachment.id)}}>
                        <Text fontWeight={'400'} ><FontAwesomeIcon icon={faFile} style={{marginRight:"5px"}} />{attachment.name}</Text>
                    </Box>
                })}
            </Grid>
        </Box>
        }

        
        <Box mt={8}>
            <Text mb={3} ms={3} color={"#004851"} fontWeight={'800'} fontSize={'sm'}>SONDAGES</Text>
            <Box mb={5} height={'4px'} bgColor={"#004851"} width={'100%'} />
        </Box>

        {dossier.polls.length > 0 && dossier.polls.map((poll, index) => {
            return <Box key={index} border={"1pt solid #e2e2dd"} p={3} mt={3} rounded={"md"}>
                <Flex>
                    <Text fontWeight={'800'} fontSize={'md'}>{poll.title}</Text>
                    <Spacer />
                </Flex>
                <Text>{poll.description}</Text>
                <Text>{pollTypeToString(poll.type)}</Text>
                <Text>{poll.options.join(", ")}</Text>
                <Text>{formatDate(poll.deadline, "dd/MM/yyyy à HH:mm")}</Text>
            </Box>
        })}
        
        {dossier.polls.length == 0 && <Text>Aucun sondage n'a été ajouté à cette demande</Text>}

        
        {dossier.high_priority && <>
            <Box mt={8}>
                <Text mb={3} ms={3} color={"#004851"} fontWeight={'800'} fontSize={'sm'}>HAUTE PRIORITÉ</Text>
                <Box mb={5} height={'4px'} bgColor={"#004851"} width={'100%'} />
            </Box>

            <Text mb={3}>
                Cette demande a été marquée comme <Text as={"span"} fontWeight={'700'} color={"#ba0105"}>haute priorité</Text>. Voici sa justification :
            </Text>

            <Box border={"1pt solid #e2e2dd"} bgColor={"white"} p={5}>
                {dossier.priority_reason}
            </Box>

            <FormControl mt={3} ms={2}>
                <FormLabel>Accepter la haute priorité ?</FormLabel>
                <Checkbox ms={2} colorScheme="red" isChecked={highPriorityAccepted} onChange={(e) => {update_priority(e.target.checked);}} mb={3}>
                    Accepter
                </Checkbox>
            </FormControl>
        </>}

        <Box mt={8}>
            <Text mb={3} ms={3} color={"#5b257d"} fontWeight={'800'} fontSize={'sm'}>ACTIONS</Text>
            <Box mb={5} height={'4px'} bgColor={"#5b257d"} width={'100%'} />
        </Box>

        {!refusing &&
            <Checkbox 
                colorScheme="red" 
                isChecked={isBureau} 
                onChange={(e) => {setIsBureau(e.target.checked);}} 
                mb={3}>
                    Dossier issu du Bureau ADERE
            </Checkbox>
        }

        {!refusing &&
        <Flex>
            <Spacer />
            <Button 
                p={6} m={3} mt={0} me={0} color={"white"} _hover={{backgroundColor:"#d92a2e"}} bgColor={"#b32326"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={() => setRefusing(true)} isDisabled={submitting} >
                REFUSER
            </Button>
            <Button 
                p={6} m={3} ms={2} mt={0} color={"white"} _hover={{backgroundColor:"#6b2b95"}} bgColor={"#5b257d"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={handleAccept} isDisabled={submitting} >
                ACCEPTER
            </Button>
        </Flex>
        }

        {refusing && <Box>
            <FormControl p={2}>
                <Textarea ref={reason} maxLength={1000} placeholder="Raison du refus" />
                <FormHelperText>Cette raison sera communiquée à {dossier.author} - Max. 1000 caractères</FormHelperText>
            </FormControl>
            <Flex>
                <Spacer />
                <Button 
                    p={6} m={3} me={0} mt={0} color={"white"} _hover={{backgroundColor:"#737884"}} bgColor={"#52565e"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={() => setRefusing(false)}>
                    RETOUR
                </Button>
                <Button 
                    p={6} m={3} mt={0} color={"white"} _hover={{backgroundColor:"#d92a2e"}} bgColor={"#b32326"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={handleRefuse} isDisabled={submitting} >
                    REFUSER
                </Button>
            </Flex>
        </Box>
        }
    </Collapse>
</Box>
}

export default PendingDossierCard;