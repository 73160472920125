import { Box, Divider, Image, Skeleton, Text } from '@chakra-ui/react';
import { IArticle } from '../types';
import { Link as ReactLink} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { downloadFile } from '../API/file';

export interface ArticleCardProps {
    article:IArticle
}

const ArticleCard: React.FC<ArticleCardProps> = ({article}) => {
    const [b64Image, setB64Image] = useState<string>("");
    const [htmlText, setHtmlText] = useState<string>("");
    const [loadingPicture, setLoadingPicture] = useState<boolean>(true);

    useEffect(() => {
        downloadFile(article.picture).then((res) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                setB64Image(reader.result as string);
            };
            reader.readAsDataURL(res.data);
            setLoadingPicture(false);
        });

        // Get the first 100 characters of the content, without HTML tags
        const regex = /(<([^>]+)>)/ig;
        setHtmlText(article.content.replace(regex, ""));
    }, []);

    return <ReactLink to={`/news/${article.slug}`}>
    <Box position={"relative"}>
    
        {!loadingPicture &&
            <Image src={b64Image} position={"absolute"} maxWidth={"320px"} minWidth={"320px"} top={"-100px"} left={["12px", "-15px"]} zIndex={-1} />
        }
        {loadingPicture && 
            <Skeleton height={"180px"} width={"300px"} position={"absolute"} top={"-100px"} left={"-10px"} zIndex={-1} />
        }
        
        <Box 
            boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} 
            className='hvr-float' 
            border={"1pt solid #e2e2dd"} 
            bgColor={"white"} 
            cursor={'pointer'} 
            zIndex={2} 
            maxWidth={"300px"} 
            minWidth={["100%", "290px"]} 
            minHeight={"500px"} 
            maxHeight={"500px"} 
            style={{display:"flex", flexDirection:"column"}}
        >
            <Box p={8} width={"100%"} height={"100%"}>
                {/* HEADER */}
                <Text fontWeight={'600'} fontSize={'xs'} color={"#5fa4b0"} letterSpacing={"0.2em"} textTransform={"uppercase"}>{article.category}</Text>

                {/* TITLE */}
                <Text fontWeight={'900'} letterSpacing={"-.05rem"} fontSize={'18pt'} color={"#00707f"} lineHeight={"1.2"} mb={3}>{article.title}</Text>

                {/* CONTENT */}
                <Box maxHeight={"270px"} overflowY={"hidden"}>
                <Text color={"#999"} textAlign={"justify"}>
                    {htmlText}
                </Text>
                </Box>

                <Divider mt={4} />

                {/* FOOTER */}
                <Text color={"#00707f"} fontSize={"xs"} fontWeight={900} position={"absolute"} bottom={"20px"}>
                EN SAVOIR +
                </Text>
            </Box>
        </Box>
    </Box>
  </ReactLink>
}

export default ArticleCard;