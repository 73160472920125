import { CloseIcon, HamburgerIcon, SearchIcon } from "@chakra-ui/icons";
import { Box, Button, Center, Circle, Container, Flex, HStack, IconButton, Image, Input, InputGroup, InputLeftElement, Menu, MenuItem, MenuList, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Stack, Text, Tooltip, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Link as ReactLink, useLocation} from 'react-router-dom';
import logo from './resources/adere_logo.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faComment, faEnvelope, faFolderOpen, faHouse, faNewspaper, faSquarePollVertical, faUser, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "./UserContext";
import { Notifications } from "./Notifications";

const NavLink = ({ children }: { children: ReactNode }) => (
    <Box
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}>
      {children}
    </Box>
  );

interface Props {
	children: React.ReactNode;
}

function DossierSearch() {
    const searchRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        // Listen for enter key
        const handleEnter = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                window.location.href = "/search/" + searchRef.current?.value;
            }
        };
        // add the event on searchRef
        searchRef.current?.addEventListener("keydown", handleEnter);
    }, []);

    return (
        <InputGroup width={{ base: '100%', md: '350px' }}>
            <InputLeftElement pointerEvents='none' height={"45px"}>
                <SearchIcon color='gray.300' />
            </InputLeftElement>
            <Input
                id="search-dossier"
                ref={searchRef}
                height={"45px"}
                rounded={'90'}
                fontSize={'sm'}
                letterSpacing={'-.1px'}
                placeholder='Rechercher un dossier' 
                bgColor={"#eeeeee"}
            />
        </InputGroup>
    );
}

export const Layout: React.FunctionComponent<Props> = (props: Props) => {
const { isOpen, onOpen, onClose } = useDisclosure();
const { user } = useUser();
const location = useLocation();
const { pathname } = location;

return (<>
    {process.env.REACT_APP_DEBUG === "true" &&
        <Box width={'100%'} bgColor={'orange.100'} p={1} fontSize={'xs'} fontWeight={800}><Center>LOCAL</Center></Box>
    }
    <Flex zIndex={1} as="header" position="fixed" h={'100px'} w="100%" bg={"white"} px={4} boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} pt={5} ps={8} draggable={false}>
    <Box w='100%'>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        <IconButton
            me={2}
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ lg: 'none' }}
            onClick={isOpen ? onClose : onOpen}
        />
        
        <HStack spacing={8} alignItems={'center'}>
            <Box width={"180px"} display={{ base: 'none', lg: 'block' }}>
                <ReactLink to={'/'} onClick={() => {window.scrollTo(0, 0);}}>
                    <Image src={logo} width={'160px'} />
                </ReactLink>
            </Box>

            {/* 100% on small screen, 350px on md */}
            {DossierSearch()}

            <HStack
                as={'nav'}
                spacing={4}
                display={{ base: 'none', lg: 'flex' }}>
                <ReactLink to={"/"} onClick={() => {window.scrollTo(0, 0);}}>
                    <Tooltip p={3} offset={[0,15]} rounded={'xl'} label="Accueil" aria-label="Accueil">
                        <Circle className="hvr-grow" bgColor={(pathname == "/") ? "#d2e3ff" : "#f5f5f5"} p={3} color={(pathname == "/") ? "#1e74fd" : "#adb5bd"}>
                            <FontAwesomeIcon icon={faHouse} />
                        </Circle>
                    </Tooltip>
                </ReactLink>
                <ReactLink to={"/dossiers"} onClick={() => {window.scrollTo(0, 0);}}>
                    <Tooltip rounded={'xl'} p={3} offset={[0,15]} label="Dossiers" aria-label="Dossiers">
                        <Circle className="hvr-grow" bgColor={(pathname.includes("/dossiers")) ? "#d2e3ff" : "#f5f5f5"} p={3} color={(pathname.includes("/dossiers")) ? "#1e74fd" : "#adb5bd"}>
                            <FontAwesomeIcon icon={faFolderOpen} />
                        </Circle>
                    </Tooltip>
                </ReactLink>
                <ReactLink to={"/sondages"} onClick={() => {window.scrollTo(0, 0);}}>
                    <Tooltip rounded={'xl'} p={3} offset={[0,15]} label="Sondages" aria-label="Sondages">
                        <Circle className="hvr-grow" bgColor={(pathname.includes("/sondages")) ? "#d2e3ff" : "#f5f5f5"} p={3} color={(pathname.includes("/sondages")) ? "#1e74fd" : "#adb5bd"}>
                            <FontAwesomeIcon icon={faSquarePollVertical} />
                        </Circle>
                    </Tooltip>
                </ReactLink>
                <ReactLink to={"/forum"} onClick={() => {window.scrollTo(0, 0);}}>
                    <Tooltip rounded={'xl'} p={3} offset={[0,15]} label="Forum" aria-label="Forum">
                        <Circle className="hvr-grow" bgColor={(pathname.includes("/forum")) ? "#d2e3ff" : "#f5f5f5"} p={3} color={(pathname.includes("/forum")) ? "#1e74fd" : "#adb5bd"}>
                            <FontAwesomeIcon icon={faComment} />
                        </Circle>
                    </Tooltip>
                </ReactLink>
                <ReactLink to={"/news"} onClick={() => {window.scrollTo(0, 0);}}>
                    <Tooltip rounded={'xl'} p={3} offset={[0,15]} label="ADERE News" aria-label="ADERE News">
                        <Circle className="hvr-grow" bgColor={(pathname.includes("/news")) ? "#d2e3ff" : "#f5f5f5"} p={3} color={(pathname.includes("/news")) ? "#1e74fd" : "#adb5bd"}>
                            <FontAwesomeIcon icon={faNewspaper} />
                        </Circle>
                    </Tooltip>
                </ReactLink>
                <ReactLink to={"/contact"} onClick={() => {window.scrollTo(0, 0);}}>
                    <Tooltip rounded={'xl'} p={3} offset={[0,15]} label="Contact Bureau" aria-label="Contact Bureau">
                        <Circle className="hvr-grow" bgColor={(pathname.includes("/contact")) ? "#d2e3ff" : "#f5f5f5"} p={3} color={(pathname.includes("/contact")) ? "#1e74fd" : "#adb5bd"}>
                            <FontAwesomeIcon icon={faEnvelope} />
                        </Circle>
                    </Tooltip>
                </ReactLink>

                {user?.rank == "admin" &&
                    <ReactLink to={"/bureau"} onClick={() => {window.scrollTo(0, 0);}}>
                        <Tooltip rounded={'xl'} p={3} offset={[0,15]} label="Bureau" aria-label="Bureau">
                            <Circle className="hvr-grow" bgColor={(pathname == "/bureau") ? "#d2e3ff" : "#b44e50"} p={3} color={(pathname == "/bureau") ? "#1e74fd" : "#610002"}>
                                <FontAwesomeIcon icon={faUserTie} />
                            </Circle>
                        </Tooltip>
                    </ReactLink>
                }
            </HStack>
        </HStack>

        <Flex alignItems={'center'}>
            <Menu>
                {Notifications("")}

                <ReactLink to={"/account"} onClick={() => {window.scrollTo(0, 0);}}>
                    <Tooltip rounded={'xl'} p={3} offset={[0,15]} label="Mon compte" aria-label="Mon compte">
                        <Circle className="hvr-grow" bgColor={(pathname == "/account") ? "#d2e3ff" : "#f5f5f5"} p={3} color={(pathname == "/account") ? "#1e74fd" : "#adb5bd"}>
                            <FontAwesomeIcon icon={faUser} />
                        </Circle>
                    </Tooltip>
                </ReactLink>
            </Menu>
        </Flex>
        </Flex>

        {isOpen ? (
        <Box pb={4} display={{ lg: 'none' }}>
            <Stack as={'nav'} spacing={4} bgColor={"white"} p={4} border={"1pt solid #e2e2dd"} fontSize={"lg"}>
                <ReactLink to={"/"}>
                    <NavLink><Text p={2} fontWeight={700}>Accueil</Text></NavLink>
                </ReactLink>
                <ReactLink to={"/dossiers"}>
                    <NavLink><Text p={2} fontWeight={700}>Dossiers</Text></NavLink>
                </ReactLink>
                <ReactLink to={"/forum"}>
                    <NavLink><Text p={2} fontWeight={700}>Forum</Text></NavLink>
                </ReactLink>
                <ReactLink to={"/news"}>
                    <NavLink><Text p={2} fontWeight={700}>ADERE News</Text></NavLink>
                </ReactLink>
                <ReactLink to={"/contact"}>
                    <NavLink><Text p={2} fontWeight={700}>Contact Bureau</Text></NavLink>
                </ReactLink>
                {user?.rank == "admin" &&
                    <ReactLink to={"/bureau"}>
                        <NavLink><Text color={"#610002"} p={2} fontWeight={700}>Bureau</Text></NavLink>
                    </ReactLink>
                }
            </Stack>
        </Box>
        ) : null}
    </Box>
    </Flex>
    
    <Box zIndex={0} position={"relative"} top={"80px"} p={4}>
        <Container mt={8} maxW='7xl'>
            {props.children}
        </Container>
    </Box>
</>);
}