import { AbsoluteCenter, Box, Button, Center, Spinner, Text } from "@chakra-ui/react";
import { Image as ChakraImage } from "@chakra-ui/react";
import logo from './resources/adere_logo.svg';
import { useEffect, useState } from "react";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Login: React.FunctionComponent = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Set loading to false after 0.5 second
        setTimeout(() => {
            setLoading(false);
        }, 500);

        // Preload the image
        const img = new Image();
        img.src = logo;
    }, []);
return (<>

    {loading &&
        <AbsoluteCenter mt={"250px"}>
            <Spinner
                thickness='5px'
                speed='0.65s'
                emptyColor='gray.200'
                color='#a8589e'
                size='xl'
            />
        </AbsoluteCenter>
    }
    
    {!loading &&
        <Box>
            <Center mt={20}>
            <ChakraImage src={logo} width={'300px'} className='noselect' />
            </Center>

            <Center mt={"40px"} mb={"30px"}>
                <Text color={"#131313"}>Veuillez vous connecter à votre compte ULiège pour accéder à l'ADERE.</Text>
            </Center>

            <Center>
                <Button
                onClick={() => {
                    window.location.href = process.env.REACT_APP_LOGIN_URL || "/";
                }}
                p={6} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                leftIcon={<FontAwesomeIcon icon={faRightToBracket} />} >
                CONNEXION
                </Button>
            </Center>
        </Box>
    }
</>);
}