import { useEffect, useState } from 'react';
import '../Home.css';
import { Avatar, Box, Button, Center, Flex, Grid, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Skeleton, SkeletonText, Spacer, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { Layout } from '../Layout';
import { IElection, IElectionCandidate, IElectionVote } from '../types';
import { useUser } from '../UserContext';
import { faCheckToSlot, faCircleInfo, faClipboardCheck, faScroll } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCandidates, getCurrentElection, getMyVote, submitMyVote, updateVote } from '../API/elections';
import { differenceInDays, differenceInHours, differenceInMinutes, formatDate } from 'date-fns';
import { fr } from 'date-fns/locale';
import { getProfilePicture } from '../API/file';


interface ConfirmProps {
  isOpen:boolean;
  onOpen:any;
  onClose:any;
  votes:IElectionVote;
  submitVote:Function;
  candidates:IElectionCandidate[];
}

const ConfirmVote: React.FunctionComponent<ConfirmProps> = ({isOpen, onOpen, onClose, votes, submitVote, candidates}) => {

  const [sortedCandidates, setSortedCandidates] = useState<IElectionCandidate[]>([]);
  useEffect(() => {
    // Sort the candidates by number of points
    const sortedCandidates = [...candidates].sort((a, b) => {
      const pointsA = votes.choices.find(choice => choice.candidate === a._id)?.points || 0;
      const pointsB = votes.choices.find(choice => choice.candidate === b._id)?.points || 0;
      return pointsB - pointsA;
    });
    setSortedCandidates(sortedCandidates);
  }, [votes, candidates]);

  return (<Modal 
    onClose={onClose} 
    size={"4xl"} 
    isOpen={isOpen}
    motionPreset='scale'
    closeOnOverlayClick={true}
    >
      <ModalOverlay backdropFilter='blur(5px)' />
      <ModalContent>
        <ModalHeader>
          <Text letterSpacing={'-.1rem'} fontSize={'3xl'} fontWeight={900} color={"#4a4a4a"} >
          Confirmation de votre vote
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={5}>
          <Text color={"#131313"} lineHeight={'1.5'} mb={3}>
            Vous avez attribué les points suivants à chaque candidat :
          </Text>
          <Box border={"1pt solid #e2e2dd"} p={5} textAlign={"justify"}>
            {sortedCandidates.map((candidate, index) => {
              const points = votes.choices.find(choice => choice.candidate === candidate._id)?.points || 0;
              return <Text color={"#131313"} key={index} mb={2}><Text fontWeight={700} as={"span"}>{candidate.user.firstname + " " + candidate.user.lastname}</Text> : {points} point{points > 1 ? "s" : ""}</Text>
            })}
          </Box>
          <Flex>
            <Spacer />
            <Button
              width={'fit-content'} p={6} m={3} me={0} color={"white"} _hover={{backgroundColor:"#b2b0a5"}} bgColor={"#8c8b82"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={onClose}>
                RETOUR
            </Button>
            <Button
              width={'fit-content'} p={6} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={() => submitVote()}>
                CONFIRMER MON VOTE
            </Button>
          </Flex>
        </ModalBody>
        </ModalContent>
    </Modal>);
}


function CandidateModal(isOpen:boolean, onOpen:any, onClose:any, candidate:IElectionCandidate) {

  const [b64Image, setB64Image] = useState<string>("");
  useEffect(() => {
    getProfilePicture(candidate.user.matricule).then((res) => {
      const reader = new FileReader();
      reader.onloadend = () => {
          setB64Image(reader.result as string);
      };
      reader.readAsDataURL(res.data);
  }).catch((err) => {
  });
  }, []);

  return (<Modal 
    onClose={onClose} 
    size={"4xl"} 
    isOpen={isOpen}
    motionPreset='scale'
    closeOnOverlayClick={true}
    >
      <ModalOverlay backdropFilter='blur(5px)' />
      <ModalContent>
        <ModalHeader>
          <Text letterSpacing={'-.1rem'} fontSize={'3xl'} fontWeight={900} color={"#4a4a4a"} >
            {candidate.user.firstname + " " + candidate.user.lastname}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={5}>

          <Center>
            <Avatar
              mb={8}
              size='2xl'
              name={candidate.user.firstname + " " + candidate.user.lastname}
              src={b64Image}
              fontWeight={'700'}
            />
          </Center>

          <Box border={"1pt solid #e2e2dd"} p={8} textAlign={"justify"} dangerouslySetInnerHTML={{__html: candidate.application}} />

        </ModalBody>
        </ModalContent>
    </Modal>);
}

export interface CandidateCardProps {
  candidate:IElectionCandidate;
  myVotes:number;
  vote_callback:Function;
  voteDisabled:boolean;
  availablePoints:number[];
}

const CandidateCard: React.FC<CandidateCardProps> = ({candidate, myVotes, vote_callback, voteDisabled, availablePoints}) => {
  const modal_disclosure = useDisclosure();

  const [b64Image, setB64Image] = useState<string>("");
  useEffect(() => {
    getProfilePicture(candidate.user.matricule).then((res) => {
      const reader = new FileReader();
      reader.onloadend = () => {
          setB64Image(reader.result as string);
      };
      reader.readAsDataURL(res.data);
  }).catch((err) => {
  });
  }, []);

  return <Box p={10} boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} border={"1pt solid #e2e2dd"} bgColor={"white"}>
    {/* {CandidateModal(candidate.user.firstname == "Marilaure" ? true : modal_disclosure.isOpen, modal_disclosure.onOpen, modal_disclosure.onClose, candidate)} */}
    {CandidateModal(modal_disclosure.isOpen, modal_disclosure.onOpen, modal_disclosure.onClose, candidate)}
  <Center>
    <Avatar
      mb={4}
      size='2xl'
      name={candidate.user.firstname + " " + candidate.user.lastname}
      src={b64Image}
      fontWeight={'700'}
    />
  </Center>
  <Center>
    <Text letterSpacing={'-.1rem'} fontSize={'3xl'} fontWeight={900} color={"#00707f"} >
      {candidate.user.firstname + " " + candidate.user.lastname}
    </Text>
  </Center>

  <Flex>
    <Button p={6} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={5} w={"100%"} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
    leftIcon={<FontAwesomeIcon icon={faScroll} />} onClick={modal_disclosure.onOpen} >CANDIDATURE</Button>

    <Menu closeOnSelect={false}>
      <MenuButton isDisabled={voteDisabled} p={6} m={3} color={"white"} _hover={{backgroundColor:"#704e91"}} _selected={{backgroundColor:"#704e91"}} _active={{backgroundColor:"#704e91"}} bgColor={"#533a6b"} size="md" mt={5} w={"100%"} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} as={Button} leftIcon={<FontAwesomeIcon icon={faCheckToSlot} />}>
        {myVotes == 0 ? "VOTER" : myVotes + " POINT"+(myVotes > 1 ? "S" : "")}
      </MenuButton>

      <MenuList minWidth='240px'>
        <MenuOptionGroup value={(myVotes > 0) ? myVotes.toString() : "0"} title='Points' type='radio'>
          <MenuItemOption isChecked={myVotes === 0} p={3} value='0' onClick={() => vote_callback(candidate._id, 0)}>Abstention</MenuItemOption>

          <MenuItemOption 
            isDisabled={availablePoints.indexOf(1) === -1}
            isChecked={myVotes === 1} p={3} value='1' onClick={() => vote_callback(candidate._id, 1)}>
              1 point
            </MenuItemOption>

          <MenuItemOption 
            isDisabled={availablePoints.indexOf(2) === -1}
            p={3} value='2' onClick={() => vote_callback(candidate._id, 2)}>2 points</MenuItemOption>
          
          <MenuItemOption 
            isDisabled={availablePoints.indexOf(3) === -1}
            p={3} value='3' onClick={() => vote_callback(candidate._id, 3)}>3 points</MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  </Flex>

</Box>
}

function Elections() {
  const { user } = useUser();
  const [election, setElection] = useState<IElection|null>(null);
  const [candidates, setCandidates] = useState<IElectionCandidate[]>([]);
  const [myVote, setMyVote] = useState<IElectionVote|null>(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [votingCandidate, setVotingCandidate] = useState<string>("");
  const toast = useToast();
  const [deadlinePassed, setDeadlinePassed] = useState(false);
  const confirm_disclosure = useDisclosure();
  const [availablePoints, setAvailablePoints] = useState([1, 2, 3]);

  useEffect(() => {
    getCurrentElection().then((res) => {
      const election_resp = res.data.election;
      // passed deadline?
      if (election_resp.deadline <= new Date()) {
        setDeadlinePassed(true);
      }

      // Is election open?
      if (!election_resp.open) {
        // redirect to home
        window.location.href = "/";
        return;
      }

      setElection(res.data.election);
        getCandidates(res.data.election._id).then((res) => {
          const candidates_resp = res.data.electionCandidates;
          // Sort the candidates by alphabetical order (on the lastname)
          candidates_resp.sort((a, b) => {
            if (a.user.lastname < b.user.lastname) {
              return -1;
            } else if (a.user.lastname > b.user.lastname) {
              return 1;
            } else {
              return 0;
            }
          });
          setCandidates(candidates_resp);
          
          getMyVote(election_resp._id).then((res) => {
            let myVoteResponse = res.data.electionVote;
            
            // If some of the candidates are not in the .choices, add them with a 0 points vote
            for (let i = 0; i < candidates_resp.length; i++) {
              const candidate = candidates_resp[i];
              const candidateVote = myVoteResponse.choices.find((choice) => choice.candidate === candidate._id);
              if (!candidateVote) {
                myVoteResponse.choices.push({
                  candidate: candidate._id,
                  points: 0,
                });
              }
            }

            // Update the available points (if there is a 1 vote, remove the 1, if there is a 2 vote, remove the 2, etc.)
            const newAvailablePoints = [1, 2, 3].filter((point) => {
              return myVoteResponse.choices.find((choice) => choice.points === point) === undefined;
            });
            setAvailablePoints(newAvailablePoints);

            setMyVote(myVoteResponse);
            setLoading(false);
          });
        });
    }).catch((err) => {
      window.location.href = "/";
    });
  }, []);

  const handleVote = async (candidate_id: string, points: number) => {
    if (!myVote || !election) return;

    setVotingCandidate(candidate_id);
    const newChoices = myVote.choices.map((choice) => {
      if (choice.candidate === candidate_id) {
        return {
          candidate: candidate_id,
          points: points,
        }
      } else {
        return choice;
      }
    });

    const newVote = {
      ...myVote,
      choices: newChoices,
    };

    
    // Update the available points (if there is a 1 vote, remove the 1, if there is a 2 vote, remove the 2, etc.)
    const newAvailablePoints = [1, 2, 3].filter((point) => {
      return newChoices.find((choice) => choice.points === point) === undefined;
    });
    setAvailablePoints(newAvailablePoints);

    // Update the vote in the database
    await updateVote(election._id, newVote).then((resp) => {
      setMyVote(newVote);
    }).catch((err) => {
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la sauvegarde de votre vote: " + err.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    }).finally(() => {
      setVotingCandidate("");
    });
  }

  const myVotePerCandidate = (candidate_id: string) => {
    if (!myVote) return 0;
    const candidateVote = myVote.choices.find((choice) => choice.candidate === candidate_id);
    if (candidateVote) {
      return candidateVote.points;
    } else {
      return 0;
    }
  }

  const blank_vote = () => {
    if (!election || !myVote) return;
    setSubmitting(true);

    const newVote = {
      ...myVote,
      choices: myVote.choices.map((choice) => {
        return {
          candidate: choice.candidate,
          points: 0,
        }
      }),
    };

    // Update the available points (if there is a 1 vote, remove the 1, if there is a 2 vote, remove the 2, etc.)
    const newAvailablePoints = [1, 2, 3].filter((point) => {
      return newVote.choices.find((choice) => choice.points === point) === undefined;
    });
    setAvailablePoints(newAvailablePoints);

    // Update the vote in the database
    updateVote(election._id, newVote).then((resp) => {
      // Submit the vote
      submitMyVote(election._id).then((res) => {
        toast({
          title: "Vote soumis",
          description: "Votre vote a bien été soumis.",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
        setMyVote({
          ...myVote,
          submitted: true,
        });
      }).catch((err) => {
        toast({
          title: "Erreur",
          description: "Une erreur est survenue lors de la soumission de votre vote: " + err.response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      });
    }).catch((err) => {
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la sauvegarde de votre vote: " + err.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    }).finally(() => {
      setSubmitting(false);
    });
  }

  const submit_vote = () => {
    if (!election || !myVote) return;

    // Check if all the votes are valid (1, 2 or 3 points)
    for (let i = 0; i < myVote.choices.length; i++) {
      if (myVote.choices[i].points < 0 || myVote.choices[i].points > 3) {
        toast({
          title: "Erreur",
          description: "Veuillez attribuer 1, 2, ou 3 points, ou vous abstenir.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
        return;
      }
    }

    setSubmitting(true);
    submitMyVote(election._id).then((res) => {
      toast({
        title: "Vote soumis",
        description: "Votre vote a bien été soumis.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      setMyVote({
        ...myVote,
        submitted: true,
      });
    }).catch((err) => {
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la soumission de votre vote: " + err.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    }).finally(() => {
      setSubmitting(false);
    });
  }

  const getFrenchRemainingTime = (date: Date) => {
    const now = new Date();
    const remainingDays = differenceInDays(date, now);
    if (remainingDays > 0) {
      return remainingDays + " jour" + (remainingDays > 1 ? "s" : "");
    } else {
      const remainingHours = differenceInHours(date, now);
      if (remainingHours > 0) {
        return remainingHours + " heure" + (remainingHours > 1 ? "s" : "");
      } else {
        const remainingMinutes = differenceInMinutes(date, now);
        return remainingMinutes + " minute" + (remainingMinutes > 1 ? "s" : "");
      }
    }
  }

  const [blankVote, setBlankVote] = useState(false);
  const confirm_vote = async (blank:boolean) => {
    if (!myVote || !election) return;
    setBlankVote(blank);

    if (blank) {
      // set 0 points to all candidates
      for(let i = 0; i < candidates.length; i++)
        await handleVote(candidates[i]._id, 0);

      const newVote = {
        ...myVote,
        choices: myVote.choices.map((choice) => {
          return {
            candidate: choice.candidate,
            points: 0,
          }
        }),
      };

      setAvailablePoints([1, 2, 3]);
      updateVote(election._id, newVote).then((resp) => {
        setMyVote(newVote);
        confirm_disclosure.onOpen();
      });
    } else {
      confirm_disclosure.onOpen();
    }
  }

  const confirm_vote_callback = () => {
    if (blankVote) {
      blank_vote();
    } else {
      submit_vote();
    }
    confirm_disclosure.onClose();
  }

  return (<>
    <Layout>
    {myVote && <ConfirmVote isOpen={confirm_disclosure.isOpen} onOpen={confirm_disclosure.onOpen} onClose={confirm_disclosure.onClose} votes={myVote} submitVote={confirm_vote_callback} candidates={candidates} />}

    <Text fontWeight={'900'} color={"#e6e6e1"} fontSize={'7xl'} letterSpacing={"-.1rem"} mb={5} mt={5}>Élections</Text>

    <Center>
      <Box boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} border={"1pt solid #e2e2dd"} bgColor={"white"} p={10} w={'80%'}>
        <Text fontWeight={'900'} color={"#4a4a4a"} letterSpacing={'-.05rem'} fontSize={"24px"}>Élections du Bureau ADERE</Text>
        <Text color={"#131313"} lineHeight={'1.5'} mt={5}>
          Vous pouvez consulter les candidatures sur cette page et attribuer un vote pondéré à chaque candidat grâce à <Text as={"span"} fontWeight={700}>six points de vote</Text>. Vous pouvez également choisir de vous <Text as={"span"} fontWeight={700}>abstenir</Text>.
        </Text>
        <Text color={"#131313"} lineHeight={'1.5'} mt={5}>
          Les votes sont <Text as={"span"} fontWeight={700}>anonymes</Text> et seront comptabilisés automatiquement à la fin de la période de vote.
        </Text>
        <Text color={"#131313"} lineHeight={'1.5'} mt={5}>
          La liste des candidats est triée par ordre alphabétique.
        </Text>
        
        {/* display only if deadline is not passed yet */}
        {election && !deadlinePassed &&
          <Text fontWeight={700} color={"#131313"} lineHeight={'1.5'} mt={5}>
          <FontAwesomeIcon icon={faCheckToSlot} style={{marginRight:"5px"}} />Fin de la période de vote : le {formatDate(election?.deadline, "dd/MM/yyyy à HH:mm", { locale: fr })} - dans {getFrenchRemainingTime(election?.deadline)}
          </Text>
        }
        {election && deadlinePassed &&
          <Text fontWeight={700} color={"#131313"} lineHeight={'1.5'} mt={5}>
          <FontAwesomeIcon icon={faCircleInfo} style={{marginRight:"5px"}} />La période de vote est terminée.
          </Text>
        }
        {!election && <SkeletonText mt={5} noOfLines={1} height={"18px"} spacing="4" width={"50%"} />}
      </Box>
    </Center>

    <Box mb={10} mt={"70px"}>
      <Text mb={3} ms={3} color={"#004851"} fontWeight={'800'} fontSize={'sm'}>CANDIDATURES</Text>
      <Box mb={5} height={'4px'} bgColor={"#004851"} width={'100%'} />
    </Box>

    {!loading &&
      <Box border={"1pt solid #004851"} rounded={5}>
        {myVote?.submitted && <Text color={"green.600"} fontWeight={'700'} p={5}><FontAwesomeIcon icon={faClipboardCheck} style={{marginRight:"8px"}} />Vous avez voté, merci !</Text>
        }
        {!myVote?.submitted && <Text color={"#131313"} p={5}><FontAwesomeIcon icon={faCircleInfo} style={{marginRight:"8px"}} />Vous n'avez pas encore soumis votre vote.</Text>
        }
      </Box>
    }

    <Grid gridTemplateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={4} mt={10}>

      {loading && [1, 2, 3, 4].map((_, index) => <Skeleton boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} border={"1pt solid #e2e2dd"} key={index} height="350px" />)

      }

      {!loading && candidates.map((candidate, index) => (
        <CandidateCard 
          key={index} candidate={candidate} myVotes={myVotePerCandidate(candidate._id)} vote_callback={handleVote} 
          voteDisabled={
            myVote?.submitted 
            || votingCandidate == candidate._id
            || (myVote!.choices.filter((choice) => choice.points > 0).length >= 3 && myVotePerCandidate(candidate._id) === 0)} 
            availablePoints={availablePoints}
            />
      ))}
    </Grid>

    <Flex>
      <Spacer />
      <Button
        isDisabled={loading || submitting || myVote?.submitted} width={'fit-content'} p={6} m={3} me={0} color={"white"} _hover={{backgroundColor:"#b2b0a5"}} bgColor={"#8c8b82"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={() => {confirm_vote(true)}}>
          VOTER BLANC
        </Button>
      <Button
        isDisabled={loading || submitting || myVote?.submitted} width={'fit-content'} p={6} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={() => {confirm_vote(false)}}>
          CONFIRMER MON VOTE
        </Button>
    </Flex>

    <Box height={'850px'} />

    </Layout>
  </>);
}

export default Elections;