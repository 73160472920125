import React, { useEffect, useRef } from 'react';
import '../Home.css';
import { Box, Button, Center, Checkbox, Divider, Flex, FormControl, FormHelperText, FormLabel, Grid, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Progress, Spacer, Spinner, Text, Textarea, Tooltip, VStack, useDisclosure, useToast } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPaperclip, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ICategory, IDossierRequest, IPoll, PollType, RequestStatus, pollTypeToString } from '../types';
import { NewPoll } from '../Poll/NewPoll';
import { useUser } from '../UserContext';
import { formatDate } from 'date-fns';
import { getCategories, getCurrentDraft, updateCurrentDraft } from '../API/dossier';
import { downloadFile, getFilenames, uploadFiles } from '../API/file';
import TextEditor from '../components/TextEditor';

export function NewDossier(isOpen:boolean, onOpen:any, onClose:any, updateContent:number) {
    const ALLOWED_FORMATS = ["image/jpeg", "image/png", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "text/plain", "text/csv", "application/rtf", "text/html", "application/zip", "audio/mpeg", "audio/x-ms-wma", "audio/ogg", "audio/wav", "video/mp4", "video/quicktime", "video/x-ms-wmv", "video/avi", "video/mpeg", "video/3gpp", "video/3gpp2", "application/zip", "application/x-rar-compressed", "application/x-tar", "application/x-gzip", "application/x-7z-compressed", "application/x-zip-compressed"];
    const name = useRef<HTMLInputElement>(null);
    const tags = useRef<HTMLInputElement>(null);
    const [description, setDescription] = React.useState<string>('');
    const priorityDescription = useRef<HTMLTextAreaElement>(null);
    const [categories, setCategories] = React.useState<ICategory[]>([]);
    const [categoriesFetched, setCategoriesFetched] = React.useState(false);
    const [selectedCategories, setSelectedCategories] = React.useState<string[]>([]);
    const [deadline, setDeadline] = React.useState<Date|null>(new Date());
    const [priority, setPriority] = React.useState(false);
    const [polls, setPolls] = React.useState<IPoll[]>([]);
    const polls_disclosure = useDisclosure();
    const MAX_NAME_LENGTH = 50;
    const MAX_TAGS_LENGTH = 150;
    const MIN_TAGS_NB = 3;
    const MAX_DESCRIPTION_LENGTH = 5000;
    const MAX_PRIORITY_DESCRIPTION_LENGTH = 500;
    const { user } = useUser();
    const toast = useToast();
    const [currentContent, setCurrentContent] = React.useState<IDossierRequest|null>(null);
    const [draftAlert, setDraftAlert] = React.useState<boolean>(false);
    const [draftLoaded, setDraftLoaded] = React.useState<boolean>(false);

    useEffect(() => {
      if (!isOpen) return;
      getCategories().then((resp) => {
        setCategories(resp.data.categories);
        setCategoriesFetched(true);
      });
    }, [isOpen]);

    useEffect(() => {
      if (!isOpen) return;
      // Fetch the current draft
      getCurrentDraft().then((resp) => {
        const dossierRequest:IDossierRequest = resp.data.dossierRequest;
        setCurrentContent(dossierRequest);
        name.current!.value = dossierRequest.title;
        tags.current!.value = dossierRequest.tags.join(', ');
        setDescription(dossierRequest.description);
        setDeadline(dossierRequest.deadline);
        setPriority(dossierRequest.high_priority);
        priorityDescription.current!.value = dossierRequest.priority_reason;
        setPolls(dossierRequest.polls);
        setDraftLoaded(true);
        fetch_filenames(dossierRequest.attached_files);
        setSelectedCategories(dossierRequest.categories);
      });

    }, [isOpen]);

    const fetch_filenames = async (ids:string[]) => {
      getFilenames(ids).then((resp) => {
        setFilenames(resp.data.filenames);
      });
    }

    const updateDraft = (attribute: string, value: any, updateDb=true) => {
      setCurrentContent((prevContent) => {
        if (prevContent) {
          const updatedContent = { ...prevContent, [attribute]: value };
          if (updateDb)
            updateCurrentDraft(updatedContent);
          return updatedContent;
        }
        return null;
      });
    };

    const update_description = (e:any) => {
      setDescription(e);
      updateDraft('description', e);
    }

    const resetDraft = () => {
      setDraftAlert(false);
      name.current!.value = '';
      tags.current!.value = '';
      setDescription('');
      setSelectedCategories([]);
      setDeadline(new Date());
      setPriority(false);
      priorityDescription.current!.value = '';
      setPolls([]);
      setFilenames([]);
      setFiles([]);

      // Update db with empty draft
      updateCurrentDraft({
        _id: currentContent?._id || "",
        author: user?.matricule || "",
        author_mail: "",
        title: '',
        tags: [],
        categories: [],
        description: '',
        deadline: new Date(),
        high_priority: false,
        priority_reason: '',
        draft: true,
        polls: [],
        attached_files: [],
        status: RequestStatus.Pending,
      });

      toast({
        title: "Brouillon réinitialisé",
        description: "Les changements ont été annulés.",
        status: "info",
        duration: 5000,
        isClosable: true,
        position: "top-right",
        size: "md",
      });
    }

    const updateNameHandler = () => {
        if (name.current?.value) {
          updateDraft('title', name.current.value);
        }
        if (!draftAlert) {
          setDraftAlert(true);
          toast({
            title: "Changements enregistrés",
            description: "Vos changements sont automatiquement sauvegardés, même si vous fermez cet onglet.",
            status: "info",
            duration: 5000,
            isClosable: true,
            position: "top-right",
            size: "md",
          });
        }
    }

    const updateTagsHandler = () => {
        if (tags.current?.value) {
          updateDraft('tags', tags.current.value.split(',').map((tag) => tag.trim()));
        }
    }

    const setPriorityHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPriority(e.target.checked);
        updateDraft('high_priority', e.target.checked);
    }

    const setDeadlineHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setDeadline(null);
        updateDraft('deadline', null);
      }
      else {
        setDeadline(new Date());
        updateDraft('deadline', new Date());
      }
    }

    // submit_handler(newPollTitle, newPollDescription, newPollType, newPollAnswers, deadline);
    const add_poll = (title:string, description:string, type:PollType, options:string[], deadline:Date) => {
      const new_poll:IPoll = {
        _id: "0",
        author: user?.matricule || "",
        title: title,
        type: type,
        description: description,
        options: options,
        deadline: deadline,
      };
      setPolls([...polls, new_poll]);
      updateDraft('polls', [...polls, new_poll]);
    }

    const remove_poll = (index:number) => {
      setPolls(polls.filter((p, i) => i !== index));
      updateDraft('polls', polls.filter((p, i) => i !== index));
    }

    const submit_handler = () => {
      // Check if name is not empty
      if (name.current?.value === '') {
        toast({
          title: "Erreur",
          description: "Veuillez entrer un intitulé pour le dossier.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
          size: "md",
        });
        return;
      }

      // Check if tags are not empty
      if (tags.current?.value === '') {
        toast({
          title: "Erreur",
          description: "Veuillez entrer au moins trois mots-clés pour le dossier.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
          size: "md",
        });
        return;
      }

      // Check if, when split into ',' and trimmed, there are at least 3 tags
      if (tags.current?.value && tags.current.value.split(',').map((tag) => tag.trim()).length < MIN_TAGS_NB) {
        toast({
          title: "Erreur",
          description: "Veuillez entrer au moins trois mots-clés pour le dossier.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
          size: "md",
        });
        return;
      }

      // Check if categories are not empty
      if (selectedCategories.length === 0) {
        toast({
          title: "Erreur",
          description: "Veuillez sélectionner au moins une catégorie pour le dossier.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
          size: "md",
        });
        return;
      }

      // Check if description is not empty
      if (description.trim() === '') {
        toast({
          title: "Erreur",
          description: "Veuillez entrer une description pour le dossier.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
          size: "md",
        });
        return;
      }

      // Check if priority is checked, then the description is not empty
      if (priority && priorityDescription.current?.value.trim() === '') {
        toast({
          title: "Erreur",
          description: "Veuillez entrer une justification pour la haute priorité du dossier.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
          size: "md",
        });
        return;
      }

      // Check if the deadlines of the polls are not after the deadline of the dossier
      if (deadline) {
        const poll_deadlines = polls.map((poll) => poll.deadline);
        if (poll_deadlines.some((d) => d > deadline!)) {
          toast({
            title: "Erreur",
            description: "La deadline d'un sondage ne peut pas être après la deadline du dossier.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
            size: "md",
          });
          return;
        }
      }

      // Update the draft (set draft to false) & send to DB
      updateDraft('draft', false);
      reset_form();

      // Close the modal, reset the form and display a success message
      onClose();
      toast({
        title: "Succès",
        description: "Votre demande d'ouverture de dossier a bien été envoyée.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
        size: "md",
      });
    }

    const reset_form = () => {
      name.current!.value = '';
      tags.current!.value = '';
      setSelectedCategories([]);
      setDescription('');
      setDeadline(new Date());
      setPriority(false);
      priorityDescription.current!.value = '';
      setPolls([]);
      setFilenames([]);
      setFiles([]);
      setDraftLoaded(false);
      setCurrentContent(null);
    }

    // Upload files handler
    const [files, setFiles] = React.useState<File[]>([]);
    const [filenames, setFilenames] = React.useState<string[]>([]);
    const [filesUploading, setFilesUploading] = React.useState<boolean>(false);
    const MAX_FILES_NB = 5;
    // Handler for file input change, whenever a new file is added
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const new_files = Array.from(e.target.files);
        if (new_files.length > MAX_FILES_NB) {
          toast({
            title: "Erreur",
            description: "Vous ne pouvez pas ajouter plus de " + MAX_FILES_NB + " fichiers à la fois.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
            size: "md",
          });
          return;
        }
        setFilesUploading(true);

        // Upload files and get the IDs, then update the draft with the IDs
        const formData = new FormData();
        new_files.forEach((file, index) => {
          formData.append('files', file);
        });
        uploadFiles(formData).then((resp) => {
          if (resp.status === 201) {
            setFilesUploading(false);
            const ids = resp.data.ids;
            updateDraft('attached_files', [...currentContent?.attached_files || [], ...ids]);
            setFiles([...files, ...new_files]);
            setFilenames([...filenames, ...new_files.map((file) => file.name)]);
            toast({
              title: "Fichiers envoyés",
              description: "Les fichiers ont bien été envoyés.",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-right",
              size: "md",
            });
          }
        }).catch((error) => {
          toast({
            title: "Erreur",
            description: "Une erreur est survenue lors de l'envoi des fichiers : "+error.response.data.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-right",
            size: "md",
          });
          setFilesUploading(false);
        });
      }
    }

    const removeFile = (filename:string) => {
      // Get the file ID from the currentContent (it is at the same index as the filename in the filenames array)
      const file_index = filenames.indexOf(filename);
      const file_id = currentContent?.attached_files[file_index];
      if (!file_id) return;
      // Update the draft
      updateDraft('attached_files', currentContent?.attached_files.filter((id) => id !== file_id));
      // Remove the file from the filenames array
      setFiles(files.filter((file) => file.name !== filename));
      setFilenames(filenames.filter((name) => name !== filename));

      toast({
        title: "Fichier supprimé",
        description: "Le fichier a bien été supprimé.",
        status: "info",
        duration: 5000,
        isClosable: true,
        position: "top-right",
        size: "md",
      });
    }

    const download_file = (filename:string) => {
      // Get the file ID
      const file_index = filenames.indexOf(filename);
      const file_id = currentContent?.attached_files[file_index];
      if (!file_id) return;

      downloadFile(file_id).then((resp) => {
        const contentDisposition = resp.headers['content-disposition'];
        const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      });
    }

    const update_deadline = (e:any) => {
      setDeadline(e.value);
      updateDraft('deadline', e.value);
    }
  
    return (<>
      <Modal 
        onClose={onClose} 
        size={"5xl"} 
        isOpen={isOpen}
        motionPreset='scale'
        closeOnOverlayClick={false}
        >
          <ModalOverlay backdropFilter='blur(5px)' />
          <ModalContent>
            <ModalHeader>
              <Text fontWeight={'900'} color={"#314e89"} fontSize={'xx-large'} letterSpacing={"-.1rem"}>Ouverture de dossier</Text>
              <Divider mt={3} />
            </ModalHeader>
  
            <ModalCloseButton />
  
            <ModalBody mt={0} pt={0}>
              <Box p={4} pt={0} mt={0}>
                <Text align={"justify"} fontSize={'md'} mb={4}>
                  Cette interface vous permet de demander la création d'un dossier qui sera ensuite soumis à l'approbation du Bureau ADERE. Une fois votre demande validée, votre dossier apparaitra dans la liste des dossiers ouverts. En cas de refus, vous serez notifié·e par mail avec les raisons de celui-ci.
                </Text>
                
                
                <Flex>
                  <Text mb={3} ms={3} color={"#314e89"} fontWeight={'800'} fontSize={'sm'}>CONTENU DU DOSSIER</Text>
                  <Spacer />
                  <Text cursor={'pointer'} onClick={resetDraft} mb={3} ms={3} color={"gray.500"} fontWeight={'800'} fontSize={'sm'}>RÉINITIALISER</Text>
                </Flex>
                <Box mb={5} height={'4px'} bgColor={"#314e89"} width={'100%'} />

                <FormControl>
                  <FormLabel>Intitulé du dossier</FormLabel>
                  <Input 
                    ms={2} type='text' ref={name} maxLength={MAX_NAME_LENGTH} 
                    onBlur={updateNameHandler}
                    disabled={!draftLoaded}
                  />
                  <FormHelperText ms={2}>Max. {MAX_NAME_LENGTH} caractères</FormHelperText>
                </FormControl>
                
                <FormControl mt={3}>
                  <FormLabel>Mots-clés</FormLabel>
                  <Input 
                    ms={2} type='text' ref={tags} maxLength={MAX_TAGS_LENGTH} placeholder='e.g., matériel, labo, B15' 
                    disabled={!draftLoaded}
                    onBlur={updateTagsHandler}  
                  />
                  <FormHelperText ms={2}>Spécifiez au moins {MIN_TAGS_NB} mots-clés sans espaces décrivant le dossier, séparés par une virgule.</FormHelperText>
                </FormControl>
  
                <FormControl mt={3}>
                  <FormLabel>Catégorie(s)</FormLabel>
                  {!categoriesFetched &&
                  <Box height={"150px"}>
                    <Center mt={10}><Spinner /></Center>
                  </Box>
                  }
                  <Grid templateColumns={{ sm: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} gap={4}>
                    {categories.map((category: ICategory, index: number) => {
                      return (
                        <Checkbox
                          ms={2}
                          isChecked={selectedCategories.some((c) => c === category._id)}
                          isDisabled={!draftLoaded}
                          key={index}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedCategories([...selectedCategories, category._id]);
                              updateDraft('categories', [...selectedCategories, category]);
                            } else {
                              setSelectedCategories(selectedCategories.filter((c, i) => c !== category._id));
                              updateDraft('categories', selectedCategories.filter((c, i) => c !== category._id));
                            }
                          }}
                        >
                          {category.name}
                        </Checkbox>
                      );
                    })}
                  </Grid>
                  {categoriesFetched && categories.length == 0 && <Text ms={2}>Aucune catégorie disponible.</Text>}
                </FormControl>

                <FormControl mt={3}>
                  <FormLabel>Description du dossier</FormLabel>
                  <Box>
                    <Text fontSize={'sm'} mb={2}>
                      La description du dossier sera publiée et visible par tous·tes les membres de l'ADERE.
                    </Text>
                    <TextEditor disabled={!draftLoaded} maxLength={MAX_DESCRIPTION_LENGTH} content={description} update_callback={(e:any) => update_description(e)} />
                  </Box>
                </FormControl>

                <FormControl mt={3}>
                  <FormLabel>Deadline</FormLabel>
                  <VStack ms={2} align={'start'} spacing={2}>
                    <Checkbox
                      disabled={!draftLoaded}
                      onChange={setDeadlineHandler}
                      isChecked={deadline === null}
                    >
                      Pas de deadline
                    </Checkbox>
                    {deadline && 
                      <DateTimePickerComponent 
                        locale='fr' 
                        width={'250px'} 
                        id="datetimepicker" 
                        min={new Date()}
                        value={deadline}
                        allowEdit={false}
                        onChange={(e:any) => {update_deadline(e);}}
                      />
                    }
                  </VStack>
                  
                  <FormHelperText ms={2}>Si vous n'indiquez pas de deadline, vous devrez clôturer le dossier manuellement.</FormHelperText>
                </FormControl>

                <FormControl mt={3}>
                  <FormLabel>Priorité</FormLabel>
                  <Text mb={2} fontSize={'sm'}>Un dossier à haute priorité apparaitra au-dessus de tous les autres dans la liste.</Text>
                  <VStack align={'start'} spacing={2} ms={2}>
                    <Checkbox
                      onChange={setPriorityHandler}
                      isChecked={priority}
                      colorScheme='red'
                      isDisabled={!draftLoaded}
                    >
                      Haute priorité
                    </Checkbox>
                      <Textarea
                        display={priority ? 'block' : 'none'}
                        disabled={!draftLoaded}
                        ref={priorityDescription}
                        onBlur={(e) => updateDraft('priority_reason', e.target.value)}
                        maxLength={MAX_PRIORITY_DESCRIPTION_LENGTH}
                      />
                  </VStack>
                  
                  {priority && (<>
                    <FormHelperText ms={2}>Veuillez justifier pourquoi le dossier doit être de haute priorité (max. {MAX_PRIORITY_DESCRIPTION_LENGTH} caractères)</FormHelperText>
                  </>)}
                </FormControl>

                
                <Flex>
                  <Spacer />
                  {draftLoaded &&
                    <Box mt={3}>
                        <FormLabel cursor={'pointer'} mt={3} border={"1pt solid #e2e2dd"} ps={3} pe={3} pt={2} pb={2} htmlFor="upload-file"><FontAwesomeIcon style={{marginRight:'5px'}} icon={faPaperclip} />Ajouter une pièce jointe</FormLabel>
                        <input multiple={true} accept={ALLOWED_FORMATS.join(',')} id='upload-file' type="file" name="photo" style={{opacity:0, position:"absolute", zIndex:-10}}
                        onChange={handleFileChange}
                        disabled={filesUploading}
                        />
                        <Text me={3} align={"right"} fontSize={"sm"} color={"#4a5568"} mt={1}>Max. 15 Mo, Max. 5 fichiers</Text>
                    </Box>
                  }
                </Flex>
  
                
                {filesUploading &&
                  <Box>
                    <Progress size='md' mt={2} isIndeterminate />
                    <Center mt={5}>Téléversement de vos fichiers...</Center>
                  </Box>
                }
                <Grid mt={2} templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }} gap={4}>
                  {filenames.map((filename, index) => {
                    return (
                      <Flex bgColor={'white'} key={index} border={"1pt solid #e2e2dd"} p={2} rounded={"md"}>
                        <Box onClick={() => {download_file(filename)}} cursor={"pointer"}>{filename.length > 20 ? filename.substring(0, 20) + "..." : filename}</Box>
                        <Spacer />
                        <Box onClick={() => {removeFile(filename);}} ms={2} cursor={"pointer"}><FontAwesomeIcon icon={faTrashCan} /></Box>
                      </Flex>
                    )
                  })}
                </Grid>
                
                <Text mt={5} mb={3} ms={3} color={"#00707f"} fontWeight={'800'} fontSize={'sm'}>SONDAGES</Text>
                <Box mb={5} height={'4px'} bgColor={"#00707f"} width={'100%'} />

                <Text fontSize={'md'} align={'justify'}>
                  Vous avez la possibilité d'ouvrir le dossier avec des sondages pré-remplis. Cela permettra aux participant·e·s de voter pour les sondages dès l'ouverture du dossier. Il est également possible de rajouter des sondages par la suite.
                </Text>

                {NewPoll(polls_disclosure.isOpen, polls_disclosure.onOpen, polls_disclosure.onClose, "", add_poll)}

                <Box>
                  {polls.map((poll:IPoll, index:number) => {
                    return (
                      <Box key={index} border={"1pt solid #e2e2dd"} p={3} mt={3} rounded={"md"}>
                        <Flex>
                          <Text fontWeight={'800'} fontSize={'md'}>{poll.title}</Text>
                          <Spacer />
                          <Tooltip hasArrow label="Supprimer ce sondage" bg="white" color="black" placement="top">
                            <FontAwesomeIcon onClick={() => {
                              remove_poll(index);
                            }} icon={faTrashCan} style={{cursor:"pointer"}} />
                          </Tooltip>
                        </Flex>
                        <Text>{poll.description}</Text>
                        <Text>{pollTypeToString(poll.type)}</Text>
                        <Text>{poll.options.join(', ')}</Text>
                        <Text>{formatDate(poll.deadline, "dd/MM/yyyy à HH:mm")}</Text>
                      </Box>
                    )
                  })}
                </Box>
                
                <Button rounded={0} variant={'outline'} colorScheme={'blue'} mt={4} ms={2} onClick={polls_disclosure.onOpen} isDisabled={!draftLoaded}>Ajouter un sondage</Button>
  
                <Divider mt={4} mb={4} />
                
                <Flex>
                  <Button rounded={0} variant={'outline'} colorScheme={'gray'} mt={4} onClick={onClose}>Annuler</Button>
                  <Spacer />
                  <Button rounded={0} variant={'outline'} colorScheme={'blue'} mt={4} ms={2} onClick={submit_handler} isDisabled={!draftLoaded} rightIcon={<FontAwesomeIcon icon={faPaperPlane} />}>Demander l'ouverture du dossier</Button>
                </Flex>
  
              </Box>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </ModalContent>
      </Modal>
    </>)
  }