import { Text } from "@chakra-ui/react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";

import './paginate.css';

interface PaginationProps {
    page_change_callback: (selectedItem: { selected: number }) => void;
    totalCount: number;
    itemsPerPage: number;
}

const Pagination: React.FC<PaginationProps> = ({page_change_callback, totalCount, itemsPerPage}) => {

    return <ReactPaginate
    activeClassName={'item active '}
    breakClassName={'item break-me '}
    breakLabel={'...'}
    containerClassName={'pagination'}
    disabledClassName={'disabled-page'}
    marginPagesDisplayed={2}
    nextClassName={"item next"}
    nextLabel={<Text>PAGE SUIVANTE<FontAwesomeIcon icon={faAngleRight} style={{marginLeft:"8px"}} /></Text>}
    onPageChange={page_change_callback}
    pageCount={Math.ceil(totalCount / itemsPerPage)}
    pageClassName={'item pagination-page '}
    pageRangeDisplayed={2}
    previousClassName={"item previous"}
    previousLabel={<Text><FontAwesomeIcon icon={faAngleLeft} style={{marginRight:"8px"}} />PAGE PRÉCÉDENTE</Text>}
  />
}

export default Pagination;