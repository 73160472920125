import { AxiosResponse } from "axios";
import API, { API_URL, ApiDataType } from "./api";
import { ICategory, IDossier, IDossierRequest, IPoll, RequestStatus } from "../types";

/**
 * GET request to the server to get dossiers
 */
export const getDossiers = async ( 
    limit:number, 
    offset:number=0, 
    sort="deadline", 
    categories:string[]=[], 
    high_priority:boolean=false, 
    open=true, 
    lightweight=false 
): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/dossiers`,
            { limit, offset, sort, categories, open, lightweight, high_priority }
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getDossier = async (id: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(`${API_URL}/dossiers/${id}`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getDossierBySlug = async (slug: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(`${API_URL}/dossiers/slug/${slug}`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getDossierNumberByCategory = async (category_id: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(`${API_URL}/dossiers/category`, { category_id });
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getMyDossiers = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(`${API_URL}/dossiers/my`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getMyDossierRequests = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(`${API_URL}/dossiers/requests/my`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const closeDossier = async (id: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(`${API_URL}/dossiers/close/${id}`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const deleteDossier = async (id: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.delete(`${API_URL}/dossiers/${id}`);
        return resp;
    } catch (error) {
        throw error;
    }
}


export const watchDossier = async (dossier_id: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(`${API_URL}/dossiers/watch/${dossier_id}`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getPolls = async (dossier_id: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(`${API_URL}/polls/${dossier_id}`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getAllPolls = async (): Promise<AxiosResponse<{polls:IPoll[], dossier_mapping:{ [key: string]: { name: string; slug: string } } }>> => {
    try {
        const resp: AxiosResponse<{polls:IPoll[], dossier_mapping:{ [key: string]: { name: string; slug: string } } }> = await API.get(`${API_URL}/polls/all`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getCategories = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(`${API_URL}/categories`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getCurrentDraft = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(`${API_URL}/dossiers/draft`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const updateCurrentDraft = async (draft: IDossierRequest): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.put(`${API_URL}/dossiers/draft`, draft);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const addDossierFiles = async (dossier_id: string, files_ids: string[]): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(`${API_URL}/dossiers/files/${dossier_id}`, { files_ids });
        return resp;
    } catch (error) {
        throw error;
    }
}

export const removeDossierFile = async (dossier_id: string, file_id: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.delete(`${API_URL}/dossiers/files/${dossier_id}/${file_id}`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getDossiersRequests = async (status:RequestStatus, limit:number=0, offset:number=0): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(`${API_URL}/dossiers/requests`, { status, limit, offset });
        return resp;
    } catch (error) {
        throw error;
    }
}

export const manageDossierRequest = async (id: string, accepted: boolean, reason: string, bureau:boolean, high_priority:boolean): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(`${API_URL}/dossiers/manage/`+id, { accepted, reason, bureau, high_priority });
        return resp;
    } catch (error) {
        throw error;
    }
}

export const newCategory = async (title: string, description: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(`${API_URL}/categories`, { title, description });
        return resp;
    } catch (error) {
        throw error;
    }
}

export const deleteCategory = async (id: string, replacement:ICategory|null): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(`${API_URL}/categories/delete/${id}`, { replacement });
        return resp;
    } catch (error) {
        throw error;
    }
}

export const searchDossierByTag = async (tag: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(`${API_URL}/dossiers/search`, { tag });
        return resp;
    } catch (error) {
        throw error;
    }
}

export const updateDossier = async (dossier: IDossier): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.put(`${API_URL}/dossiers/${dossier._id}`, dossier);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const updateDossierDeadline = async (dossier_id: string, deadline: Date|null): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.put(`${API_URL}/dossiers/deadline/${dossier_id}`, { deadline });
        return resp;
    } catch (error) {
        throw error;
    }
}