import { AxiosResponse } from "axios";
import API, { API_URL, ApiDataType } from "./api";

export const uploadFiles = async (formData: FormData): Promise<AxiosResponse<ApiDataType>> => {
    try {
        
        const resp: AxiosResponse<ApiDataType> = await API.post(
            `/files`, 
            formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
        });
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getFilenames = async (ids: string[]): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(`/files/names`, { ids });
        return resp;
    } catch (error) {
        throw error;
    }
}

export const downloadFile = async (id: string): Promise<AxiosResponse<Blob>> => {
    try {
        const resp: AxiosResponse<Blob> = await API.get(`/files/${id}`, {
            responseType: "blob",
        });
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getProfilePicture = async (matricule: string): Promise<AxiosResponse<Blob>> => {
    try {
        const resp: AxiosResponse<Blob> = await API.get(
            `${API_URL}/files/pp/${matricule}`,
            { responseType: "blob" }
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getProfilePictureById = async (id: string): Promise<AxiosResponse<Blob>> => {
    try {
        const resp: AxiosResponse<Blob> = await API.get(
            `${API_URL}/files/pp/id/${id}`,
            { responseType: "blob" }
        );
        return resp;
    } catch (error) {
        throw error;
    }
}