import { useEffect, useRef, useState } from 'react';
import '../../Home.css';
import { Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Button, Checkbox, Flex, Skeleton, Spacer, Table, Tooltip, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useToast, Box, FormControl, FormLabel, Input, Center, FormHelperText } from '@chakra-ui/react';
import { Image as ChakraImage } from '@chakra-ui/react';
import { IArticle } from '../../types';
import { getArticleById, getArticles, newArticle, updateArticle } from '../../API/news';
import { formatDate } from 'date-fns';
import { faFilePen, faPaperclip, faPenNib, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { downloadFile, uploadFiles } from '../../API/file';
import TextEditor from '../../components/TextEditor';


export interface EditArticleProps {
    article_id: string|null;
    exit_callback:Function;
}

const EditArticle: React.FC<EditArticleProps> = ({article_id, exit_callback}) => {
    const ALLOWED_FORMATS = ["image/jpeg", "image/png", "image/jpg", "image/gif", "image/svg", "image/webp"];
    const [article, setArticle] = useState<IArticle | null>(null);
    const [b64Image, setB64Image] = useState<string | null>(null);
    const [imageId, setImageId] = useState<string>("");
    const [articleContent, setArticleContent] = useState<string>("");
    const titleRef = useRef<HTMLInputElement>(null);
    const categoryRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingPicture, setLoadingPicture] = useState<boolean>(true);
    const toast = useToast();
    const MAX_ARTICLE_LENGTH = 10000;
    const MAX_TITLE_LENGTH = 150;
    const MAX_CATEGORY_LENGTH = 50;
    const [saving, setSaving] = useState<boolean>(false);
    const [uploadingImage, setUploadingImage] = useState<boolean>(false);

    useEffect(() => {
        if (!article_id) {
            setLoading(false);
            return;
        }

        setLoadingPicture(true);
        getArticleById(article_id).then((res) => {
            const article_resp:IArticle = res.data.article;
            setArticle(res.data.article);
            titleRef.current!.value = article_resp.title;
            categoryRef.current!.value = article_resp.category;
            setArticleContent(article_resp.content);
            setImageId(article_resp.picture);

            // Download article picture
            downloadFile(article_resp.picture).then((res) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setB64Image(reader.result as string);
                };
                reader.readAsDataURL(res.data);
                setLoadingPicture(false);
            });
        }).catch((err) => {
            toast({
                title: "Erreur",
                description: "Impossible de charger l'article.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }).finally(() => {
            setLoading(false);
        });
    }, []);
    
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const new_file = Array.from(e.target.files)[0];

        // Max 10MB
        if (new_file.size > 10000000) {
          toast({
            title: "Erreur",
            description: "L'image est trop lourde.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        if (!ALLOWED_FORMATS.includes(new_file.type)) {
          toast({
            title: "Erreur",
            description: "Format d'image non supporté.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        const MIN_WIDTH = 1024;
        const MIN_HEIGHT = 575;
        const img = new Image();
        img.src = URL.createObjectURL(new_file);
        img.onload = () => {
          if (img.width < MIN_WIDTH || img.height < MIN_HEIGHT) {
            toast({
              title: "Erreur",
              description: "L'image est trop petite.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            return;
          }
        };

        // Upload file
        const formData = new FormData();
        formData.append('files', new_file);
        setUploadingImage(true);
        uploadFiles(formData).then((res) => {
            const reader = new FileReader();
            reader.onloadend = () => {
            setB64Image(reader.result as string);
            };
            reader.readAsDataURL(new_file);
            setImageId(res.data.ids[0]);
        }).catch((err) => {
            setImageId("");
            setB64Image("");
            toast({
                title: "Erreur",
                description: "Impossible de télécharger l'image. Erreur: " + err.response.data.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }).finally(() => {
            setUploadingImage(false);
        });
      }
    }

    const update_content_callback = (content: string) => {
        setArticleContent(content);
    }

    const submit = () => {
        if (!titleRef.current 
            || !categoryRef.current
            || !articleContent
            || !imageId) {
            toast({
                title: "Erreur",
                description: "Veuillez remplir tous les champs.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        setSaving(true);

        // New article
        if (!article_id) {
            newArticle(titleRef.current.value, articleContent, categoryRef.current.value, imageId).then((res) => {
                toast({
                    title: "Succès",
                    description: "Article publié.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                exit_callback();
            }).catch((err) => {
                toast({
                    title: "Erreur",
                    description: "Impossible de publier l'article.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }).finally(() => {
                setSaving(false);
            });
        } else {
            // Update article
            updateArticle(article_id, titleRef.current.value, articleContent, categoryRef.current.value, imageId).then((res) => {
                toast({
                    title: "Succès",
                    description: "Article modifié.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                exit_callback();
            }).catch((err) => {
                toast({
                    title: "Erreur",
                    description: "Impossible de modifier l'article.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }).finally(() => {
                setSaving(false);
            });
        }
    }

    return (
    <Box mt={8}>
        <Flex me={3}>
            <Spacer />
            <Text onClick={(e) => {exit_callback();}} cursor={'pointer'} mb={3} ms={3} color={"#00707f"} fontSize={"sm"} fontWeight={900} >
                RETOUR
            </Text>
        </Flex>

        <Text mb={3} ms={3} color={"#004851"} fontWeight={'800'} fontSize={'sm'}>ÉDITEUR D'ARTICLE</Text>
        <Box mb={5} height={'4px'} bgColor={"#004851"} width={'100%'} />

        <Box p={4}>
            <FormControl>
                <FormLabel fontWeight={700}>Titre</FormLabel>
                <Input
                    maxLength={MAX_TITLE_LENGTH}
                    isDisabled={loading}
                    ref={titleRef}
                    bgColor={"white"} type="text" />
                <FormHelperText>Max. {MAX_TITLE_LENGTH} caractères</FormHelperText>
            </FormControl>

            <FormControl mt={5}>
                <FormLabel fontWeight={700}>Catégorie</FormLabel>
                <Input
                    isDisabled={loading}
                    ref={categoryRef}
                    placeholder='e.g., Actualités, Blog ADERE, Élections, etc.'
                    bgColor={"white"} type="text" />
                    <FormHelperText>Max. {MAX_CATEGORY_LENGTH} caractères</FormHelperText>
            </FormControl>

            
            <FormControl mt={5}>
                <FormLabel fontWeight={700}>Image d'illustration</FormLabel>

                <Text fontSize={"sm"}>
                    L'illustration d'un article doit absolument être assez grande (au moins 1024 pixels de large et 575 pixels de hauteur), et ne peut être plus lourde que 10Mo.
                </Text>

                <Flex>
                    <FormLabel bgColor={"white"} width={"fit-content"} cursor={'pointer'} mt={3} border={"1pt solid #e2e2dd"} ps={3} pe={3} pt={2} pb={2} htmlFor="upload-file"><FontAwesomeIcon style={{marginRight:'5px'}} icon={faPaperclip} />Sélectionner une image</FormLabel>
                        <input multiple={false} accept={ALLOWED_FORMATS.join(',')} id='upload-file' type="file" name="photo" style={{opacity:0, position:"absolute", zIndex:-10}}
                        onChange={handleFileChange}
                        />
                </Flex>

                {!uploadingImage && b64Image && b64Image?.length > 0 &&
                    <Text mt={2} mb={3} fontSize={"sm"}>
                        Image actuelle:
                    </Text>
                }

                {(uploadingImage || (article_id && loadingPicture)) && <Skeleton width={"960px"} height={"540px"} isLoaded={loadingPicture} />}
                <Center>
                    <ChakraImage border={"1pt solid #e2e2dd"} src={b64Image || ""} alt={article?.title} />
                </Center>
            </FormControl>

            <FormControl mt={5}>
                <FormLabel fontWeight={700}>Contenu de l'article</FormLabel>
                {/* content, update_callback, maxLength=0, disabled */}
                <TextEditor content={articleContent} update_callback={update_content_callback} maxLength={MAX_ARTICLE_LENGTH} disabled={loading} />
            </FormControl>
        </Box>

        
        <Flex mt={0} me={5}>
            <Text mt={5} onClick={(e) => {exit_callback();}} cursor={'pointer'} mb={3} ms={5} color={"#00707f"} fontSize={"sm"} fontWeight={900} >
                RETOUR
            </Text>
            <Spacer />
            <Button 
                isDisabled={loading || saving}
                p={6} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                rightIcon={<FontAwesomeIcon icon={faPenNib} />}
                onClick={() => submit()}
                >
                {article_id ? "MODIFIER" : "PUBLIER"}
            </Button>
        </Flex>
    </Box>);
}

export default EditArticle;