import React from 'react';
import '../Home.css';
import { Box, Button, Center, Circle, Container, Divider, Flex, FormControl, FormHelperText, FormLabel, Grid, HStack, Heading, Image, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spacer, Stack, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';
import { Layout } from '../Layout';
import { CheckIcon, PhoneIcon, SearchIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faComment, faEye, faFile, faFolderClosed, faFolderOpen, faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import LoremIpsum from 'react-lorem-ipsum';
import { Link as ReactLink, useLocation} from 'react-router-dom';

function NewsEditor() {
  return (<>
    <Layout>
      <Text fontWeight={'900'} color={"#e6e6e1"} fontSize={'7xl'} letterSpacing={"-.1rem"} mb={3} mt={5}>Éditeur d'articles</Text>

      <Text mb={4}>
        Les changements effectués sur cette page sont sauvegardés automatiquement, même si vous quittez la page.
      </Text>
      
      <FormControl>
        <FormLabel>Titre de l'article</FormLabel>
        <Input type='text' value={""} onChange={(e) => {}} maxLength={255} />
        <FormHelperText>Max. {255} caractères</FormHelperText>
      </FormControl>

      <FormControl mt={3}>
        <FormLabel>Catégorie</FormLabel>
        <Select placeholder='Sélectionnez une catégorie'>
          <option value='option1'>Option 1</option>
          <option value='option2'>Option 2</option>
          <option value='option3'>Option 3</option>
        </Select>
      </FormControl>

      <FormControl mt={3}>
        <FormLabel>Résumé à afficher en miniature</FormLabel>
        <Textarea value={""} onChange={(e) => {}} maxLength={255} />
        <FormHelperText>Max. {255} caractères</FormHelperText>
      </FormControl>

      <FormControl mt={3}>
        <FormLabel>Article</FormLabel>
        <Textarea value={""} onChange={(e) => {}} maxLength={20000} />
        <FormHelperText>Max. {20000} caractères</FormHelperText>
      </FormControl>

      <FormControl mt={3}>
        <FormLabel>Bannière</FormLabel>
        <Input border={"none"} type='file' />

        <FormHelperText>L'image de banière est obligatoire pour afficher l'article dans les listes du site. Minimum 800px de largeur et 450px de hauteur.</FormHelperText>
      </FormControl>

      <Flex>
        <Spacer />
        <Button mt={4} size={"md"} colorScheme="blue" variant="outline" rightIcon={<FontAwesomeIcon icon={faBullhorn} />}>Publier l'article</Button>
      </Flex>
    </Layout>
    </>);
}

export default NewsEditor;