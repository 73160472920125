import { AbsoluteCenter, Box, Button, Center, Collapse, Container, Fade, Flex, Grid, Spacer, Spinner, Text, useDisclosure } from "@chakra-ui/react";
import { Image as ChakraImage } from "@chakra-ui/react";
import logo from './resources/adere_logo.svg';
import { useEffect, useState } from "react";
import { faFolderOpen, faRightToBracket, faSquarePollVertical, faNewspaper, faComment, faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReadme } from "@fortawesome/free-brands-svg-icons";
import DossierCard from "./Dossiers/DossierCard";
import { IDossier } from "./types";
import BigDossierCard from "./Dossiers/BigDossierCard";

export const Welcome: React.FunctionComponent = () => {
    const p1_disclosure = useDisclosure();
    const p2_disclosure = useDisclosure();
    const p3_disclosure = useDisclosure();
    const p4_disclosure = useDisclosure();
    const p5_disclosure = useDisclosure();
    const p6_disclosure = useDisclosure();

    const [loaded, setLoaded] = useState(false);
    const [spinnerLoaded, setSpinnerLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) {
            // in one second
            setTimeout(() => {
                p1_disclosure.onToggle();
                setSpinnerLoaded(true);
            }, 1000);
            setLoaded(true);
        }
    }, [loaded]);

return (<>

<Center mt={"100px"}>
    <ChakraImage width={"400px"} src={logo} alt="Adere logo" />
</Center>

<Container maxW={"4xl"} mt={10}>

    {!spinnerLoaded &&
        <AbsoluteCenter>
            <Spinner size="xl"  thickness="5px" speed="0.65s" emptyColor="gray.200" color="#a8589e" />
        </AbsoluteCenter>
    }

    <Collapse in={p1_disclosure.isOpen} animateOpacity>
        <Box>
            <Text fontSize="6xl" letterSpacing={"-.1rem"} fontWeight={900} color={"#a8589e"} mb={5}>Bienvenue !</Text>

            <Box bgColor={"white"} border={"1pt solid #e2e2dd"} p={10}>
                <Text color={"#131313"}>
                    Voulez-vous consulter un rapide tutoriel pour vous familiariser avec l'application ?
                </Text>
            </Box>

            <Flex mt={5}>
                <Spacer />
                <Button
                isDisabled={false} width={'fit-content'} p={6} m={3} me={0} color={"white"} _hover={{backgroundColor:"#b2b0a5"}} bgColor={"#8c8b82"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={() => {
                    window.location.href = "/home";
                }}>
                NON MERCI
                </Button>
            <Button
                isDisabled={false} width={'fit-content'} p={6} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                onClick={() => {
                    p1_disclosure.onClose();
                    p2_disclosure.onToggle();
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                >
                OUI
                </Button>
            </Flex>
        </Box>
    </Collapse>

    <Collapse in={p2_disclosure.isOpen} animateOpacity>
        <Box>
            <Text fontSize="6xl" letterSpacing={"-.1rem"} fontWeight={900} color={"#a8589e"} mb={5}>À propos de la plateforme...</Text>

            <Box bgColor={"white"} border={"1pt solid #e2e2dd"} p={10}>
                <Text color={"#131313"}>
                    La plateforme web de l'ADERE (Association des Directeur·rice·s d'Equipe de Recherche et d'Enseignement) est un espace réservé aux membres de l'association où vous pouvez :
                </Text>

                <Box mt={5} ms={3} color={"#131313"}>
                    <Text><Text as={"span"} color={"#a8589e"} fontWeight={700}><FontAwesomeIcon icon={faFolderOpen} size="lg" style={{width:"45px"}} />Ouvrir un dossier</Text> au sujet d'une problématique que vous rencontrez,</Text>
                    <Text mt={5}><Text as={"span"} color={"#a8589e"} fontWeight={700}><FontAwesomeIcon icon={faReadme} size="lg" style={{width:"45px"}} />Consulter les dossiers</Text> ouverts par d'autres membres de l'association,</Text>
                    <Text mt={5}><Text as={"span"} color={"#a8589e"} fontWeight={700}><FontAwesomeIcon icon={faSquarePollVertical} size="lg" style={{width:"45px"}} />Participer à des sondages</Text> au sujet de problématiques abordées par d'autres membres de l'association,</Text>
                    <Text mt={5}><Text as={"span"} color={"#a8589e"} fontWeight={700}><FontAwesomeIcon icon={faComment} size="lg" style={{width:"45px"}} />Échanger</Text> avec les autres membres de l'association sur un forum privé,</Text>
                    <Text mt={5}><Text as={"span"} color={"#a8589e"} fontWeight={700}><FontAwesomeIcon icon={faNewspaper} size="lg" style={{width:"45px"}} />Lire des articles</Text> postés par le Bureau sur l'actualité de l'ADERE</Text>
                </Box>
            </Box>

            <Flex mt={5}>
                <Spacer />
                <Button
                isDisabled={false} width={'fit-content'} p={6} m={3} me={0} color={"white"} _hover={{backgroundColor:"#b2b0a5"}} bgColor={"#8c8b82"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    p2_disclosure.onClose();
                    p1_disclosure.onToggle();
                }}>
                RETOUR
                </Button>
                <Button
                    isDisabled={false} width={'fit-content'} p={6} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        p2_disclosure.onClose();
                        p3_disclosure.onToggle();
                    }}>
                    SUIVANT
                </Button>
            </Flex>
        </Box>
    </Collapse>

    <Collapse in={p3_disclosure.isOpen} animateOpacity>
        <Box>
            <Text fontSize="6xl" letterSpacing={"-.1rem"} fontWeight={900} color={"#a8589e"} mb={5}>Dossiers</Text>

            <Box color={"#131313"} bgColor={"white"} border={"1pt solid #e2e2dd"} p={10}>
                <Text textAlign={"justify"}>
                    Vous avez la possibilité d'<Text as={"span"} fontWeight={600}>ouvrir un dossier</Text> au sujet d'une problématique que vous rencontrez. Cette demande d'ouverture de dossier sera alors traitée par le Bureau de l'ADERE, et vous serez notifié·e de la suite donnée à votre demande.
                </Text>

                <Text mt={5}>
                    Lorsqu'un dossier est ouvert, sa couleur indique le temps restant avant sa clôture, et donc son urgence.
                </Text>

                <Box ms={3}>
                    <Flex mt={5}>
                        <DossierCard fake={true} dossier={
                            {
                                title: "Mon Dossier",
                                author_name: "Auteur·rice",
                                bureau: false,
                                deadline: new Date(new Date().getTime() + 10 * 24 * 60 * 60 * 1000),
                                user_seen: true,
                                slug: "dossier-1"
                            } as IDossier
                        } />
                        <Text ms={"100px"} mt={"60px"}>
                            <Text as={"span"} color={"#00843b"} fontWeight={600}>Deadline de clôture de plus d'une semaine</Text>
                        </Text>
                    </Flex>

                    <Flex mt={5}>
                        <DossierCard fake={true} dossier={
                            {
                                title: "Mon Dossier",
                                author_name: "Auteur·rice",
                                bureau: false,
                                deadline: new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000),
                                user_seen: true,
                                slug: "dossier-1"
                            } as IDossier
                        } />
                        <Text ms={"100px"} mt={"60px"}>
                            <Text as={"span"} color={"#f07f3c"} fontWeight={600}>Deadline de clôture de moins d'une semaine</Text>
                        </Text>
                    </Flex>

                    <Flex mt={5}>
                        <DossierCard fake={true} dossier={
                            {
                                title: "Mon Dossier",
                                author_name: "Auteur·rice",
                                bureau: true,
                                deadline: new Date(new Date().getTime() + 20 * 60 * 60 * 1000),
                                user_seen: true,
                                slug: "dossier-1"
                            } as IDossier
                        } />
                        <Text ms={"100px"} mt={"60px"}>
                            <Text as={"span"} color={"#940104"} fontWeight={600}>Deadline de clôture de moins de trois jours</Text>
                        </Text>
                    </Flex>
                </Box>

                <Text mt={8}>
                    Lorsqu'un dossier est ouvert par le Bureau, il est indiqué par un <Text as={"span"} fontWeight={700}><FontAwesomeIcon icon={faBriefcase} style={{marginRight:"5px"}} />badge</Text> à côté du titre du dossier.
                </Text>

                <Text mt={5}>
                    <Text as={"span"} fontWeight={700}>Attention : </Text> avant d'ouvrir un dossier, utilisez la barre de recherche pour vérifier si un dossier similaire n'a pas déjà été ouvert par un autre membre.
                </Text>

                <Text mt={5} mb={3}>
                    Dernière chose : les dossiers classés comme étant <Text as={"span"} fontWeight={700}>prioritaires</Text> sont mis en évidence par rapport aux dossiers classiques :
                </Text>

                <BigDossierCard fake={true} dossier={
                    {
                        title: "Mon Dossier",
                        author_name: "Auteur·rice",
                        content: "Exemple de contenu de dossier, mis en évidence par rapport aux dossiers classiques.",
                        bureau: false,
                        deadline: new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000),
                        user_seen: true,
                        slug: "dossier-1"
                    } as IDossier
                } />
            </Box>

            <Flex mt={5}>
                <Spacer />
                <Button
                isDisabled={false} width={'fit-content'} p={6} m={3} me={0} color={"white"} _hover={{backgroundColor:"#b2b0a5"}} bgColor={"#8c8b82"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    p3_disclosure.onClose();
                    p2_disclosure.onToggle();
                }}>
                RETOUR
                </Button>
                <Button
                    isDisabled={false} width={'fit-content'} p={6} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        p3_disclosure.onClose();
                        p4_disclosure.onToggle();
                    }}
                    >
                    SUIVANT
                </Button>
            </Flex>
        </Box>
    </Collapse>

    <Collapse in={p4_disclosure.isOpen} animateOpacity>
        <Box>
            <Text fontSize="6xl" letterSpacing={"-.1rem"} fontWeight={900} color={"#a8589e"} mb={5}>Forum</Text>

            <Box color={"#131313"} bgColor={"white"} border={"1pt solid #e2e2dd"} p={10}>
                <Text textAlign={"justify"}>
                    Il existe un <Text as={"span"} fontWeight={600}>forum de discussion</Text> où vous pouvez échanger avec les autres membres de l'association sur des sujets variés. Vous pouvez y poser des questions, partager des informations, ou simplement discuter de sujets importants.
                </Text>
            </Box>

            <Flex mt={5}>
                <Spacer />
                <Button
                isDisabled={false} width={'fit-content'} p={6} m={3} me={0} color={"white"} _hover={{backgroundColor:"#b2b0a5"}} bgColor={"#8c8b82"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    p4_disclosure.onClose();
                    p3_disclosure.onToggle();
                }}>
                RETOUR
                </Button>
                <Button
                    isDisabled={false} width={'fit-content'} p={6} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        p4_disclosure.onClose();
                        p5_disclosure.onToggle();
                    }}
                    >
                    SUIVANT
                </Button>
            </Flex>
        </Box>
    </Collapse>
    
    <Collapse in={p5_disclosure.isOpen} animateOpacity>
        <Box>
            <Text fontSize="6xl" letterSpacing={"-.1rem"} fontWeight={900} color={"#a8589e"} mb={5}>Fil d'actualité</Text>

            <Box color={"#131313"} bgColor={"white"} border={"1pt solid #e2e2dd"} p={10}>
                <Text textAlign={"justify"}>
                    Sur la page d'accueil, il y a un <Text as={"span"} fontWeight={600}>fil d'actualité</Text> qui regroupe les dernières contributions des membres de l'association. Vous pouvez y lire des articles postés par le Bureau, consulter les derniers dossiers ouverts, ou voir les derniers sondages.
                </Text>
            </Box>

            <Flex mt={5}>
                <Spacer />
                <Button
                isDisabled={false} width={'fit-content'} p={6} m={3} me={0} color={"white"} _hover={{backgroundColor:"#b2b0a5"}} bgColor={"#8c8b82"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    p5_disclosure.onClose();
                    p4_disclosure.onToggle();
                }}>
                RETOUR
                </Button>
                <Button
                    isDisabled={false} width={'fit-content'} p={6} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        p5_disclosure.onClose();
                        p6_disclosure.onToggle();
                    }}
                    >
                    SUIVANT
                </Button>
            </Flex>
        </Box>
    </Collapse>

    <Collapse in={p6_disclosure.isOpen} animateOpacity>
        <Box>
            <Text fontSize="6xl" letterSpacing={"-.1rem"} fontWeight={900} color={"#a8589e"} mb={5}>Bonne visite !</Text>

            <Box color={"#131313"} bgColor={"white"} border={"1pt solid #e2e2dd"} p={10}>
                <Text textAlign={"justify"}>
                    Pour toute question relative à l'ADERE, notre adresse e-mail est : <Text as={"span"} fontWeight={600}>adere@uliege.be</Text>.
                </Text>
                <Text textAlign={"justify"} mt={5}>
                    Notez que cette plateforme web est en constante évolution, et que nous sommes ouvert·e·s à toute suggestion d'amélioration.
                </Text>
            </Box>

            <Flex mt={5}>
                <Spacer />
                <Button
                isDisabled={false} width={'fit-content'} p={6} m={3} me={0} color={"white"} _hover={{backgroundColor:"#b2b0a5"}} bgColor={"#8c8b82"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    p6_disclosure.onClose();
                    p5_disclosure.onToggle();
                }}>
                RETOUR
                </Button>
                <Button
                    isDisabled={false} width={'fit-content'} p={6} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        p6_disclosure.onClose();
                        window.location.href = "/home";
                    }}
                    >
                    TERMINER
                </Button>
            </Flex>
        </Box>
    </Collapse>

    <Box height={"400px"} />
</Container>
</>);
}