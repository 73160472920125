import { ICategory, IContactForm, IDossierRequest, RequestStatus } from "../../types";
import PendingDossierCard from "./PendingDossierCard";
import { useEffect, useState } from "react";
import { getCategories, getDossiersRequests } from "../../API/dossier";
import { Center, Skeleton, Text, useToast } from "@chakra-ui/react";
import Pagination from "../../components/Pagination";
import { getContactForms, updateContactForm } from "../../API/contact";
import ContactFormCard from "./ContactFormCard";


export interface MessagesProps {}

const Messages: React.FC<MessagesProps> = ({}) => {

    const [forms, setForms] = useState<IContactForm[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const toast = useToast();
    useEffect(() => {
        getContactForms().then((resp) => {
            setForms(resp.data.contactForms);
            setLoading(false);
        });
    }, []);

    const update_form = (form:IContactForm) => {
        updateContactForm(form).then((resp) => {
            const index = forms.findIndex((f) => f._id === form._id);
            if (index !== -1) {
                const newForms = [...forms];
                newForms.splice(index, 1);
                setForms(newForms);
            }
            toast({
                title: "Message archivé",
                description: "Le message a été archivé avec succès",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        }).catch((error) => {
            toast({
                title: "Erreur",
                description: "Erreur lors de l'archivage du message",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        });
    }

    return <>
        {!loading && forms.length > 0 && forms.filter(
            (form) => !form.archived
        ).map((form: IContactForm, index: number) => {
            return <ContactFormCard form={form} handle_callback={update_form} key={index} />
        })}
    </>
}

export default Messages;