import React, { useEffect, useState } from 'react';
import '../Home.css';
import { Box, Button, Center, Circle, Container, Divider, Flex, Grid, Heading, Image, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spacer, Stack, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';
import { Layout } from '../Layout';
import { CheckIcon, PhoneIcon, SearchIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faComment, faEye, faFile, faFolderClosed, faFolderOpen, faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import LoremIpsum from 'react-lorem-ipsum';
import Threads from './Threads';
import survey_img from './resources/survey.svg';
import talk_img from './resources/talk.svg';
import { ICategory, IThread } from '../types';
import { getCategories } from '../API/dossier';
import { getThreadsFromCategory } from '../API/thread';
import MainThreads from './MainThreads';

function Forum() {

  const [categories, setCategories] = useState<ICategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ICategory|null>(null);
  useEffect(() => {
    getCategories().then((resp) => {
      setCategories(resp.data.categories);
    });
  }, []);

  const handle_category_change = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const category_id = e.target.value;
    const category = categories.find(cat => cat._id === category_id);
    setSelectedCategory(category || null);
  }

  return (
    <>
      <Layout>
        <Text fontWeight={'900'} color={"#b3b3af"} fontSize={'7xl'} letterSpacing={"-.1rem"} mb={5}>Forum</Text>

        <Flex mb={4} mt={4}>
          <Text mt={2} me={4}>
            Catégorie :
          </Text>
          <Select fontSize={"sm"} className='prettyOption' bgColor={"white"} width={"300px"} variant='outline' placeholder='Toutes les catégories' onChange={handle_category_change} value={selectedCategory?._id || ""}>
            {categories.map(category => (
              <option className='prettyOption' key={category._id} value={category._id}>{category.name}</option>
            ))}
          </Select>
        </Flex>

        {/* // show_category, dossier, category, categories */}
        <MainThreads categories={categories} category={selectedCategory} />

        {/* <Flex mt={8}>
          <Spacer />
          <Image src={talk_img} alt='survey' width={'400px'} position={'relative'} left={'200px'}/>
        </Flex> */}

        <Box height={'400px'} />
        
      </Layout>
    </>
  );
}

export default Forum;