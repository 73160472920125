import { AxiosResponse } from "axios";
import API, { API_URL, ApiDataType } from "./api";
import { IElection, IElectionVote } from "../types";

export const newElection = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp = await API.post(`${API_URL}/elections/new`, {});
        return resp;
    } catch (error) {
        throw error;
    }

}

export const searchUser = async (matricule:string="", email:string="", name:string=""): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp = await API.post(`${API_URL}/users/search`, {matricule, email, name});
        return resp;
    } catch (error) {
        throw error;
    }

}

export const updateElection = async (election_id:string, election:IElection): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp = await API.put(`${API_URL}/elections/${election_id}`, election);
        return resp;
    } catch (error) {
        throw error;
    }

}

export const addCandidate = async (election_id:string, candidate_matricule:string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp = await API.put(`${API_URL}/elections/${election_id}/candidates`, {candidate_matricule});
        return resp;
    } catch (error) {
        throw error;
    }
}

export const removeCandidate = async (election_id:string, candidate_id:string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp = await API.delete(`${API_URL}/elections/${election_id}/candidates/${candidate_id}`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const updateCandidateApplication = async (election_id:string, candidate_id:string, content:string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp = await API.put(`${API_URL}/elections/${election_id}/candidates/${candidate_id}`, {content});
        return resp;
    } catch (error) {
        throw error;
    }

}

export const getCurrentElection = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp = await API.get(`${API_URL}/elections/current`);
        return resp;
    } catch (error) {
        throw error;
    }

}

export const getElections = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp = await API.get(`${API_URL}/elections/all`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getFinishedElection = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp = await API.get(`${API_URL}/elections/finished`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const postElection = async (election_id:string, postDeadline:Date): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp = await API.post(`${API_URL}/elections/${election_id}/post`, {
            posted_end: postDeadline
        });
        return resp;
    } catch (error) {
        throw error;
    }
}

export const stopElection = async (election_id:string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp = await API.post(`${API_URL}/elections/${election_id}/stop`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getVotesPerCandidate = async (election_id:string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp = await API.get(`${API_URL}/elections/${election_id}/candidates/votes`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getCandidates = async (election_id:string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp = await API.get(`${API_URL}/elections/${election_id}/candidates`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getMyVote = async (election_id:string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp = await API.get(`${API_URL}/elections/${election_id}/votes/me`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const updateVote = async (election_id:string, vote:IElectionVote): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp = await API.put(`${API_URL}/elections/${election_id}/votes/me`, vote);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const submitMyVote = async (election_id:string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp = await API.post(`${API_URL}/elections/${election_id}/votes/me/submit`, {});
        return resp;
    } catch (error) {
        throw error;
    }
}