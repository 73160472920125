import '../Home.css';
import { Box, Button, Center, Circle, Divider, Flex, FormControl, FormLabel, Grid, Radio, RadioGroup, Select, Skeleton, Spacer, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { Layout } from '../Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFolderClosed, faFolderOpen, faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { getCategories, getDossierBySlug, getDossiers } from '../API/dossier';
import { ICategory, IDossier } from '../types';
import BigDossierCard from './BigDossierCard';
import DossierCard from './DossierCard';
import { NewDossier } from './NewDossier';
import { Link as ReactLink, useParams} from 'react-router-dom';
import Pagination from '../components/Pagination';

function Dossiers() {
  const DOSSIERS_PER_PAGE = 9;
  const [highPriorityDossiers, setHighPriorityDossiers] = useState<IDossier[]>([]);
  const [highPriorityDossiersFetched, setHighPriorityDossiersFetched] = useState<boolean>(false);
  
  const [openDossiers, setOpenDossiers] = useState<IDossier[]>([]);
  const [openDossiersFetched, setOpenDossiersFetched] = useState<boolean>(false);
  const [dossiersCount, setDossiersCount] = useState<number>(0);

  const [recentlyClosedDossiers, setRecentlyClosedDossiers] = useState<IDossier[]>([]);
  const [recentlyClosedDossiersFetched, setRecentlyClosedDossiersFetched] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const newdossier_disclosure = useDisclosure();
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ICategory|null>(null);
  const [selectedPriority, setSelectedPriority] = useState<string>('');
  const priorities = ["Deadline urgente d'abord", "Date d'ouverture"];
  const toast = useToast();

  const params = useParams();
  const slug = params.slug;
  useEffect(() => {
    // High priority dossiers
    getDossiers(0, 0, "deadline", [], true, true, true).then((resp) => {
      setHighPriorityDossiers(resp.data.dossiers);
      setHighPriorityDossiersFetched(true);
    });

    // Open dossiers, not high priority
    getDossiers(DOSSIERS_PER_PAGE, 0, "deadline", [], false, true, true).then((resp) => {
      setOpenDossiers(resp.data.dossiers);
      setDossiersCount(resp.data.count);
      setOpenDossiersFetched(true);
    });

    // Recently closed dossiers (not high priority)
    getDossiers(6, 0, "deadline", [], false, false, true).then((resp) => {
      let normal_recently_closed_dossiers = resp.data.dossiers;
      // Filter out the ones which deadline is older than 1 week
      const now = new Date().getTime();
      normal_recently_closed_dossiers = normal_recently_closed_dossiers.filter((dossier) => {
        const deadline = new Date(dossier.deadline).getTime();
        return (now - deadline) < 7*24*60*60*1000;
      });
      setRecentlyClosedDossiers(normal_recently_closed_dossiers);
      // Get the high priority recently closed dossiers
      getDossiers(6, 0, "deadline", [], true, false, true).then((resp) => {
        let high_priority_recently_closed_dossiers = resp.data.dossiers;
        // Filter out the ones which deadline is older than 1 week
        high_priority_recently_closed_dossiers = high_priority_recently_closed_dossiers.filter((dossier) => {
          const deadline = new Date(dossier.deadline).getTime();
          return (now - deadline) < 7*24*60*60*1000;
        });

        setRecentlyClosedDossiers(recentlyClosedDossiers.concat(high_priority_recently_closed_dossiers));
      }).catch((error) => {}).finally(() => { setRecentlyClosedDossiersFetched(true); });
    }).catch((error) => {}).finally(() => { setRecentlyClosedDossiersFetched(true); });
  }, []);


  const [lastPopupTime, setLastPopupTime] = useState<any>(null);
  useEffect(() => {
    if (!slug) return;

    getDossierBySlug(slug).then((resp) => {
      const dossier = resp.data.dossier;
      if (dossier) {
        dossier.defaultOpen = true;
        dossier.user_seen = true;
        if (dossier.high_priority) {
          // Push it into the highPriorityDossiers if it's not already there, otherwise update it
          const index = highPriorityDossiers.findIndex((d) => d._id === dossier._id);
          if (index !== -1) {
            highPriorityDossiers[index] = dossier;
            setHighPriorityDossiers([...highPriorityDossiers]);
          }
        } else {
          // Push it into the openDossiers if it's not already there, otherwise update it
          const index = openDossiers.findIndex((d) => d._id === dossier._id);
          if (index !== -1) {
            openDossiers[index] = dossier;
            setOpenDossiers([...openDossiers]);
          }
        }
      }
    }).catch((error) => {

      if (!lastPopupTime || new Date().getTime() - lastPopupTime > 2000) {
        setLastPopupTime(new Date().getTime());
        toast({
          title: "Erreur",
          description: "Le dossier que vous tentez de consulter n'existe pas.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    });
  }, [slug, openDossiersFetched, highPriorityDossiersFetched]);

  
  // Dossier banner size
  const [filterBannerHeight, setFilterBannerHeight] = useState<number>(0);
  useEffect(() => {
    const element = document.getElementById('filter_banner');
    if (element) {
      setFilterBannerHeight(element.clientHeight);
    }
  }, []);

  // Get categories
  useEffect(() => {
    getCategories().then((resp) => {
      setCategories(resp.data.categories);
    });
  }, []);

  const handleCategoryChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
    let value = e.target.value;
    if (value == "all") {
      fetchFilteredDossiers([]);
      setSelectedCategory(null);
      return;
    }

    const category = categories.find((cat) => cat.name === value);
    if (!category) return;
    setSelectedCategory(category);
    
    fetchFilteredDossiers([category._id]);
  }

  const handlePriorityChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPriority(e.target.value);

    fetchFilteredDossiers(selectedCategory?._id ? [selectedCategory._id] : [], e.target.value);
  }

  const fetchFilteredDossiers = (categories:string[]=[], priority:string="deadline") => {
    if (categories === null) categories = [];

    let filter = "deadline";
    if (priority === "Date d'ouverture") {
      filter = "createdAt";
    }

    // Reset dossiers
    setOpenDossiersFetched(false);
    setOpenDossiers([]);
    setHighPriorityDossiersFetched(false);
    setHighPriorityDossiers([]);
    setRecentlyClosedDossiersFetched(false);
    setRecentlyClosedDossiers([]);

    // High priority dossiers
    getDossiers(DOSSIERS_PER_PAGE, currentPage*DOSSIERS_PER_PAGE, filter, categories, true, true, true).then((resp) => {
      setHighPriorityDossiers(resp.data.dossiers);
      setHighPriorityDossiersFetched(true);
    });
    
    // Open dossiers, not high priority
    getDossiers(DOSSIERS_PER_PAGE, currentPage*DOSSIERS_PER_PAGE, filter, categories, false, true, true).then((resp) => {
      setOpenDossiers(resp.data.dossiers);
      setOpenDossiersFetched(true);
    });

    // Recently closed dossiers (not high priority)
    getDossiers(6, 0, filter, categories, false, false, true).then((resp) => {
      let normal_recently_closed_dossiers = resp.data.dossiers;
      // Filter out the ones which deadline is older than 1 week
      const now = new Date().getTime();
      normal_recently_closed_dossiers = normal_recently_closed_dossiers.filter((dossier) => {
        const deadline = new Date(dossier.deadline).getTime();
        return (now - deadline) < 7*24*60*60*1000;
      });
      setRecentlyClosedDossiers(normal_recently_closed_dossiers);

      // Get the high priority recently closed dossiers
      getDossiers(6, 0, filter, categories, true, false, true).then((resp) => {
        let high_priority_recently_closed_dossiers = resp.data.dossiers;
        // Filter out the ones which deadline is older than 1 week
        high_priority_recently_closed_dossiers = high_priority_recently_closed_dossiers.filter((dossier) => {
          const deadline = new Date(dossier.deadline).getTime();
          return (now - deadline) < 7*24*60*60*1000;
        });
        setRecentlyClosedDossiers(normal_recently_closed_dossiers.concat(high_priority_recently_closed_dossiers));
      }).catch((error) => {}).finally(() => { setRecentlyClosedDossiersFetched(true); });
    }).catch((error) => {}).finally(() => { setRecentlyClosedDossiersFetched(true); });
  }

  const [updateContent, setUpdateContent] = useState<number>(0);

  
  const page_change = (selectedItem: { selected: number }) => {
    const page = selectedItem.selected;
    setCurrentPage(page);

    // Open dossiers, not high priority
    let filter = "deadline";
    if (selectedPriority === "Date d'ouverture") {
      filter = "createdAt";
    }
    getDossiers(DOSSIERS_PER_PAGE, page*DOSSIERS_PER_PAGE, filter, selectedCategory ? [selectedCategory?._id] : [], false, true, true).then((resp) => {
      setOpenDossiers(resp.data.dossiers);
      setDossiersCount(resp.data.count);
      setOpenDossiersFetched(true);
    });
  }

  return (<>
    <Layout>
      <Text mb={5} fontWeight={'900'} color={"#b3b3af"} fontSize={['5xl', '5xl', '7xl']}  letterSpacing={"-.1rem"}> Dossiers</Text>

      <Box bgColor={"white"} border={"1pt solid #e2e2dd"} width={"100%"} position={"absolute"} left={0} zIndex={-1} height={filterBannerHeight+"px"} />

      {/* FILTERS */}
      <Box p={5} id='filter_banner'>
        <Flex>
          <FormControl mb={4} p={4} pe={0}>
            <FormLabel fontSize={"sm"}>
              TRIER PAR CATÉGORIE
            </FormLabel>
            <Select className='prettyOption' rounded={0} me={2} bgColor={"white"} size='lg' value={selectedCategory?.name || ''} onChange={handleCategoryChange}>
              <option value={'all'}>Toutes les catégories</option>
              {categories.map((category) => (
                <option key={category._id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl mb={4} p={4} ps={0}>
            <FormLabel fontSize={"sm"}>
              TRIER PAR PRIORITÉ
            </FormLabel>
            <Select className='prettyOption' rounded={0} me={2} bgColor={"white"} size='lg' value={selectedPriority} onChange={handlePriorityChange}>
              {priorities.map((priority) => (
                <option key={priority} value={priority}>
                  {priority}
                </option>
              ))}
            </Select>
          </FormControl>
        </Flex>
      </Box>

      {/* OPEN NEW DOSSIER */}
      <Flex mt={5}>
        <Spacer />
        <Button variant={'outline'} colorScheme='blue' size='lg' rounded={0} leftIcon={<FontAwesomeIcon icon={faFolderPlus} />} 
          onClick={() => {
            setUpdateContent(updateContent+1);
            newdossier_disclosure.onOpen()
          }
          } mb={5} ml={5}>Ouvrir un nouveau dossier</Button>
      </Flex>
      {NewDossier(newdossier_disclosure.isOpen, newdossier_disclosure.onOpen, newdossier_disclosure.onClose, updateContent)}

      <Box height={'10px'} />

      {(!highPriorityDossiersFetched || highPriorityDossiers.length > 0) && (<>
        <Text mb={3} ms={3} color={"#b44e50"} fontWeight={'800'} fontSize={'sm'}>DOSSIERS PRIORITAIRES</Text>
        <Box mb={10} height={'4px'} bgColor={"#940104"} width={'100%'} />
      </>)}
      
      {highPriorityDossiersFetched && highPriorityDossiers.map((dossier, index) => {
        return (
          <Box key={index} mb={(index < 2) ? 2 : 0}>
            <BigDossierCard dossier={dossier} defaultOpen={dossier.defaultOpen} />
          </Box>
      )})}

      {!highPriorityDossiersFetched &&
        <Skeleton boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} height={'250px'} width={"100%"} mb={"50px"}  />
      }

      {!highPriorityDossiersFetched || highPriorityDossiers.length > 0 && (<>
      <Box height={"50px"}></Box>
      </>)}

      <Box mb={5}>
        <Text mb={3} ms={3} color={"#00707f"} fontWeight={'800'} fontSize={'sm'}>TOUS LES DOSSIERS</Text>
        <Box mb={10} height={'4px'} bgColor={"#00707f"} width={'100%'} />
      </Box>

      
      {openDossiersFetched && openDossiers.length === 0 &&
        <Text textAlign={"center"} color={"#3e3e3e"} fontSize={"md"}>Aucun dossier trouvé</Text>
      }

      {openDossiersFetched &&
        <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(3, 1fr)']} gap={6} mt={3} mb={6}>
          {openDossiers.map((dossier, index) => {
            return (
              <DossierCard key={index} dossier={dossier} defaultOpen={dossier.defaultOpen} />
            )})}
        </Grid>
      }

      {!openDossiersFetched &&
        <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(3, 1fr)']} gap={6} mt={3} mb={6}>
          {[1, 2, 3, 4, 5].map((_, index) => {
            return (
              <Skeleton key={index} boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} height={'150px'} width={"310px"} />
            )})}
        </Grid>
      }

      {dossiersCount > DOSSIERS_PER_PAGE && (
        <Center mt={10}>
          <Pagination 
            page_change_callback={page_change} 
            totalCount={dossiersCount} 
            itemsPerPage={DOSSIERS_PER_PAGE} 
          />
        </Center>
      )}

      
      <Box mb={5} mt={5}>
        <Text mb={3} ms={3} color={"#80807d"} fontWeight={'800'} fontSize={'sm'}>DOSSIERS RÉCEMMENT CLÔTURÉS</Text>
        <Box mb={10} height={'4px'} bgColor={"#80807d"} width={'100%'} />
      </Box>

      {recentlyClosedDossiersFetched &&
        <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(3, 1fr)']} gap={6} mt={3} mb={6}>
          {recentlyClosedDossiers.map((dossier, index) => {
            return (
              <DossierCard key={index} dossier={dossier} defaultOpen={dossier.defaultOpen} />
            )})}
        </Grid>
      }

      {recentlyClosedDossiersFetched && recentlyClosedDossiers.length === 0 &&
        <Text textAlign={"center"} color={"#3e3e3e"} fontSize={"md"}>Aucun dossier récemment clôturé.</Text>
      }

      {!recentlyClosedDossiersFetched &&
        <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(3, 1fr)']} gap={6} mt={3} mb={6}>
          {[1, 2, 3, 4, 5].map((_, index) => {
            return (
              <Skeleton key={index} boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} height={'150px'} width={"310px"} />
            )})}
        </Grid>
      }

      <Flex pb={"30px"} mt={3} mb={4}>
        <Spacer />
        <ReactLink to={'/archives'} onClick={() => {window.scrollTo(0, 0);}}>
          <Text cursor={"pointer"} color={"#00707f"} opacity={0.8} fontSize={"sm"} fontWeight={900}>CONSULTER LES ARCHIVES</Text>
          <Box height={'3px'} bgColor={"#00707f"} width={"40%"} mt={1} />
        </ReactLink>
      </Flex>

      <Box height={'400px'} />
      
    </Layout>
    </>);
}

export default Dossiers;