import { useEffect, useRef, useState } from 'react';
import { Box, Button, Divider, Flex, FormControl, FormHelperText, FormLabel, Input, List, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Skeleton, Spacer, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faMagnifyingGlass, faPersonBooth, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import TextEditor from '../../components/TextEditor';
import { IElection, IElectionCandidate, IUser } from '../../types';
import { addCandidate, getCandidates, getCurrentElection, newElection, removeCandidate, searchUser, updateCandidateApplication, updateElection } from '../../API/elections';
import { formatDate } from 'date-fns';
import { UserSearchModal } from '../UserSearch';


function StartElectionModal (isOpen:boolean, onClose:()=>void, submit_callback:Function, deadline:Date|null, candidates:IElectionCandidate[]) {
    return <Modal
    onClose={onClose} 
    size={"3xl"} 
    isOpen={isOpen}
    motionPreset='scale'
    closeOnOverlayClick={true}
    >
        <ModalOverlay backdropFilter='blur(5px)' />
        <ModalContent>
            <ModalHeader>
            <Text fontWeight={'900'} color={"#314e89"} fontSize={'xx-large'} letterSpacing={"-.1rem"}>Lancer l'élection</Text>
            <Divider mt={3} />
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody mt={0} ps={8} pe={8} pb={8} pt={0}>
                <Text mb={2}>
                    Êtes-vous sûr·e de vouloir démarrer l'élection ? Une fois l'élection démarrée, vous ne pourrez plus la modifier.
                </Text>
                <Text mb={2} fontWeight={700}>
                    La date limite de vote est fixée au {formatDate(deadline || new Date(), "dd/MM/yyyy 'à' HH:mm")}.
                </Text>
                <Text mb={1} fontWeight={700}>
                    Candidat·e·s :
                </Text>
                <List ms={2}>
                {candidates.map((candidate, index) => {
                    return <ListItem key={index}>
                        <Text>{candidate.user.firstname} {candidate.user.lastname.toUpperCase()}</Text>
                    </ListItem>
                })}
                </List>
                <Flex>
                    <Spacer />
                    <Button 
                    onClick={() => {submit_callback(); onClose();}}
                    p={6} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                    me={2}
                    leftIcon={<FontAwesomeIcon icon={faPersonBooth} />} >
                        DÉMARRER
                    </Button>
                </Flex>
            </ModalBody>
        </ModalContent>
    </Modal>
}

export interface NewElectionProps {
    reload_election:Function;
}

const NewElection: React.FC<NewElectionProps> = ({reload_election}) => {
    const toast = useToast();
    const [candidates, setCandidates] = useState<IElectionCandidate[]>([])
    const [election, setElection] = useState<IElection|null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [deleting, setDeleting] = useState<string>("");

    useEffect(() => {
        getCurrentElection().then((resp) => {
            setElection(resp.data.election);
            setDeadline(resp.data.election.deadline);
            getCandidates(resp.data.election._id).then((resp) => {
                setCandidates(resp.data.electionCandidates);
                setLoading(false);
            });
        }).catch((error) => {
            // newElection().then((resp) => {
            //     setElection(resp.data.election);
            // });
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const update_deadline = (date:Date) => {
        if (!election) return;

        updateElection(election._id, {...election, deadline: date}).then((resp) => {
            setElection(resp.data.election);
            setDeadline(date);
        }).catch((error) => {
            toast({
                title: "Erreur lors de la mise à jour de la date limite :"+error.response.data.message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom-right"
            });
        });
    }

    const add_candidate = (candidate_matricule:string) => {
        if (!election) return;

        addCandidate(election._id, candidate_matricule).then((resp) => {
            // Push the received candidate to the candidates array
            setCandidates([...candidates, resp.data.electionCandidate]);
            setElection(resp.data.election);
            toast({
                title: "Candidat·e ajouté·e",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "bottom-right"
            });
        }).catch((error) => {
            toast({
                title: "Erreur lors de l'ajout de candidat·e :"+error.response.data.message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom-right"
            });
        });
    }

    const update_candidate_application = (candidate_id:string, content:string) => {
        if (!election) return;

        updateCandidateApplication(election._id, candidate_id, content).then((resp) => {
            setCandidates(candidates.map((candidate) => {
                if (candidate._id === candidate_id) {
                    return {...candidate, application: content};
                }
                return candidate;
            }));
        }).catch((error) => {
            toast({
                title: "Erreur lors de la mise à jour de la candidature : "+error.response.data.message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom-right"
            });
        });
    }

    const remove_candidate = (candidate_id:string) => {
        if (!election) return;

        setDeleting(candidate_id);
        removeCandidate(election?._id, candidate_id).then((resp) => {
            setCandidates(candidates.filter((candidate) => candidate._id !== candidate_id));
            toast({
                title: "Candidat·e retiré·e",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "bottom-right"
            });
        }).catch((error) => {
            toast({
                title: "Erreur lors de la suppression de candidat·e : "+error.response.data.message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom-right"
            });
        }).finally(() => {
            setDeleting("");
        });
    }

    const start_election = () => {
        if (!election) return;

        updateElection(election._id, {...election, open: true}).then((resp) => {
            setElection(resp.data.election);
            toast({
                title: "Élection démarrée avec succès.",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "bottom-right"
            });
            reload_election();
        }).catch((error) => {
            toast({
                title: "Erreur lors du lancement de l'élection : "+error.response.data.message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "bottom-right"
            });
        });
    }

    const selection_disclosure = useDisclosure();
    const starting_disclosure = useDisclosure();
    const [deadline, setDeadline] = useState<Date>(new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000));

    return <Box>
        {StartElectionModal(starting_disclosure.isOpen, starting_disclosure.onClose, start_election, deadline, candidates)}
        {UserSearchModal(selection_disclosure.isOpen, selection_disclosure.onClose, add_candidate)}
        {/* {UserSearchModal(true, selection_disclosure.onClose, add_candidate)} */}
        <Text mb={3} ms={3} color={"#004851"} fontWeight={'800'} fontSize={'sm'}>NOUVELLE ÉLECTION DE BUREAU</Text>
        <Box mb={5} height={'4px'} bgColor={"#004851"} width={'100%'} />

        <Box bgColor={"white"} border={"1pt solid #e2e2dd"} p={8}>

        <Text textAlign={'justify'} color={'#131313'}>
            Cette interface vous permet de lancer des élections de bureau. Les membres pourront voter pour les candidats de leur choix, et une fois la période électorale terminée, les résultats seront affichés ici. Vous pourrez alors nommer les nouveaux membres du bureau.
        </Text>

        <Text color={'#131313'} fontWeight={"700"} mt={3}>
        <FontAwesomeIcon icon={faCircleExclamation} style={{marginRight:"5px"}} />Attention : une fois l'élection lancée, vous ne pourrez pas la modifier !
        </Text>

        <FormControl mt={5}>
            <FormLabel>Deadline</FormLabel>
            <DateTimePickerComponent 
                readonly={loading}
                locale='fr' 
                width={'250px'} 
                id="datetimepicker"
                min={new Date()}
                value={deadline}
                allowEdit={false}
                onChange={(e:any) => {update_deadline(e.value)}}
            />
            <FormHelperText>Fin de la période électorale</FormHelperText>
        </FormControl>

        <FormControl mt={5}>
            <FormLabel>Candidat·e·s</FormLabel>
        </FormControl>

        {loading && [1,2,3].map((i) => {
            return <Skeleton key={i} height={"290px"} mb={5} />
        })}

        {candidates.map((candidate, index) => {
            return <Box key={index} color={'#131313'} bgColor={"white"} border={"1pt solid #e2e2dd"} p={5} mb={5}>
                <Flex mb={4}>
                <Text fontWeight={"700"} fontSize={'md'}>{candidate.user.firstname} {candidate.user.lastname.toUpperCase()}</Text>
                <Spacer />
                <Button 
                    p={3} colorScheme='red' variant={'outline'} size="sm" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                    onClick={() => {remove_candidate(candidate._id)}}
                    isDisabled={deleting === candidate._id}
                    >
                    SUPPRIMER
                    </Button>
                </Flex>

                <Text fontSize={"md"} mb={2}>Candidature</Text>
                <TextEditor content={candidate.application} update_callback={(e:string) => {update_candidate_application(candidate._id,e)}} maxLength={2000} disabled={deleting === candidate._id} />
            </Box>
        })}

        <Flex>
            {/* <Spacer /> */}
            <Button 
            p={3} m={3} colorScheme='teal' variant={'outline'} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
            onClick={selection_disclosure.onOpen}>
                AJOUTER UN·E CANDIDAT·E
            </Button>
        </Flex>
        
        <Flex>
          <Spacer />
          <Button 
            onClick={starting_disclosure.onOpen}
            p={6} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
            leftIcon={<FontAwesomeIcon icon={faPersonBooth} />} >
              LANCER L'ÉLECTION
            </Button>
        </Flex>

        </Box>

    </Box>
}

export default NewElection;