import { AxiosResponse } from "axios";
import API, { API_URL, ApiDataType } from "./api";
import { IContactForm } from "../types";

export const getCurrentContactDraft = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(`${API_URL}/contact/draft`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const updateCurrentContactDraft = async (draft: IContactForm): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.put(`${API_URL}/contact/draft`, draft);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getContactForms = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(`${API_URL}/contact`);
        return resp;
    } catch (error) {
        throw error;
    }
}

export const updateContactForm = async (form: IContactForm): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.put(`${API_URL}/contact`, form);
        return resp;
    } catch (error) {
        throw error;
    }
}