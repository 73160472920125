import { AxiosResponse } from "axios";
import API, { API_URL, ApiDataType } from "./api";
import { PollType } from "../types";

export const votePoll = async ( poll_id:string, option:any): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/polls/${poll_id}/vote`,
            { option }
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getUserVote = async (poll_id:string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/polls/${poll_id}/current_vote`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const createPoll = async (title:string, description:string, type:PollType, answers:string[], deadline:Date, dossier_id:string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/polls/new`,
            { title, description, type, answers, deadline, dossier_id }
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const stopPoll = async (poll_id:string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/polls/${poll_id}/stop`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getPollResults = async (poll_id:string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/polls/${poll_id}/results`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}