import { Box, Button, Checkbox, Collapse, Divider, Flex, FormControl, FormHelperText, FormLabel, Grid, Input, Spacer, Text, Textarea, useDisclosure, useToast } from "@chakra-ui/react";
import { faFile, faPaperclip, faTags } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "date-fns";
import LoremIpsum from "react-lorem-ipsum";
import { ICategory, IContactForm, IDossierRequest, pollTypeToString } from "../../types";
import { useRef, useState } from "react";
import { manageDossierRequest } from "../../API/dossier";
import { CheckBox } from "@syncfusion/ej2/buttons";
import { downloadFile } from "../../API/file";

export interface ContactFormCardProps {
    form:IContactForm;
    handle_callback:Function;
}

const ContactFormCard: React.FC<ContactFormCardProps> = ({form, handle_callback}) => {
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
    const toast = useToast();
    const [submitting, setSubmitting] = useState<boolean>(false);

    const download_file = (file_id:string) => {
        downloadFile(file_id).then((resp) => {
          const contentDisposition = resp.headers['content-disposition'];
          const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        });
    }

    const open_card_wrapper = () => {
        onToggle();
    }

    const handleArchive = () => {
        const new_form = {...form};
        new_form.archived = true;
        onToggle();
        
        handle_callback(new_form);
    }

    return <Box mb={5} onClick={isOpen ? () => {}: open_card_wrapper} cursor={isOpen ? "default" : "pointer"} width={"100%"} bgColor={"white"} p={6} pb={5} border={"1pt solid #e2e2dd"} position={'relative'}>
    <Flex>
        <Text letterSpacing={"-.01rem"} lineHeight={"1.2"} fontWeight={'700'} fontSize={'lg'} color={"#00707f"}>Sujet : {form.subject}</Text>
        <Spacer />
        <Text cursor={'pointer'} onClick={() => { if (isOpen) onClose();}} color={"#00707f"} fontSize={"xs"} fontWeight={900} pt={2} pe={2}>
            {isOpen ? "FERMER" : "CONSULTER"}
        </Text>
    </Flex>
    <Text fontWeight={'400'} fontSize={'sm'} color={"#7f8591"}>Envoyé par {form.author} le {formatDate(form.createdAt ?? new Date(), "dd/MM/yyyy à HH:mm")}</Text>

    <Collapse in={isOpen} animateOpacity>
        <Divider mt={5} mb={5} />

        <Box mt={8}>
            <Text mb={3} ms={3} color={"#004851"} fontWeight={'800'} fontSize={'sm'}>CONTENU</Text>
            <Box mb={5} height={'4px'} bgColor={"#004851"} width={'100%'} />
        </Box>

        <Box border={"1pt solid #e2e2dd"} bgColor={"white"} p={5} textAlign={"justify"}>
            <Box dangerouslySetInnerHTML={{__html: form.content}} />
        </Box>

        {form.attached_files.length > 0 &&
        <Box p={3} mt={2}>
            <Text fontSize={'lg'} fontWeight={'700'} color={"#314e89"} mb={2}>
                <FontAwesomeIcon icon={faPaperclip} style={{marginRight:"5px"}} />Fichiers joints
            </Text>
            <Grid templateColumns='repeat(4, 2fr)' gap={6}>
                {form.attached_files.map((attachment, index) => {
                    return <Box key={index} cursor={'pointer'} bgColor={'white'} p={3} textAlign={'center'} border={"1pt solid #e2e2dd"} onClick={() => {download_file(attachment.id)}}>
                        <Text fontWeight={'400'} ><FontAwesomeIcon icon={faFile} style={{marginRight:"5px"}} />{attachment.name}</Text>
                    </Box>
                })}
            </Grid>
        </Box>
        }

        <Box mt={8}>
            <Text mb={3} ms={3} color={"#5b257d"} fontWeight={'800'} fontSize={'sm'}>ACTIONS</Text>
            <Box mb={5} height={'4px'} bgColor={"#5b257d"} width={'100%'} />
        </Box>

        <Flex>
            <Spacer />
            <Button 
                p={6} m={3} ms={2} mt={0} color={"white"} _hover={{backgroundColor:"#8da0bc"}} bgColor={"gray.500"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={handleArchive} isDisabled={submitting} >
                ARCHIVER
            </Button>
        </Flex>
    </Collapse>
</Box>
}

export default ContactFormCard;