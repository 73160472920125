import { AxiosResponse } from "axios";
import API, { API_URL, ApiDataType } from "./api";

/**
 * GET request to the server to get threads
 */
export const getThreadsFromDossier = async ( dossier_id:string, offset:number=0, limit:number=0, lightweight=false ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/threads/dossier/${dossier_id}`,
            { lightweight, offset, limit }
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getThreadsFromCategory = async ( category_id:string, offset:number=0, limit:number=0, lightweight:boolean=false): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/threads/category/${category_id}`,
            { lightweight, offset, limit }
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getThreadsNumberFromCategory = async ( category_id:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/threads/count/${category_id}`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getThreadsNumberFromDossier = async ( dossier_id:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/threads/count/dossier/${dossier_id}`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getThreadById = async ( thread_id:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/threads/${thread_id}`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const newThread = async ( category_id:string, dossier_id:string, title:string, content:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/threads/new`,
            { 
                category_id:category_id, 
                dossier_id:dossier_id, 
                title:title, 
                content:content }
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getThreadPosts = async ( thread_id:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/threads/${thread_id}/posts`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const newPost = async ( thread_id:string, content:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/threads/${thread_id}/posts/new`,
            { content }
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const updatePost = async ( post_id:string, content:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.put(
            `${API_URL}/threads/posts/${post_id}`,
            { content }
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const deletePost = async ( post_id:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.delete(
            `${API_URL}/threads/posts/${post_id}`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const watchThread = async ( thread_id:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/threads/watch/${thread_id}`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}