import '../../Home.css';
import { Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import AccessRequests from './AccessRequests';
import Whitelist from './Whitelist';
import Blacklist from './Blacklist';
import Admins from './Admins';

export interface MembersProps {}

const Members: React.FC<MembersProps> = ({}) => {

    return (
    <Tabs position="relative" variant="unstyled" mt={5} isLazy={true}>
        <TabList>
            <Tab fontWeight={800} p={3} fontSize={'sm'}>DEMANDES D'ACCÈS</Tab>
            <Tab fontWeight={800} p={3} fontSize={'sm'}>WHITELIST</Tab>
            <Tab fontWeight={800} p={3} fontSize={'sm'}>BLACKLIST</Tab>
            <Tab fontWeight={800} p={3} fontSize={'sm'}>BUREAU</Tab>
        </TabList>
        <TabIndicator
            mt="-1.5px"
            height="3px"
            bg="#004851"
            borderRadius="1px"
        />
        <TabPanels>
            <TabPanel p={0} pt={8}>
                <AccessRequests />
            </TabPanel>
            <TabPanel p={0} pt={8}>
                <Whitelist />
            </TabPanel>
            <TabPanel p={0} pt={8}>
                <Blacklist />
            </TabPanel>
            <TabPanel p={0} pt={8}>
                <Admins />
            </TabPanel>
        </TabPanels>
  </Tabs>);
}

export default Members;