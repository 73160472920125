import { useEffect, useState } from 'react';
import '../Home.css';
import { Avatar, Box, Button, Center, Flex, Grid, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Skeleton, SkeletonText, Spacer, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { Layout } from '../Layout';
import { IElection, IElectionCandidate, IElectionVote } from '../types';
import { useUser } from '../UserContext';
import { faCheckToSlot, faCircleInfo, faClipboardCheck, faScroll } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCandidates, getCurrentElection, getFinishedElection, getMyVote, getVotesPerCandidate, submitMyVote, updateVote } from '../API/elections';
import { differenceInDays, differenceInHours, differenceInMinutes, formatDate } from 'date-fns';
import { fr } from 'date-fns/locale';
import { getProfilePicture } from '../API/file';

function CandidateModal(isOpen:boolean, onOpen:any, onClose:any, candidate:IElectionCandidate, avatarb64:string) {

  return (<Modal 
    onClose={onClose} 
    size={"4xl"} 
    isOpen={isOpen}
    motionPreset='scale'
    closeOnOverlayClick={true}
    >
      <ModalOverlay backdropFilter='blur(5px)' />
      <ModalContent>
        <ModalHeader>
          <Text letterSpacing={'-.1rem'} fontSize={'3xl'} fontWeight={900} color={"#4a4a4a"} >
            {candidate.user.firstname + " " + candidate.user.lastname}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={5}>

          <Center>
            <Avatar
              mb={8}
              size='2xl'
              name={candidate.user.firstname + " " + candidate.user.lastname}
              src={avatarb64}
              fontWeight={'700'}
            />
          </Center>

          <Box border={"1pt solid #e2e2dd"} p={8} textAlign={"justify"} dangerouslySetInnerHTML={{__html: candidate.application}} />

        </ModalBody>
        </ModalContent>
    </Modal>);
}

export interface CandidateCardProps {
  candidate:IElectionCandidate;
  totalVotes:number;
  myVotes:number;
}

const CandidateCard: React.FC<CandidateCardProps> = ({candidate, totalVotes, myVotes}) => {
  const modal_disclosure = useDisclosure();
  
  const [b64Image, setB64Image] = useState<string>("");
  useEffect(() => {
    getProfilePicture(candidate.user.matricule).then((res) => {
      const reader = new FileReader();
      reader.onloadend = () => {
          setB64Image(reader.result as string);
      };
      reader.readAsDataURL(res.data);
  }).catch((err) => {
  });
  }, []);

  return <Box mb={5} p={7} pt={10} boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} border={"1pt solid #e2e2dd"} bgColor={"white"}>
    {CandidateModal(modal_disclosure.isOpen, modal_disclosure.onOpen, modal_disclosure.onClose, candidate, b64Image)}
  <Center>
    <Avatar
      mb={4}
      size='xl'
      name={candidate.user.firstname + " " + candidate.user.lastname}
      src={b64Image}
      fontWeight={'700'}
    />
  </Center>
  <Center>
    <Text letterSpacing={'-.1rem'} fontSize={'2xl'} fontWeight={900} color={"#00707f"} >
      {candidate.user.firstname + " " + candidate.user.lastname} a reçu {candidate.votes_number || 0} vote{candidate.votes_number && candidate.votes_number > 1 ? "s" : ""}
    </Text>
  </Center>

  <Flex>
    <Box mt={7}>
      {myVotes >= 1 &&
      <Text><FontAwesomeIcon icon={faCheckToSlot} style={{marginRight:"5px"}} color='#131313' />Vous avez attribué {myVotes} point{myVotes > 1 ? "s" : ""} à cette candidature</Text>
      }
      {myVotes === 0 &&
      <Text><FontAwesomeIcon icon={faCircleInfo} style={{marginRight:"5px"}} color='#131313' />Vous n'avez pas voté pour cette candidature</Text>
      }
    </Box>
    <Spacer />
    <Button p={4} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={7} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
    leftIcon={<FontAwesomeIcon icon={faScroll} />} onClick={modal_disclosure.onOpen} >CANDIDATURE</Button>
  </Flex>

</Box>
}

function ElectionsEnd() {
  const { user } = useUser();
  const [election, setElection] = useState<IElection|null>(null);
  const [candidates, setCandidates] = useState<IElectionCandidate[]>([]);
  const [myVote, setMyVote] = useState<IElectionVote|null>(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [votingCandidate, setVotingCandidate] = useState<string>("");
  const toast = useToast();
  const [deadlinePassed, setDeadlinePassed] = useState(false);
  const confirm_disclosure = useDisclosure();
  const [availablePoints, setAvailablePoints] = useState([1, 2, 3]);

  useEffect(() => {
    getFinishedElection().then((res) => {
      const election_resp = res.data.election;

      // Is election open?
      if (!election_resp.open) {
        // redirect to home
        window.location.href = "/";
        return;
      }

      setElection(res.data.election);
      getVotesPerCandidate(res.data.election._id).then((res) => {
          const candidates_resp = res.data.electionCandidates;
          // Sort the candidates by number of votes
          candidates_resp.sort((a, b) => {
            return (b.votes_number || 0) - (a.votes_number || 0);
          });
          setCandidates(candidates_resp);
          
          getMyVote(election_resp._id).then((res) => {
            let myVoteResponse = res.data.electionVote;
            
            // If some of the candidates are not in the .choices, add them with a 0 points vote
            for (let i = 0; i < candidates_resp.length; i++) {
              const candidate = candidates_resp[i];
              const candidateVote = myVoteResponse.choices.find((choice) => choice.candidate === candidate._id);
              if (!candidateVote) {
                myVoteResponse.choices.push({
                  candidate: candidate._id,
                  points: 0,
                });
              }
            }

            // Update the available points (if there is a 1 vote, remove the 1, if there is a 2 vote, remove the 2, etc.)
            const newAvailablePoints = [1, 2, 3].filter((point) => {
              return myVoteResponse.choices.find((choice) => choice.points === point) === undefined;
            });
            setAvailablePoints(newAvailablePoints);

            setMyVote(myVoteResponse);
            setLoading(false);
          });
        });
    }).catch((err) => {
      window.location.href = "/";
    });
  }, []);

  const myVotePerCandidate = (candidate_id: string) => {
    if (!myVote) return 0;
    const candidateVote = myVote.choices.find((choice) => choice.candidate === candidate_id);
    if (candidateVote) {
      return candidateVote.points;
    } else {
      return 0;
    }
  }

  return (<>
    <Layout>

    <Text fontWeight={'900'} color={"#e6e6e1"} fontSize={'7xl'} letterSpacing={"-.1rem"} mb={5} mt={5}>Résultats des élections</Text>

    <Center>
      <Box boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} border={"1pt solid #e2e2dd"} bgColor={"white"} p={10} w={'80%'}>
        <Text fontWeight={'900'} color={"#4a4a4a"} letterSpacing={'-.05rem'} fontSize={"24px"}>Élections du Bureau ADERE</Text>
        <Text color={"#131313"} lineHeight={'1.5'} mt={5}>
          Ces élections se sont terminées le <Text as={"span"} fontWeight={700}>{formatDate(election?.deadline || new Date(), "dd/MM/yyyy à HH:mm", { locale: fr })}</Text>.
        </Text>

        {!election && <SkeletonText mt={5} noOfLines={1} height={"18px"} spacing="4" width={"50%"} />}
      </Box>
    </Center>

    <Box mb={10} mt={"70px"}>
      <Text mb={3} ms={3} color={"#004851"} fontWeight={'800'} fontSize={'sm'}>RÉSULTATS</Text>
      <Box mb={5} height={'4px'} bgColor={"#004851"} width={'100%'} />
    </Box>


    {loading && [1, 2, 3, 4].map((_, index) => <Skeleton boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} border={"1pt solid #e2e2dd"} key={index} height="350px" width={"100%"} />)}

    {!loading && candidates.map((candidate, index) => (
      <CandidateCard 
        key={index} candidate={candidate} totalVotes={candidate.votes_number || 0} myVotes={myVotePerCandidate(candidate._id)} 
        // vote_callback={handleVote}
        />
    ))}

    <Box height={'850px'} />

    </Layout>
  </>);
}

export default ElectionsEnd;