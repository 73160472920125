import React, { useEffect } from 'react';
import '../Home.css';
import { Avatar, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Center, Collapse, Divider, Flex, FormControl, FormHelperText, Grid, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Square, Table, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tooltip, Tr, VStack, useDisclosure, useToast } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faComment, faComments, faEye, faHeart, faPaperPlane, faPenToSquare, faTag, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import LoremIpsum from 'react-lorem-ipsum';
import { faCalendar, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { IDossier, IThread, IThreadPost } from '../types';
import { getDossier } from '../API/dossier';
import { deletePost, getThreadPosts, newPost, updatePost, watchThread } from '../API/thread';
import { formatDate } from 'date-fns';
import { API_URL, ApiDataType } from '../API/api';
import { useUser } from '../UserContext';
import { getProfilePicture } from '../API/file';
import TextEditor from '../components/TextEditor';

function EditorCollapse(isOpen:any, onToggle:any, new_post_callback:Function) {
    const [newPostContent, setNewPostContent] = React.useState<string>("");
    const MAX_POST_LENGTH = 2000;

    const handleNewPostChange = (event: string) => {
      setNewPostContent(event);
    };

    const handleNewPostSubmit = () => {
      if (newPostContent.trim() !== "") {
        createPost(newPostContent);
        setNewPostContent("");
      }
    };

    const createPost = (content: string) => {
      if (content.trim() === "") return;
      new_post_callback(content);
      onToggle();
    };

    const [editorSeed, setEditorSeed] = React.useState<number>(1);
    useEffect(() => {
      setEditorSeed(Math.random());
    }, [isOpen]);

    return (
      <Box mt={4}>
        {isOpen &&<>
          <TextEditor withImage={false} seed={editorSeed} content={newPostContent} update_callback={handleNewPostChange} disabled={false} maxLength={MAX_POST_LENGTH} />
          
          <Flex>
            <Spacer />
            {/* <Button 
              mt={2} 
              size={"sm"} 
              colorScheme="blue" 
              variant="outline" 
              rightIcon={<FontAwesomeIcon icon={faPaperPlane} />}
              onClick={handleNewPostSubmit}
            >
              Envoyer
            </Button> */}
            
            <Button 
              p={5} me={3} color={"white"} _hover={{backgroundColor:"#3e63af"}} bgColor={"#314e89"} size="sm" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
              onClick={handleNewPostSubmit}
              leftIcon={<FontAwesomeIcon icon={faPaperPlane} />} >
                ENVOYER LA RÉPONSE
            </Button>
          </Flex>
        </>}
      </Box>
    );
}

export function ConfirmDeleteModal(isOpen:boolean, onClose:any, onConfirm:any) {
  return (
    <Modal 
      onClose={onClose} 
      size={"sm"} 
      isOpen={isOpen}
      motionPreset='scale'
      closeOnOverlayClick={false}
      >
        <ModalOverlay backdropFilter='blur(2px)' />
        <ModalContent>
          <ModalHeader>
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody pt={0}>
            <Text mb={3} mt={3} fontWeight={'700'} letterSpacing={"-.05rem"} color={"#314e89"} fontSize={'lg'}>
              Êtes-vous sûr·e de vouloir supprimer ce post ?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onConfirm}>
              Supprimer
            </Button>
            <Button onClick={onClose}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
    </Modal>
    )

}

export function Thread(isOpen:boolean, onOpen:any, onClose:any, thread:IThread|null, dossier:IDossier|null, thread_updated_callback:Function, readonly:boolean=false) {
    const MAX_POST_LENGTH = 2000;
    const disclose_editor = useDisclosure();
    const disclose_delete = useDisclosure();
    const [threadPosts, setThreadPosts] = React.useState<IThreadPost[]>([]);
    const [author, setAuthor] = React.useState<string>("");
    const [authorPictureb64, setAuthorPictureb64] = React.useState<string>("");
    const toast = useToast();
    const { user } = useUser();
    const [editedPost, setEditedPost] = React.useState<string|null>(null);
    const [editedPostContent, setEditedPostContent] = React.useState<string>("");
    const [deletedPost, setDeletedPost] = React.useState<string|null>(null);
    const [usersPictures, setUsersPictures] = React.useState<Record<string, string>>({}); // matricule -> b64

    // Get Thread posts
    useEffect(() => {
      if (!thread) return;
      if (!isOpen) return;
      getThreadPosts(thread._id).then((resp:any) => {
        const threadPostsResp: IThreadPost[] = resp.data.threadPosts;
        setThreadPosts(threadPostsResp);
        setAuthor((threadPostsResp.length > 0) ? threadPostsResp[0].author_name || "" : "");

        getProfilePicture(threadPostsResp[0].author ?? "").then((resp:any) => {
          const reader = new FileReader();
            reader.onloadend = () => {
              setAuthorPictureb64(reader.result as string);
            };
            reader.readAsDataURL(resp.data);
        }).catch((error:any) => {});

        // Get profile pictures of all users
        const users = threadPostsResp.map(post => post.author);
        const usersSet = new Set(users);
        const usersArray = Array.from(usersSet);
        usersArray.forEach(user => {
          getProfilePicture(user).then((resp:any) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              setUsersPictures({...usersPictures, [user]: reader.result as string});
            };
            reader.readAsDataURL(resp.data);
          }).catch((error:any) => {});
        });
      });

      // Watch the thread
      watchThread(thread._id).then((resp:any) => {}).catch((error:any) => {});
    }, [thread]);

    // Create a new post
    const createPost = (post:string) => {
      if (!thread) return;
      newPost(thread._id, post).then((resp:any) => {
          const new_post = resp.data.threadPost;
          setThreadPosts([...threadPosts, new_post]);
          thread_updated_callback();
          toast({
            title: "Post créé",
            description: "Ce post a bien été créé",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right"
          });
      }).catch((error:any) => {
          toast({
            title: "Erreur",
            description: "Une erreur est survenue lors de la création du post",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right"
          });
      });
    }

    const editPost = (post_id:string) => {
      updatePost(post_id, editedPostContent).then((resp:any) => {
        const updated_post = resp.data.threadPost;
        const updated_posts = threadPosts.map((post:IThreadPost) => {
          if (post._id == updated_post._id) {
            return updated_post;
          }
          return post;
        });
        setThreadPosts(updated_posts);
        setEditedPost(null);
        setEditedPostContent("");
        toast({
          title: "Post modifié",
          description: "Le post a bien été modifié",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right"
        });
        thread_updated_callback();
      }).catch((error:any) => {
        toast({
          title: "Erreur",
          description: "Une erreur est survenue lors de la modification du post",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right"
        });
      });
    }

    const deletePost_callback = (post_id:string) => {
      setDeletedPost(post_id);
      disclose_delete.onOpen();
    }

    const confirm_delete = () => {
      if (!deletedPost) return;

      deletePost(deletedPost).then((resp:any) => {
        thread_updated_callback();
        // If it is the only post of the thread, close the modal and the thread
        if (threadPosts.length == 1) {
          setThreadPosts([]);
          onClose();
          disclose_delete.onClose();

          if (window.location.pathname.includes("/forum"))
            window.history.pushState({}, "", "/forum");
          toast({
            title: "Post supprimé",
            description: "Le post a bien été supprimé",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right"
          });
          return;
        }

        // If the deletedPost is the first of the thread, set the content to "Ce post a été supprimé."
        if (deletedPost == threadPosts[0]?._id) {
          const updated_post = threadPosts[0];
          updated_post.content = "Ce post a été supprimé.";
          updated_post.author = "deleted";
          updated_post.author_name = "Utilisateur inconnu";
          updated_post.updatedAt = new Date();
          setAuthor("Utilisateur inconnu");
          const updated_posts = threadPosts.map((post:IThreadPost) => {
            if (post._id == updated_post._id) {
              return updated_post;
            }
            return post;
          });
          setThreadPosts(updated_posts);
          setDeletedPost(null);
          disclose_delete.onClose();
          toast({
            title: "Post supprimé",
            description: "Le post a bien été supprimé",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right"
          });
          return;
        }
        const updated_posts = threadPosts.filter((post:IThreadPost) => post._id != deletedPost);
        setThreadPosts(updated_posts);
        setDeletedPost(null);
        disclose_delete.onClose();
        toast({
          title: "Post supprimé",
          description: "Le post a bien été supprimé",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right"
        });
      }).catch((error:any) => {
        toast({
          title: "Erreur",
          description: "Une erreur est survenue lors de la suppression du post",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right"
        });
      });
      setDeletedPost(null);
      disclose_delete.onClose();
    }

    const toggle_edition_wrapper = () => {
      disclose_editor.onToggle();
    }

    return (<>
      <Modal 
        onClose={onClose} 
        size={"6xl"} 
        isOpen={isOpen}
        motionPreset='scale'
        closeOnOverlayClick={false}
        >
          <ModalOverlay backdropFilter='blur(2px)' />
          <ModalContent>
            <ModalHeader>
            </ModalHeader>
  
            <ModalCloseButton />
  
            <ModalBody pt={0}>

              {dossier && <Breadcrumb fontWeight='medium' fontSize='sm'>
                <BreadcrumbItem>
                  <BreadcrumbLink href='#'>"{dossier.title}"</BreadcrumbLink>
                </BreadcrumbItem>
    
                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink href='#'>Post de {author}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>}

              {!dossier && <Breadcrumb fontWeight='medium' fontSize='sm'>
                <BreadcrumbItem>
                  <BreadcrumbLink href='#'>Forum</BreadcrumbLink>
                </BreadcrumbItem>
              
                <BreadcrumbItem>
                  <BreadcrumbLink href='#'>{thread?.category}</BreadcrumbLink>
                </BreadcrumbItem>
    
                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink href='#'>Post de {author}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>}

  
              <Text mb={3} mt={3} fontWeight={'900'} letterSpacing={"-.05rem"} color={"#314e89"} fontSize={'x-large'}>
                <FontAwesomeIcon icon={faComments} style={{marginRight:"5px"}} />Sujet : {thread?.title}
              </Text>
  
              <Divider mb={5} />
  
              <Flex>
                <Avatar me={4} size={'md'} name={author} src={authorPictureb64 || ""} />
                <Grid templateColumns='repeat(1, 2fr)' gap={0}>
                  <Text align={"left"} fontWeight={'500'}>{author}</Text>
                  <Text align={"left"} fontSize={"sm"} color={"gray"}><FontAwesomeIcon icon={faCalendar} style={{marginRight:"5px"}} />Publié le {formatDate( threadPosts[0]?.createdAt ?? new Date(), "dd/MM/yyyy à HH:mm")} {(threadPosts[0]?.createdAt != threadPosts[0]?.updatedAt) ? " - modifié le "+formatDate( threadPosts[0]?.updatedAt ?? new Date(), "dd/MM/yyyy à HH:mm") : ""}</Text>
                </Grid>
              </Flex>
  
              <Box mt={5} p={4} bgColor={"white"} border={"1pt solid #e2e2dd"}fontWeight={'400'} fontSize={'sm'} textAlign={'justify'}>
                  {editedPost == threadPosts[0]?._id ? (
                      <TextEditor content={editedPostContent} update_callback={setEditedPostContent} disabled={false} maxLength={MAX_POST_LENGTH} />
                  ) : (
                    <Box className='forum-post' dangerouslySetInnerHTML={{__html:threadPosts[0]?.content}} />
                  )}

                  <Flex>
                    <Spacer />
                    {((editedPost != threadPosts[0]?._id)) && (user?.rank == "admin" || user?.matricule == threadPosts[0]?.author) && (
                      <Button rounded={0} me={2} mt={2} size={"sm"} ml={'auto'} colorScheme="red" variant="outline"
                      rightIcon={<FontAwesomeIcon icon={faTrashCan} />}
                      onClick={
                        () => {
                          deletePost_callback(threadPosts[0]?._id);
                        }
                      }>Supprimer</Button>
                    )}
                    {(editedPost != threadPosts[0]?._id) && user?.matricule == threadPosts[0]?.author && (
                      <Button rounded={0} mt={2} size={"sm"} ml={'auto'} colorScheme="teal" variant="outline" 
                      rightIcon={<FontAwesomeIcon icon={faPenToSquare} />}
                      onClick={
                        () => {
                          if (editedPost == threadPosts[0]?._id) {
                            setEditedPost(null);
                            setEditedPostContent("");
                          } else {
                            setEditedPost(threadPosts[0]?._id);
                            setEditedPostContent(threadPosts[0]?.content);
                          }
                        }}>Éditer</Button>
                    )}
                    {editedPost == threadPosts[0]?._id && (<>
                      <Button rounded={0} mt={2} me={2} size={"sm"} ml={'auto'} colorScheme="teal" variant="outline" onClick={
                        () => {
                          editPost(threadPosts[0]?._id);
                        }
                      }>Valider</Button>
                      <Button rounded={0} mt={2} size={"sm"} ml={'auto'} colorScheme="red" variant="outline" onClick={
                        () => {
                          setEditedPost(null);
                          setEditedPostContent("");
                        }
                      }>Annuler</Button>
                    </>)}
                  </Flex>
              </Box>
  
              <Flex>
                <Spacer />
                <Button 
                  isDisabled={readonly}
                  p={6} color={"white"} _hover={{backgroundColor:"#3e63af"}} bgColor={"#314e89"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                  onClick={toggle_edition_wrapper}
                  leftIcon={<FontAwesomeIcon icon={disclose_editor.isOpen ? faChevronDown : faChevronUp} />} >
                      RÉPONDRE
                </Button>
              </Flex>
  
              {EditorCollapse(disclose_editor.isOpen, disclose_editor.onToggle, createPost)}
  
              <Divider mt={5} mb={5} />
  
              <Text mb={5} fontWeight={'700'} color={"#314e89"} fontSize={'large'}>
                Réponses
              </Text>
  
              {threadPosts.slice(1).length == 0 &&
                <Center mt={2} mb={2}>
                  <Text color={"gray"} fontSize={'sm'}>Aucune réponse pour le moment</Text>
                </Center>
              }
              {threadPosts.slice(1).map((post: IThreadPost) => (
                <Box key={post._id}>
                  <Flex>
                    <Avatar me={4} size={'md'} name={post.author_name} 
                    src={usersPictures[post.author] || ""} />
                    <Grid templateColumns='repeat(1, 2fr)' gap={0}>
                      <Text align={"left"} fontWeight={'500'}>{post.author_name}</Text>
                      <Text align={"left"} fontSize={"sm"} color={"gray"}><FontAwesomeIcon icon={faCalendar} style={{marginRight:"5px"}} />Publié le {formatDate( post.createdAt, "dd/MM/yyyy à HH:mm")}
                      {(post.createdAt != post.updatedAt) ? " - modifié le "+formatDate( post.updatedAt, "dd/MM/yyyy à HH:mm") : ""}
                      </Text>
                    </Grid>
                  </Flex>
  
                  <Box mt={5} p={4} bgColor={"white"} border={"1pt solid #e2e2dd"} fontWeight={'400'} fontSize={'md'} textAlign={'justify'}>

                      {editedPost == post._id ? (
                        <TextEditor content={editedPostContent} update_callback={setEditedPostContent} disabled={false} maxLength={MAX_POST_LENGTH} />
                      ) : (
                        <Box dangerouslySetInnerHTML={{__html:post.content}} />
                      )}

                      <Flex>
                        <Spacer />
                        {((editedPost != post._id)) && (user?.rank == "admin" || user?.matricule == post.author) && (
                          <Button rounded={0} me={2} mt={2} size={"sm"} ml={'auto'} colorScheme="red" variant="outline"
                          rightIcon={<FontAwesomeIcon icon={faTrashCan} />}
                          onClick={
                            () => {
                              deletePost_callback(post._id);
                            }
                          }>Supprimer</Button>
                        )}
                        {(editedPost != post._id) &&user?.matricule == post.author && (
                          <Button rounded={0} mt={2} size={"sm"} ml={'auto'} colorScheme="teal" variant="outline" 
                          rightIcon={<FontAwesomeIcon icon={faPenToSquare} />}
                          onClick={
                            () => {
                              if (editedPost == post._id) {
                                setEditedPost(null);
                                setEditedPostContent("");
                              } else {
                                setEditedPost(post._id);
                                setEditedPostContent(post.content);
                              }
                            }}>Éditer</Button>
                        )}
                        {editedPost == post._id && (<>
                          <Button rounded={0} mt={2} me={2} size={"sm"} ml={'auto'} colorScheme="teal" variant="outline" onClick={
                            () => {
                              editPost(post._id);
                            }
                          }>Valider</Button>
                          <Button rounded={0} mt={2} size={"sm"} ml={'auto'} colorScheme="red" variant="outline" onClick={
                            () => {
                              setEditedPost(null);
                              setEditedPostContent("");
                            }
                          }>Annuler</Button>
                        </>)}
                      </Flex>
                  </Box>
  
                  <Divider mt={5} mb={5} />
                </Box>
              ))}
  
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </ModalContent>
      </Modal>
      {ConfirmDeleteModal(disclose_delete.isOpen, disclose_delete.onClose, confirm_delete)}
      </>)
  }