import { useEffect, useState } from 'react';
import '../Home.css';
import { Box, Flex, Tab, TabList, Tabs, Text } from '@chakra-ui/react';
import { Layout } from '../Layout';
import { useUser } from '../UserContext';
import Elections from './elections/Elections';
import Dossiers from './dossiers/Dossiers';
import Members from './members/Members';
import { faFolderOpen, faNewspaper, faPersonBooth, faScrewdriverWrench, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Blog from './blog/Blog';
import Site from './site/Site';

function Bureau() {
  const { user } = useUser();
  const enum TabsEnum {
    DOSSIERS,
    MEMBERS,
    BLOG,
    ELECTIONS,
    SITE
  };
  const [selectedTab, setSelectedTab] = useState<TabsEnum>(TabsEnum.DOSSIERS);

  useEffect(() => {
    if (!user) return;
    if (user.rank !== "admin") {
      window.location.href = "/";
    }
  }, [user]);

  // Banner size
  const [menuBannerHeight, setMenuBannerHeight] = useState<number>(0);
  useEffect(() => {
    const element = document.getElementById('menu_banner');
    if (element) {
      setMenuBannerHeight(element.clientHeight);
    }
  }, []);

  return (<>
    <Layout>

    <Text fontWeight={'900'} color={"#b44e50"} fontSize={'7xl'} letterSpacing={"-.1rem"} mb={5} mt={5}>Bureau</Text>

    <Box bgColor={"white"} border={"1pt solid #e2e2dd"} width={"100%"} position={"absolute"} left={0} zIndex={-1} height={menuBannerHeight+"px"} />

      <Box p={5} id='menu_banner'>
        <Flex>
          <Tabs isFitted width={"100%"} variant='enclosed' index={selectedTab} onChange={setSelectedTab}>
            <TabList>
              <Tab fontWeight={800} p={5} fontSize={'sm'}><FontAwesomeIcon icon={faFolderOpen} style={{marginRight:"5px"}} />DOSSIERS</Tab>
              <Tab fontWeight={800} p={5} fontSize={'sm'}><FontAwesomeIcon icon={faUsers} style={{marginRight:"5px"}} />MEMBRES</Tab>
              <Tab fontWeight={800} p={5} fontSize={'sm'}><FontAwesomeIcon icon={faNewspaper} style={{marginRight:"5px"}} />BLOG</Tab>
              <Tab fontWeight={800} p={5} fontSize={'sm'}><FontAwesomeIcon icon={faPersonBooth} style={{marginRight:"5px"}} />ÉLECTIONS</Tab>
              <Tab fontWeight={800} p={5} fontSize={'sm'}><FontAwesomeIcon icon={faScrewdriverWrench} style={{marginRight:"5px"}} />SITE</Tab>
            </TabList>
          </Tabs>
        </Flex>
      </Box>

      {/* <Box height={"50px"} /> */}

      {selectedTab === TabsEnum.ELECTIONS && <Elections />}
      {selectedTab === TabsEnum.DOSSIERS && <Dossiers />}
      {selectedTab === TabsEnum.MEMBERS && <Members />}
      {selectedTab === TabsEnum.BLOG && <Blog />}
      {selectedTab === TabsEnum.SITE && <Site />}

      <Box height={"450px"} />

    </Layout>
  </>);
}

export default Bureau;