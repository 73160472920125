import { useEffect, useState } from 'react';
import './Home.css';
import { Box, Button, Center, Collapse, Divider, Flex, Grid, Image, Skeleton, SkeletonText, Spacer, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { Layout } from './Layout';
import { NewDossier } from './Dossiers/NewDossier';
import { IArticle, IDossier } from './types';
import { getArticles } from './API/news';
import { Link as ReactLink} from 'react-router-dom';
import { getDossiers } from './API/dossier';
import BigDossierCard from './Dossiers/BigDossierCard';
import DossierCard from './Dossiers/DossierCard';
import { faBullhorn, faCheckToSlot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCurrentElection, getFinishedElection } from './API/elections';
import { ArticleCardSkeleton } from './News/ArticleCardSkeleton';
import ArticleCard from './News/ArticleCard';
import { Feed } from './Feed';
import { getTutoStatus } from './API/users';

function Home() {
  const new_folder_disclosure = useDisclosure();

  // Articles
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [articlesFetched, setArticlesFetched] = useState<boolean>(false);
  useEffect(() => {
    getArticles(3, 0, "createdAt", true).then((resp) => {
      setArticles(resp.data.articles);
      setArticlesFetched(true);
    });
  }, []);

  // Dossiers
  const [highPriorityDossiers, setHighPriorityDossiers] = useState<IDossier[]>([]);
  const [highPriorityDossiersFetched, setHighPriorityDossiersFetched] = useState<boolean>(false);
  const [openDossiers, setOpenDossiers] = useState<IDossier[]>([]);
  const [openDossiersFetched, setOpenDossiersFetched] = useState<boolean>(false);
  useEffect(() => {
    // High priority dossiers
    getDossiers(1000, 0, "deadline", [], true, true, true).then((resp) => {
      setHighPriorityDossiers(resp.data.dossiers);
      setHighPriorityDossiersFetched(true);
    });
    // Open dossiers, not high priority
    getDossiers(1000, 0, "deadline", [], false, true, true).then((resp) => {
      setOpenDossiers(resp.data.dossiers);
      setOpenDossiersFetched(true);
    });
  }, []);

  // Dossier banner size
  const [dossierBannerHeight, setDossierBannerHeight] = useState<number>(0);
  
  useEffect(() => {
    const element = document.getElementById('dossiers_banner');
    if (element) {
      setDossierBannerHeight(element.clientHeight);
    }
  }, [highPriorityDossiersFetched, openDossiersFetched]);

  // Elections
  const elections_disclosure = useDisclosure();
  const finished_election_disclosure = useDisclosure();
  useEffect(() => {
    getCurrentElection().then((resp) => {
      if (resp.data.election.open)
        elections_disclosure.onOpen();
    }).catch((error) => {});

    getFinishedElection().then((resp) => {
      if (resp.data.election)
        finished_election_disclosure.onOpen();
    }).catch((error) => {});

    getTutoStatus().then((resp) => {
      if (!resp.data.seen) {
        window.location.href = '/welcome';
      }
    }).catch((error) => {});
  }, []);

// Ressources financières
// Enseignement
// Juridique

  return (<>
    <Layout>

    <Collapse in={finished_election_disclosure.isOpen} animateOpacity>
      <Center mt={10} pb={8}>
        <Box boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} border={"1pt solid #e2e2dd"} bgColor={"white"} p={10} w={'80%'}>
          <Text fontWeight={'900'} color={"#4a4a4a"} letterSpacing={'-.05rem'} fontSize={"24px"}><FontAwesomeIcon icon={faBullhorn} style={{marginRight:"5px"}} /> Élections du Bureau ADERE</Text>
          <Text color={"#131313"}  mt={5}>
            Les résultats de l'élection du nouveau Bureau de l'ADERE sont disponibles.
          </Text>

          <Center mt={5}>
            <ReactLink to={'/elections/end'}>
              <Button p={7} colorScheme="purple" onClick={new_folder_disclosure.onOpen} leftIcon={<FontAwesomeIcon icon={faCheckToSlot} style={{marginRight:"5px"}} />}>Consulter les résultats</Button>
            </ReactLink>
          </Center>
        </Box>
      </Center>
    </Collapse>

    <Collapse in={elections_disclosure.isOpen} animateOpacity>
      <Center mt={10} pb={8}>
        <Box boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} border={"1pt solid #e2e2dd"} bgColor={"white"} p={10} w={'80%'}>
          <Text fontWeight={'900'} color={"#4a4a4a"} letterSpacing={'-.05rem'} fontSize={"24px"}><FontAwesomeIcon icon={faBullhorn} style={{marginRight:"5px"}} /> Élections du Bureau ADERE</Text>
          <Text color={"#131313"}  mt={5}>
            Les élections du Bureau de l'ADERE sont ouvertes.
          </Text>

          <Center mt={5}>
            <ReactLink to={'/elections'}>
              <Button p={7} colorScheme="purple" onClick={new_folder_disclosure.onOpen} leftIcon={<FontAwesomeIcon icon={faCheckToSlot} style={{marginRight:"5px"}} />}>Consulter les candidatures</Button>
            </ReactLink>
          </Center>
        </Box>
      </Center>
    </Collapse>
    
    {/* <Box bgColor={"white"} border={"1pt solid #e2e2dd"} width={"100%"} position={"absolute"} left={0} zIndex={-1} height={dossierBannerHeight+"px"} p={2}></Box> */}

    <Box id='dossiers_banner'>
      <Text fontWeight={'900'} color={"#b3b3af"} fontSize={['5xl', '5xl', '7xl']} letterSpacing={"-.1rem"}> Dossiers en cours</Text>

      <Text mb={10} ps={4}>
        Les dossiers présents sur cette page sont actuellement ouverts et nécessitent l'attention des membres de l'ADERE.
      </Text>

      {highPriorityDossiersFetched && highPriorityDossiers.length == 0 && openDossiers.length == 0 && <Center mt={10}>
        <Text color={"gray.500"}>Aucun dossier pour le moment.</Text>
      </Center>
      }

      {!highPriorityDossiersFetched || highPriorityDossiers.length > 0 && (<>
        <Text mb={3} ms={3} color={"#b44e50"} fontWeight={'800'} fontSize={'sm'}>DOSSIERS PRIORITAIRES OUVERTS</Text>
        <Box mb={10} height={'4px'} bgColor={"#940104"} width={'100%'} />
      </>)}
      
      {highPriorityDossiers.map((dossier, index) => {
        return (
          <Box key={index} mb={(index < 2) ? 2 : 0}>
            <BigDossierCard dossier={dossier} />
          </Box>
      )})}

      {!highPriorityDossiersFetched &&
        <Skeleton boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} height={'250px'} width={"100%"} mb={"50px"}  />
      }
      
      {!openDossiersFetched || openDossiers.length > 0 && (
        <Box mb={5} mt={10}>
          <Text mb={3} ms={3} color={"#00707f"} fontWeight={'800'} fontSize={'sm'}>DOSSIERS OUVERTS</Text>
          <Box mb={10} height={'4px'} bgColor={"#00707f"} width={'100%'} />
        </Box>
      )}

      {!openDossiersFetched &&
        <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(3, 1fr)']} gap={6} mt={3} mb={6}>
          {[1, 2, 3, 4, 5].map((_, index) => {
            return (
              <Skeleton key={index} boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} height={'150px'} width={"310px"} />
            )})}
        </Grid>
      }

      {/* <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(3, 1fr)']} gap={6} mt={3} mb={6}>
        {openDossiers.map((dossier, index) => {
          return (
            <BigDossierCard key={index} dossier={dossier} />
          )})}
      </Grid> */}
      
      {openDossiers.map((dossier, index) => {
        return (
          <Box key={index} mb={(index < 2) ? 2 : 0}>
            <BigDossierCard dossier={dossier} />
          </Box>
      )})}

      <Flex pb={"30px"} mt={3} mb={4}>
        <Spacer />
        <ReactLink to={'/dossiers'} onClick={() => {window.scrollTo(0, 0);}}>
          <Text className='hvr-grow' cursor={"pointer"} color={"#00707f"} opacity={0.8} fontSize={"sm"} fontWeight={900}>ACCÉDER AUX FILTRES</Text>
        </ReactLink>
      </Flex>
    </Box>

    {/* <Text fontWeight={'900'} color={"#e6e6e1"} fontSize={['5xl', '5xl', '7xl']} letterSpacing={"-.1rem"} mb={5} mt={5}>News</Text>

    {articlesFetched && articles.length > 0 &&
      <Box height={"100px"} />
    } */}

    {/* ARTICLES */}

    {/* {articlesFetched && articles.length === 0 && <Center>
      <Text mb={5} color={"gray.500"}>Aucun article pour le moment.</Text>
    </Center>} */}

    {/* {articlesFetched && articles.length > 0 && (<Grid position={"relative"} bottom={"100px"} templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}>
      {articles.map((article, index) => {
        return (<Box mt={"120px"} me={index == 2 ? 0 : 10} key={index}>
          <ArticleCard article={article} />
          </Box>
      )
    })}

    </Grid>)} */}

    {/* SKELETONS BOX */}
    
    {/* {!articlesFetched && (<Flex>
      {[1, 2, 3].map((_, i) => <Box key={i}>{ArticleCardSkeleton(i == 3)}</Box>)}
    </Flex>)} */}

    {/* <Flex mt={3} mb={4}>
      <Spacer />
      <ReactLink to={'/news'}>
        <Text className='hvr-grow' cursor={"pointer"} color={"#00707f"} opacity={0.8} fontSize={"sm"} fontWeight={900}>VOIR TOUTES LES NEWS ADERE</Text>
      </ReactLink>
    </Flex> */}

    {/* <Feed /> */}

    <Box height={"400px"} />
      
    </Layout>
  </>);
}

export default Home;