import { AxiosResponse } from "axios";
import API, { API_URL, ApiDataType } from "./api";


export const getNotifications = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/notifications`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const watchNotifications = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/notifications/watch`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}