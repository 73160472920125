import React, { useEffect, useState } from 'react';
import '../Home.css';
import { Box, Button, Center, Circle, Container, Divider, Flex, Grid, HStack, Heading, Image, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Skeleton, SkeletonText, Spacer, Stack, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';
import { Layout } from '../Layout';
import { CheckIcon, PhoneIcon, SearchIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faComment, faEye, faFile, faFolderClosed, faFolderOpen, faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import LoremIpsum from 'react-lorem-ipsum';
import { Link as ReactLink, useLocation, useParams} from 'react-router-dom';
import { faNewspaper } from '@fortawesome/free-regular-svg-icons';
import { IArticle } from '../types';
import { getArticleBySlug } from '../API/news';
import { formatDate } from 'date-fns';
import { fr } from 'date-fns/locale';
import { downloadFile } from '../API/file';

const Article: React.FC = () => {
  const params = useParams();
  const slug = params.slug;
  const [loaded, setLoaded] = useState<boolean>(false);
  const [article, setArticle] = useState<IArticle|null>();
  const [b64Image, setB64Image] = useState<string>("");
  const [loadingPicture, setLoadingPicture] = useState<boolean>(true);

  useEffect(() => {
    if (!slug) return;
    getArticleBySlug(slug, false).then((resp) => {
      setArticle(resp.data.article);
      setLoaded(true);
      
      downloadFile(resp.data.article.picture).then((res) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setB64Image(reader.result as string);
        };
        reader.readAsDataURL(res.data);
        setLoadingPicture(false);
    });
    });
  }, []);

  return (<>
    <Layout>
      <Box height={"25px"} />
      <ReactLink to={"/news"}>
      <Text cursor={"pointer"} fontWeight={'600'} fontSize={'xs'} color={"#4a4a4a"} textTransform={"uppercase"} mb={2}>retour à la liste des articles</Text>
      </ReactLink>
      <Box height={"50px"} />

      {!loaded &&<>
        <Skeleton width={"100px"} height={"10px"} mb={2} mt={0} />
      </>}

      {loaded &&
        <Text fontWeight={'700'} fontSize={'xs'} color={"#71a4d0"} letterSpacing={"0.2em"} textTransform={"uppercase"} mb={2}>{article?.category}</Text>
      }

      {!loaded &&<>
        <Skeleton width={"540px"} height={"30px"} mb={5} mt={0} />
      </>}

      {loaded &&
      <Text fontWeight={'900'} color={"#4a4a4a"} fontSize={'4xl'} letterSpacing={"-.1rem"} mb={5} mt={0} lineHeight={1}>{article?.title}</Text>
      }

      <Box width={"100%"} height={"4px"} bgColor={"#005ca9"} />

      <Center mt={5}>
        <Box border={"1pt solid #e2e2dd"} bgColor={"white"} width={["100%", "100%", "3xl"]} p={["35px", "35px", "70px"]}>

          {!loaded &&<>
            <Skeleton width={"270px"} height={"20px"} />
          </>}
          {loaded &&
            <Text fontSize={'md'} fontWeight={700} color={"#005ca9"}><FontAwesomeIcon icon={faNewspaper} style={{marginRight:"5px"}} size='xl' />
            {formatDate(article?.createdAt || new Date(), "dd MMMM yyyy", { locale: fr })}
              , rédigé par {article?.author}
            </Text>
          }

          <Box width={"100%"} height={"3px"} bgColor={"#005ca9"} mt={"25px"} mb={"60px"} />

          {!loadingPicture &&
            <Image boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} src={b64Image} minWidth={["100%", "100%", "950px"]} position={["inherit", "inherit", "relative"]} right={"160px"} mb={"60px"} />
          }
          {loadingPicture && 
            <Skeleton width={"950px"} height={"500px"} position={"relative"} right={"160px"} mb={"60px"} />
          }

          <Box color={"#4a4a4a"} fontSize={'md'}>
            {!loaded &&
              <SkeletonText mt={4} noOfLines={10} spacing="4" />
            }
            {loaded &&
              <div dangerouslySetInnerHTML={{__html: article?.content || ""}} />
            }
          </Box>
        </Box>
      </Center>

      <Box height={"500px"} />
    </Layout>
    </>);
}

export default Article;