import { useEffect, useState } from 'react';
import '../../Home.css';
import { Box, Button, Center, Flex, Spacer, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonBooth } from '@fortawesome/free-solid-svg-icons';
import NewElection from './NewElection';
import { IElection } from '../../types';
import { getCurrentElection, getElections, getFinishedElection, newElection } from '../../API/elections';
import OngoingElection from './OngoingElection';
import FinishedElection from './FinishedElection';

export interface ElectionsProps {}

const Elections: React.FC<ElectionsProps> = ({}) => {
    // Current election
    const [election, setElection] = useState<IElection | null>(null);
    const [finishedElections, setFinishedElections] = useState<IElection[]>([])
    const [ongoingElection, setOngoingElection] = useState<boolean>(true);
    useEffect(() => {
        getCurrentElection().then((resp) => {
        const election: IElection = resp.data.election;
        setElection(election);
        if (!election.open)
            setOngoingElection(false);
        }).catch((err) => {
        setOngoingElection(false);
        });

        getElections().then((resp) => {
          const elections: IElection[] = resp.data.elections;
          // For all the finished elections, add them to the finishedElections array
          // (i.e., if their deadline has passed)
          const finished_elections = elections.filter((election) => new Date(election.deadline) < new Date());

          // Sort them by deadline (most recent first)
          finished_elections.sort((a, b) => new Date(b.deadline).getTime() - new Date(a.deadline).getTime());
          setFinishedElections(finished_elections);
        });
    }, []);

    // New election
    const new_election = () => {
        newElection().then((resp) => {
        const election: IElection = resp.data.election;
        setOngoingElection(false);
        setElection(election);
        });
    }

    const reload_election = () => {
      setOngoingElection(true);
      getCurrentElection().then((resp) => {
        const election: IElection = resp.data.election;
        setElection(election);
        if (!election.open)
            setOngoingElection(false);
        }).catch((err) => {
          setOngoingElection(false);
      });
    }

    return <Tabs position="relative" variant="unstyled" mt={5} isLazy={true}>
    <TabList>
      <Tab fontWeight={800} p={3} fontSize={'sm'}>ÉLECTION EN COURS</Tab>
      <Tab fontWeight={800} p={3} fontSize={'sm'}>ÉLECTIONS TERMINÉES</Tab>
    </TabList>
    <TabIndicator
      mt="-1.5px"
      height="3px"
      bg="#004851"
      borderRadius="1px"
    />
    <TabPanels>
      <TabPanel p={0} pt={8}>
        {election && ongoingElection && <OngoingElection election={election} />}
        {!ongoingElection && election && <NewElection reload_election={reload_election} />}
        {!ongoingElection && !election && 
          <Box>
            <Center>
              <Text>Il n'y a aucune élection en cours.</Text>
            </Center>
    
            <Flex>
              <Spacer />
              <Button 
                p={6} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                leftIcon={<FontAwesomeIcon icon={faPersonBooth} />}
                onClick={new_election}
                >
                  LANCER UNE ÉLECTION
                </Button>
            </Flex>
          </Box>
        }
      </TabPanel>
      <TabPanel>
        {finishedElections.map((election, index) => {
          return (
          <Box key={index} mb={3}>
            <FinishedElection election_data={election} />
          </Box>)
        })}
        {finishedElections.length === 0 && <Center>
          <Text>Aucune élection terminée pour le moment.</Text>
        </Center>
        }
      </TabPanel>
    </TabPanels>
  </Tabs>
}

export default Elections;