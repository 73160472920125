import React, { useEffect, useState } from 'react';
import '../Home.css';
import { Avatar, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Collapse, Divider, Flex, FormControl, FormHelperText, FormLabel, Grid, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spacer, Square, Table, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tooltip, Tr, VStack, useDisclosure, useToast } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faComment, faComments, faEye, faHeart, faPaperPlane, faTag, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import LoremIpsum from 'react-lorem-ipsum';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { IDossier, ICategory } from '../types';
import { newThread } from '../API/thread';
import TextEditor from '../components/TextEditor';

export function NewThread(isOpen:boolean, onOpen:any, onClose:any, create_thread_callback:Function, dossier:IDossier|null=null, category:ICategory|null=null, categories:ICategory[]|null=null) {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const toast = useToast();
  const MAX_POST_LENGTH = 2000;
  const MAX_TITLE_LENGTH = 100;
  const [creatingThread, setCreatingThread] = useState(false);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleContentChange = (content: string) => {
    setContent(content);
  };

  const handleCreateThread = () => {
    // Check if title and content are not empty
    if (title === '' || content === '') {
      toast({
        title: "Erreur",
        description: "Le titre et le contenu du sujet ne peuvent pas être vides",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      });
      return;
    }

    setCreatingThread(true);

    newThread(selectedCategory?._id || '', dossier?._id || '', title, content).then((resp) => {
      create_thread_callback(resp.data.thread);
      setTitle('');
      setContent('');
      onClose();
      toast({
        title: "Sujet créé",
        description: "Votre sujet a été créé avec succès",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      });
    }).catch((err) => {
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la création du sujet, veuillez réessayer plus tard...",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      });
    }).finally(() => {
      setCreatingThread(false);
    });
  };

  // Categories
  const [selectedCategory, setSelectedCategory] = useState<ICategory|null>(null);
  const handle_category_change = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const category_id = e.target.value;
    const category = categories?.find(cat => cat._id === category_id);
    setSelectedCategory(category || null);
  }
  useEffect(() => {
    if (categories && categories.length > 0) {
      setSelectedCategory(categories[0]);
    }
  }, [categories]);

  return (
    <Modal 
      onClose={onClose} 
      size={"6xl"} 
      isOpen={isOpen}
      motionPreset='scale'
      closeOnOverlayClick={false}
    >
      <ModalOverlay backdropFilter='blur(2px)' />
      <ModalContent>
        <ModalHeader>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody pt={0}>

          <Breadcrumb fontWeight='medium' fontSize='sm'>

            {dossier && 
              <BreadcrumbItem>
                <BreadcrumbLink href='#'>"{dossier?.title}"</BreadcrumbLink>
              </BreadcrumbItem>
            }
            
            {categories &&
              <BreadcrumbItem>
                <BreadcrumbLink href='#'>{selectedCategory?.name || 'Autre'}</BreadcrumbLink>
              </BreadcrumbItem>
            }

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href='#'>Nouveau sujet</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <Text mb={3} mt={3} fontWeight={'900'} letterSpacing={"-.05rem"} color={"#314e89"} fontSize={'x-large'}>
            <FontAwesomeIcon icon={faComments} style={{marginRight:"5px"}} />Nouveau sujet
          </Text>

          <Divider mb={5} />

          <Box mt={4}>

            {categories && categories.length > 0 &&
              <FormControl mt={3}>
                <FormLabel fontWeight={'700'} >Catégorie</FormLabel>
                <Select isDisabled={creatingThread} fontSize={"sm"} className='prettyOption' bgColor={"white"} width={"300px"} variant='outline' onChange={handle_category_change} value={selectedCategory?._id || ""}>
                  {categories.map(category => (
                    <option className='prettyOption' key={category._id} value={category._id}>{category.name}</option>
                  ))}
                </Select>
              </FormControl>
            }
            
            <FormControl mt={3}>
              <FormLabel fontWeight={'700'} >Titre du sujet</FormLabel>
              <Input 
                isDisabled={creatingThread}
                placeholder='Titre du sujet'
                value={title}
                onChange={handleTitleChange}
                maxLength={MAX_TITLE_LENGTH}
              />
              <FormHelperText>Max. {MAX_TITLE_LENGTH} caractères</FormHelperText>
            </FormControl>

            <FormControl mt={3}>
              <FormLabel fontWeight={'700'} >Contenu</FormLabel>
              <TextEditor withImage={false} content={content} update_callback={handleContentChange} disabled={creatingThread} maxLength={MAX_POST_LENGTH} />
            </FormControl>
            <Flex>
              <Spacer />
              <Button isDisabled={creatingThread} mt={2} size={"sm"} colorScheme="blue" variant="outline" rounded={0} rightIcon={<FontAwesomeIcon icon={faPaperPlane} />} onClick={handleCreateThread}>Créer le sujet</Button>
            </Flex>
          </Box>

          <Divider mt={5} mb={5} />

        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>
    )
  }