import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { IElection, IElectionCandidate } from "../../types";
import { Box, Button, Center, Divider, Flex, FormControl, FormLabel, Skeleton, Spacer, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getCandidates, getVotesPerCandidate, postElection } from "../../API/elections";
import { differenceInDays, formatDate } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";

export interface FinishedElectionProps {
    election_data:IElection;
}

const FinishedElection: React.FC<FinishedElectionProps> = ({election_data}) => {
    const toast = useToast();
    const [votesPerCandidate, setVotesPerCandidate] = useState<{name:string, votes:number}[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [chartRendered, setChartRendered] = useState<boolean>(false);
    const [election, setElection] = useState<IElection>(election_data);

    useEffect(() => {
        getVotesPerCandidate(election._id).then((resp) => {
            const candidates:IElectionCandidate[] = resp.data.electionCandidates;
            let votes:{name:string, votes:number}[] = [];
            candidates.forEach((candidate) => {
                votes.push({name:candidate.user.firstname + " " + candidate.user.lastname, votes:candidate.votes_number || 0});
            });
            setVotesPerCandidate(votes);
            setLoading(false);
        }).catch((err) => {
            toast({
                title: "Erreur",
                description: "Une erreur est survenue lors de la récupération des votes",
                status: "error",
                duration: 6000,
                isClosable: true,
                position: "top-right"
            });
        });
    }, [election]);

    const [postDeadline, setPostDeadline] = useState<Date>(new Date());
    const [erroneousDate, setErroneousDate] = useState<boolean>(false);

    const publish_results = () => {
        // Check if the date is in the future
        if (postDeadline <= new Date()) {
            toast({
                title: "Erreur",
                description: "La date de fin de publication des résultats doit être dans le futur",
                status: "error",
                duration: 6000,
                isClosable: true,
                position: "top-right"
            });
            document.getElementById("datetimepicker")?.blur();
            return;
        }

        if (differenceInDays(postDeadline, new Date()) < 1) {
            toast({
                title: "Erreur",
                description: "La date de fin de publication des résultats doit être au moins 24 heures dans le futur",
                status: "error",
                duration: 6000,
                isClosable: true,
                position: "top-right"
            });
            document.getElementById("datetimepicker")?.blur();
            return;
        }

        postElection(election._id, postDeadline).then((resp) => {
            setElection(resp.data.election);
            toast({
                title: "Résultats publiés",
                description: "Les résultats ont été publiés",
                status: "success",
                duration: 6000,
                isClosable: true,
                position: "top-right"
            });
        }).catch((err) => {
            toast({
                title: "Erreur",
                description: "Une erreur est survenue lors de la publication des résultats",
                status: "error",
                duration: 6000,
                isClosable: true,
                position: "top-right"
            });
        });
    }


    return <>
    
        <Box mt={8}>
            <Text mb={3} ms={3} color={"#004851"} fontWeight={'800'} fontSize={'sm'}>ÉLECTION TERMINÉE LE {formatDate(election.deadline, "dd/MM/yyyy 'à' HH:mm")} </Text>
            <Box mb={5} height={'4px'} bgColor={"#004851"} width={'100%'} />
        </Box>

        {!election.posted && 
        <Flex mb={5}>
            <FormControl mt={5} ms={3}>
                <FormLabel>Rendre les résultats visibles jusqu'au :</FormLabel>
                <DateTimePickerComponent 
                    readonly={loading}
                    locale='fr' 
                    width={'250px'} 
                    id="datetimepicker"
                    min={new Date()}
                    allowEdit={false}
                    value={postDeadline}
                    onChange={(e:any) => {setPostDeadline(e.target.value)}}
                />
            </FormControl>
            <Spacer />
            <Button 
                width={"250px"}
                onClick={publish_results}
                p={6} ps={8} pe={8} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={8} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                leftIcon={<FontAwesomeIcon icon={faBullhorn} />} >
                PUBLIER LES RÉSULTATS
            </Button>
        </Flex>
        }

        {election.posted &&
            <Text ms={2} mt={2} mb={5} fontWeight={700} color={"green.500"}>Les résultats {
                new Date(election.posted_end) > new Date() ? "sont" : "étaient"
            } visibles publiquement jusqu'au {formatDate(election.posted_end, "dd/MM/yyyy 'à' HH:mm")}</Text>
        }

        <Box width={"100%"} height={"400px"} bgColor={"white"} p={4} pb={5} border={"1pt solid #e2e2dd"}>
            <Text pb={2} fontWeight={700}>Nombres final de votes</Text>
            {!loading && <>
                {!chartRendered && <Skeleton height={"100%"} /> }
                <ResponsiveContainer width="100%" height="95%" onResize={() => {setChartRendered(true)}}>
                    <BarChart width={150} height={40} data={votesPerCandidate}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis dataKey="votes"/>
                        <Bar dataKey="votes" fill="#004851" />
                    </BarChart>
                </ResponsiveContainer>
            </>}
            {loading &&
                <Skeleton height={"80%"} />
            }
        </Box>

        <Center mb={10}>
            <Box bgColor={"white"} border={"1pt solid #e2e2dd"} p={5} width={"90%"}>
                {votesPerCandidate
                    .sort((a, b) => b.votes - a.votes)
                    .map((candidate, index) => {
                        return (
                            <Box key={index} mt={3} mb={3}>
                                <Flex>
                                    <Text fontWeight={600}>{candidate.name}</Text>
                                    <Spacer />
                                    <Text fontWeight={600} mb={3}>
                                        {candidate.votes} votes
                                    </Text>
                                </Flex>
                                <Divider />
                            </Box>
                        );
                    })}
            </Box>
        </Center>
    </>
}

export default FinishedElection;