import '../Home.css';
import { Box, Button, Center, Circle, Divider, Flex, FormControl, FormLabel, Grid, Radio, RadioGroup, Select, Skeleton, Spacer, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { Layout } from '../Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faEye, faFolderClosed, faFolderOpen, faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { getCategories, getDossierBySlug, getDossiers } from '../API/dossier';
import { ICategory, IDossier } from '../types';
import BigDossierCard from './BigDossierCard';
import DossierCard from './DossierCard';
import { NewDossier } from './NewDossier';
import { Link as ReactLink, useParams} from 'react-router-dom';

function Archives() {
  const [openDossiers, setOpenDossiers] = useState<IDossier[]>([]);
  const [dossiersFetched, setDossiersFetched] = useState<boolean>(false);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ICategory|null>(null);
  const priorities = ["Deadline urgente d'abord", "Ordre d'ouverture"];

  const params = useParams();
  const slug = params.slug;
  useEffect(() => {
    if (!slug) return;

    getDossierBySlug(slug).then((resp) => {
      const dossier = resp.data.dossier;
      if (dossier) {
        dossier.defaultOpen = true;
        dossier.user_seen = true;

        // Push it into the openDossiers if it's not already there, otherwise update it
        const index = openDossiers.findIndex((d) => d._id === dossier._id);
        if (index !== -1) {
          openDossiers[index] = dossier;
          setOpenDossiers([...openDossiers]);
        } else {
          // update the state
          setOpenDossiers([...openDossiers, dossier]);
        }
      }
    });
  }, [slug, dossiersFetched]);

  useEffect(() => {
    // Open dossiers, not high priority
    getDossiers(0, 0, "deadline", [], false, false, true).then((resp) => {
      setOpenDossiers(resp.data.dossiers);

      // Get high priority dossiers
      getDossiers(0, 0, "deadline", [], true, false, true).then((resp) => {
        // Concat resp.data.dossiers to openDossiers
        setOpenDossiers(prev => prev.concat(resp.data.dossiers));
        setDossiersFetched(true);
      });
    });
  }, []);

  
  // Dossier banner size
  const [filterBannerHeight, setFilterBannerHeight] = useState<number>(0);
  useEffect(() => {
    const element = document.getElementById('filter_banner');
    if (element) {
      setFilterBannerHeight(element.clientHeight);
    }
  }, []);

  // Get categories
  useEffect(() => {
    getCategories().then((resp) => {
      setCategories(resp.data.categories);
    });
  }, []);

  const handleCategoryChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
    let value = e.target.value;
    if (value == "all") {
      fetchFilteredDossiers([]);
      setSelectedCategory(null);
      return;
    }
    
    const category = categories.find((cat) => cat.name === e.target.value);
    if (!category) return;
    setSelectedCategory(category);

    fetchFilteredDossiers([category._id]);
  }

  const fetchFilteredDossiers = (categories:string[]=[]) => {
    if (categories === null) categories = [];

    // Reset dossiers
    setDossiersFetched(false);
    setOpenDossiers([]);

    // Normal dossiers
    getDossiers(0, 0, "deadline", categories, false, false, true).then((resp) => {
      setOpenDossiers(resp.data.dossiers);
      
      // Get high priority dossiers
      getDossiers(0, 0, "deadline", categories, true, false, true).then((resp) => {
        // Concat resp.data.dossiers to openDossiers
        setOpenDossiers(prev => prev.concat(resp.data.dossiers));
        setDossiersFetched(true);
      });
    });
  }

  return (<>
    <Layout>
      {/* <Text fontWeight={'900'} color={"#e6e6e1"} fontSize={'7xl'} letterSpacing={"-.1rem"} mb={3}>Dossiers en cours</Text> */}
      
      <Text mb={5} fontWeight={'900'} color={"#e6e6e1"} fontSize={'7xl'} letterSpacing={"-.1rem"}> Dossiers archivés</Text>

      <Box bgColor={"white"} border={"1pt solid #e2e2dd"} width={"100%"} position={"absolute"} left={0} zIndex={-1} height={filterBannerHeight+"px"} />

      {/* FILTERS */}
      <Box p={5} id='filter_banner'>
          <FormControl mb={4} p={4} pe={0}>
            <FormLabel fontSize={"sm"}>
              TRIER PAR CATÉGORIE
            </FormLabel>
            <Select className='prettyOption' rounded={0} me={2} bgColor={"white"} size='lg' value={selectedCategory?.name || ''} onChange={handleCategoryChange}>
              <option value={'all'}>Toutes les catégories</option>
              {categories.map((category) => (
                <option key={category._id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </Select>
          </FormControl>
      </Box>
      
      <Box height={"20px"}></Box>

      <Box mb={5}>
        <Text mb={3} ms={3} color={"#00707f"} fontWeight={'800'} fontSize={'sm'}>ARCHIVES</Text>
        <Box mb={10} height={'4px'} bgColor={"#00707f"} width={'100%'} />
      </Box>

      {!dossiersFetched &&
        <Grid templateColumns='repeat(3, 2fr)' gap={6} mt={3} mb={6}>
          {[1, 2, 3, 4, 5].map((_, index) => {
            return (
              <Skeleton key={index} boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} height={'150px'} width={"310px"} />
            )})}
        </Grid>
      }

      {dossiersFetched && openDossiers.length === 0 &&
        <Center><Text textAlign={"center"} fontSize={"lg"} color={"gray.500"}>Aucun dossier archivé</Text></Center>
      }
      
      {dossiersFetched && openDossiers.length > 0 &&
        <Grid templateColumns='repeat(3, 2fr)' gap={6} mt={3} mb={6}>
          {openDossiers.map((dossier, index) => {
            return (
              <DossierCard key={index} dossier={dossier} defaultOpen={dossier.defaultOpen} />
            )})}
        </Grid>
      }

      <Flex>
        <Spacer />
        <Button onClick={() => {
          window.location.href = '/dossiers';
        }} bgColor={"#004851"} color={"white"} _hover={{backgroundColor:"#006977"}} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} leftIcon={<FontAwesomeIcon icon={faArrowCircleLeft} />} >
          RETOUR AUX DOSSIERS EN COURS
        </Button>
      </Flex>

      <Box height={'800px'} />
      
    </Layout>
    </>);
}

export default Archives;