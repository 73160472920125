import axios from "axios";
import Cookies from "js-cookie";
import { IArticle, ICategory, IContactForm, IDossier, IDossierRequest, IElection, IElectionCandidate, IElectionVote, INewsFeed, INotification, IPoll, IPollVote, IThread, IThreadPost, IUser } from "../types";

export const API_URL = process.env.REACT_APP_API_URL;
export const MAILER_URL = process.env.REACT_APP_MAILER_URL;

const API = axios.create({ baseURL: API_URL });

API.interceptors.request.use(
    (config) => {
        // If a JWT token exists, add it to the Authorization header
        const token = Cookies.get('jwt_adere');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        }
        else {
            // Trigger token event on document
            const event = new Event('TOKEN_ERROR');
            document.dispatchEvent(event);
            throw new Error("No token found");
        }
        
        return config;
    },
    // If no JWT token exists, redirect to login
    (error) => {
        console.log("[REQUEST] Error in request interceptor")
        console.log(error);
        return Promise.reject(error);
    }
);

API.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response != undefined && error.response.status === 401) {
            console.log("[RESPONSE] TOKEN ERROR");
            Cookies.remove('jwt_cafe');
        }

        return Promise.reject(error);
    }
);

export default API;


export type ApiDataType = {
    user:IUser;
    users:IUser[];
    articles:IArticle[];
    article:IArticle;
    dossiers:IDossier[];
    dossier:IDossier;
    polls:IPoll[];
    poll:IPoll;
    pollVote:IPollVote;
    threads:IThread[];
    thread:IThread;
    threadPosts:IThreadPost[];
    threadPost:IThreadPost;
    category:ICategory;
    categories:ICategory[];
    dossierRequest:IDossierRequest;
    dossierRequests:IDossierRequest[];
    election:IElection;
    elections:IElection[];
    electionCandidates:IElectionCandidate[];
    electionCandidate:IElectionCandidate;
    electionVote:IElectionVote;
    electionVotes:IElectionVote[];
    newsfeed:INewsFeed[];
    newsEvent:INewsFeed;
    notification:INotification;
    notifications:INotification[];
    contactForm:IContactForm;
    contactForms:IContactForm[];
    filenames:string[];
    token:string;
    ids:string[];
    mails:string[];
    id:string;
    error:string;
    results:any;
    hasMore:boolean;
    allowed:boolean;
    remainingTime:number;
    seen:boolean;
    count:number;
}