import { Box, Flex, Spacer, Text, useDisclosure } from "@chakra-ui/react";
import { faBriefcase, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IDossier } from "../types";
import { differenceInDays, differenceInHours, differenceInMinutes, formatDate } from 'date-fns';
import { fr } from 'date-fns/locale';
import { format } from "path";
import { Dossier } from "./Dossier";
import { DOSSIER_COLORS } from "../colors";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { useEffect, useState } from "react";

export interface DossierCardProps {
    dossier:IDossier;
    defaultOpen?:boolean;
    fake?:boolean;
}

const BigDossierCard: React.FC<DossierCardProps> = ({dossier, defaultOpen, fake}) => {
    const folder_disclosure = useDisclosure();
    const [htmlDescription, setHtmlDescription] = useState<string>("");
    const color = () => {
        if (!dossier.deadline) return DOSSIER_COLORS.green;
        
        if (differenceInDays(dossier.deadline, new Date()) < 3) {
            return DOSSIER_COLORS.red;
        } else if (differenceInDays(dossier.deadline, new Date()) < 7) {
            return DOSSIER_COLORS.orange;
        } else {
            return DOSSIER_COLORS.green;
        }
    }

    const textColor = () => {
      if (!dossier.deadline) return DOSSIER_COLORS.green;
      
      if (differenceInDays(dossier.deadline, new Date()) < 3) {
          return DOSSIER_COLORS.red;
      } else if (differenceInDays(dossier.deadline, new Date()) < 7) {
          return DOSSIER_COLORS.orange;
      } else {
          return DOSSIER_COLORS.green;
      }
  }

    useEffect(() => {
      if (defaultOpen) {
        folder_disclosure.onOpen();
      }
    }, [defaultOpen]);

    useEffect(() => {
      // Take the raw HTML and convert it to a string without HTML tags.
      // Also remove B64 images and replace them with "".
      // Then, put all of that in the htmlDescription state.
      const html = dossier.content;
      const div = document.createElement("div");
      div.innerHTML = html;
      const text = div.textContent || div.innerText || "";
      setHtmlDescription(text);
    }, []);

    useEffect(() => {
      // Listen to updateDossier event
      const updateDossierListener = (e: any) => {
        if (e.detail.id === dossier._id) {
          // New title
          if (e.detail.title) {
            dossier.title = e.detail.title;
          }
        }
      }
      document.addEventListener("updateDossier", updateDossierListener);
      return () => {
        document.removeEventListener("updateDossier", updateDossierListener);
      }
    }, []);

    const handleOpenDossier = () => {
      if (fake) return;

      folder_disclosure.onOpen();
      
      if (window.location.pathname.includes('/dossiers')) {
        window.history.pushState({}, '', `/dossiers/${dossier.slug}`);
      } else if (window.location.pathname.includes('/archives')) {
        window.history.pushState({}, '', `/archives/${dossier.slug}`);
      }
    }

    const close_dossier_wrapper = () => {
      folder_disclosure.onClose();
      if (window.location.pathname.includes("/dossiers")) {
        window.history.pushState({}, '', `/dossiers`);
      } else if (window.location.pathname.includes("/archives")) {
        window.history.pushState({}, '', `/archives`);
      }
    }

    const growSvg = () => {
      // Get the SVG element
      const svg = document.getElementById(dossier.slug+"-svg");
      if (!svg) return;

      // Slowly grow the SVG element to a minWidth of 100px in 0.5s
      svg.style.transition = "min-width 0.5s";
      svg.style.minWidth = "100px";
    }

    const shrinkSvg = () => {
      // Get the SVG element
      const svg = document.getElementById(dossier.slug+"-svg");
      if (!svg) return;

      // Slowly shrink the SVG element to a minWidth of 40px in 0.5s
      svg.style.transition = "min-width 0.5s";
      svg.style.minWidth = "40px";
    }

    return (<>
        <Box mb={5} boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} className='hvr-float' border={"1pt solid #e2e2dd"} bgColor={"white"} cursor={'pointer'} width={"100%"} onClick={handleOpenDossier} minHeight={"150px"} position={'relative'} onMouseOver={growSvg} onMouseOut={shrinkSvg}>

          {/* {!dossier.user_seen && (
            <Box bgColor={"#ba0105"} color={"white"} width={'fit-content'} pe={2} ps={2} pt={1} pb={1} fontSize={'xs'} fontWeight={'800'} border={"1pt solid #610002"} position={'absolute'} right={"-10px"} top={"-10px"}>
              NOUVEAU
            </Box>
          )} */}

          <Flex minHeight={"150px"}>
            {/* Color box */}
            <Box minWidth={"40px"} maxWidth={"40px"} bgColor={color()[1]} minHeight={'100%'} maxHeight={'100%'} id={dossier.slug+"-svg"}>
              <svg height="100%" width="100%">
                <polygon points="-10,0 150, 200 100,0" fill={color()[0]} />
              </svg>
            </Box>

            <Box p={5} width={"100%"} height={"100%"}>
              <Text letterSpacing={"-.05rem"} fontWeight={'800'} fontSize={'2xl'} color={"#00707f"}>{dossier.title}</Text>

              {dossier.bureau && (
              <Text fontWeight={'400'} fontSize={'sm'} color={"#adb5bd"}><FontAwesomeIcon icon={faBriefcase} style={{marginRight:"5px"}} />Publié par le Bureau ADERE</Text>
              )}
              {!dossier.bureau && (
              <Text fontWeight={'400'} fontSize={'sm'} color={"#adb5bd"}>Publié par {dossier.author_name}</Text>
              )}


              <Box fontWeight={400} fontSize={'md'} color={"#4a4a4a"} textAlign={"justify"} mt={3} mb={3}>
              {/* Only display the first */}
              {htmlDescription}
              </Box>

              <Flex mt={3}>
                {dossier.deadline && differenceInDays(dossier.deadline, new Date()) > 0 && (
                    <Text fontSize={'sm'} fontWeight={"500"} color={textColor()}><FontAwesomeIcon icon={faCalendar} style={{marginRight:"5px"}} />
                        Deadline: {formatDate(dossier.deadline, "dd MMMM yyyy", {locale:fr})} - Dans {differenceInDays(dossier.deadline, new Date())} jour{differenceInDays(dossier.deadline, new Date()) > 1 ? "s" : ""}
                    </Text>
                )}
                {dossier.deadline && differenceInHours(dossier.deadline, new Date()) > 0 && differenceInDays(dossier.deadline, new Date()) == 0 && (
                    <Text fontSize={'sm'} color={textColor()}><FontAwesomeIcon icon={faCalendar} style={{marginRight:"5px"}} />
                    Deadline: {formatDate(dossier.deadline, "dd MMMM yyyy", {locale:fr})} - Dans {differenceInHours(dossier.deadline, new Date())} heure{differenceInHours(dossier.deadline, new Date()) > 1 ? "s" : ""}
                    </Text>
                )}
                {dossier.deadline && differenceInHours(dossier.deadline, new Date()) == 0 && (
                    <Text fontSize={'sm'} color={textColor()}><FontAwesomeIcon icon={faCalendar} style={{marginRight:"5px"}} />
                    Deadline: {formatDate(dossier.deadline, "dd MMMM yyyy", {locale:fr})} - Dans {differenceInMinutes(dossier.deadline, new Date())} minute{differenceInMinutes(dossier.deadline, new Date()) > 1 ? "s" : ""}
                    </Text>
                )}
                <Spacer />
                <Text fontSize={'sm'} color={"#adb5bd"}>{fake ? Math.floor(Math.random() * 400) + 100 : dossier.views_count} <FontAwesomeIcon icon={faEye} style={{marginLeft:"5px"}} /></Text>
              </Flex>
              <Flex mt={3}>
                <Spacer />
                <Text color={"#00707f"} fontSize={"xs"} fontWeight={900} position={"absolute"} bottom={"20px"} right={"20px"}>
                EN SAVOIR +
                </Text>
              </Flex>
            </Box>
          </Flex>
      </Box>

    {Dossier(folder_disclosure.isOpen, folder_disclosure.onOpen, close_dossier_wrapper, dossier._id)}
    </>)
}

export default BigDossierCard;