import { Box, Button, Checkbox, Collapse, Divider, Flex, FormControl, FormHelperText, Grid, Input, Spacer, Text, Textarea, useDisclosure, useToast } from "@chakra-ui/react";
import { faFile, faPaperclip, faTags } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "date-fns";
import LoremIpsum from "react-lorem-ipsum";
import { ICategory, IDossierRequest, pollTypeToString } from "../../types";
import { useRef, useState } from "react";
import { manageDossierRequest } from "../../API/dossier";
import { CheckBox } from "@syncfusion/ej2/buttons";
import { downloadFile } from "../../API/file";

export interface RefusedDossierCardProps {
    dossier:IDossierRequest;
    categories:ICategory[];
}

const RefusedDossierCard: React.FC<RefusedDossierCardProps> = ({dossier, categories}) => {
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
    const categoriesString = categories.map((category:ICategory) => category.name).join(", ");
    const toast = useToast();

    const download_file = (file_id:string) => {
        downloadFile(file_id).then((resp) => {
          const contentDisposition = resp.headers['content-disposition'];
          const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        });
      }

    return <Box mb={5} onClick={isOpen ? () => {}: onToggle} cursor={isOpen ? "default" : "pointer"} width={"100%"} bgColor={"white"} p={6} pb={5} border={"1pt solid #e2e2dd"} position={'relative'}>
    <Flex>
        <Text letterSpacing={"-.01rem"} lineHeight={"1.2"} fontWeight={'700'} fontSize={'lg'} color={"#00707f"}>{dossier.title}</Text>
        <Spacer />
        <Text cursor={'pointer'} onClick={() => { if (isOpen) onClose();}} color={"#00707f"} fontSize={"xs"} fontWeight={900} pt={2} pe={2}>
            {isOpen ? "FERMER" : "CONSULTER"}
        </Text>
    </Flex>
    <Text fontWeight={'400'} fontSize={'sm'} color={"#7f8591"}>Demandé par {dossier.author} le {formatDate(dossier.createdAt ?? new Date(), "dd/MM/yyyy à HH:mm")}</Text>

    {dossier.high_priority &&
        <Text pt={2} fontWeight={'700'} fontSize={'sm'} color={"#ba0105"}>HAUTE PRIORITÉ DEMANDÉE</Text>
    }

    <Collapse in={isOpen} animateOpacity>
        <Divider mt={5} mb={5} />

        {dossier.deadline &&
        <Text>
            Clôture du dossier demandée pour le <Text as={"span"} fontWeight={'700'} color={"#ba0105"}>{formatDate(dossier.deadline ?? new Date(), "dd/MM/yyyy à HH:mm")}</Text>
        </Text>
        }
            
        {!dossier.deadline &&
        <Text>
            Clôture manuelle demandée (pas de deadline indiquée)
        </Text>
        }

        <Box mt={8}>
            <Text mb={3} ms={3} color={"#004851"} fontWeight={'800'} fontSize={'sm'}>DESCRIPTION</Text>
            <Box mb={5} height={'4px'} bgColor={"#004851"} width={'100%'} />
        </Box>

        <Box ms={2} mb={2}>
            Catégories: <Text as={"span"} fontWeight={'700'} color={"#004851"}>{categoriesString}</Text>
        </Box>

        <Box border={"1pt solid #e2e2dd"} bgColor={"white"} p={5}>
            <Box dangerouslySetInnerHTML={{__html: dossier.description}} />
        </Box>

        <Box ps={2} pt={2}>
            <Text fontSize={'sm'}><FontAwesomeIcon style={{marginRight:"3px", position:"relative", top:"2px"}} icon={faTags} /> <Text as={"span"} fontWeight={'700'}>Mots-clés: </Text> {dossier.tags.join(", ")}</Text>
        </Box>

        {dossier.attached_files.length > 0 &&
        <Box p={3} mt={2}>
            <Text fontSize={'lg'} fontWeight={'700'} color={"#314e89"} mb={2}>
                <FontAwesomeIcon icon={faPaperclip} style={{marginRight:"5px"}} />Fichiers joints
            </Text>
            <Grid templateColumns='repeat(4, 2fr)' gap={6}>
                {dossier.attached_files.map((attachment, index) => {
                    return <Box key={index} cursor={'pointer'} bgColor={'white'} p={3} textAlign={'center'} border={"1pt solid #e2e2dd"} onClick={() => {download_file(attachment.id)}}>
                        <Text fontWeight={'400'} ><FontAwesomeIcon icon={faFile} style={{marginRight:"5px"}} />{attachment.name}</Text>
                    </Box>
                })}
            </Grid>
        </Box>
        }

        
        <Box mt={8}>
            <Text mb={3} ms={3} color={"#004851"} fontWeight={'800'} fontSize={'sm'}>SONDAGES</Text>
            <Box mb={5} height={'4px'} bgColor={"#004851"} width={'100%'} />
        </Box>

        {dossier.polls.length > 0 && dossier.polls.map((poll, index) => {
            return <Box key={index} border={"1pt solid #e2e2dd"} p={3} mt={3} rounded={"md"}>
                <Flex>
                    <Text fontWeight={'800'} fontSize={'md'}>{poll.title}</Text>
                    <Spacer />
                </Flex>
                <Text>{poll.description}</Text>
                <Text>{pollTypeToString(poll.type)}</Text>
                <Text>{poll.options.join(", ")}</Text>
                <Text>{formatDate(poll.deadline, "dd/MM/yyyy à HH:mm")}</Text>
            </Box>
        })}
        {dossier.polls.length == 0 && <Text>Aucun sondage n'a été ajouté à cette demande</Text>}

        {dossier.high_priority && <>
            <Box mt={8}>
                <Text mb={3} ms={3} color={"#004851"} fontWeight={'800'} fontSize={'sm'}>HAUTE PRIORITÉ</Text>
                <Box mb={5} height={'4px'} bgColor={"#004851"} width={'100%'} />
            </Box>

            <Text mb={3}>
                Cette demande a été marquée comme <Text as={"span"} fontWeight={'700'} color={"#ba0105"}>haute priorité</Text>. Voici sa justification :
            </Text>

            <Box border={"1pt solid #e2e2dd"} bgColor={"white"} p={5}>
                {dossier.priority_reason}
            </Box>
        </>}

        <Box mt={8}>
            <Text mb={3} ms={3} color={"#5b257d"} fontWeight={'800'} fontSize={'sm'}>TRAITEMENT DU DOSSIER</Text>
            <Box mb={5} height={'4px'} bgColor={"#5b257d"} width={'100%'} />
        </Box>

        <Text mb={3}>
            Ce dossier a été refusé par <Text as={"span"} fontWeight={700}>{dossier.manager}</Text> le {formatDate(dossier.status_date ?? new Date(), "dd/MM/yyyy à HH:mm")} pour la raison suivante:
        </Text>

        <Box border={"1pt solid #e2e2dd"} bgColor={"white"} p={5}>
            {dossier.status_reason}
        </Box>
    </Collapse>
</Box>
}

export default RefusedDossierCard;