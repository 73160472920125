import { Button, Checkbox, Flex, Skeleton, Spacer, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import '../../Home.css';
import { IUser } from '../../types';
import { useEffect, useState } from 'react';
import { acceptUsers, addAdmin, getAcceptedUsers, getAccessRequests, getAdmins, refuseUsers, removeAdmin } from '../../API/users';
import { UserSearchModal } from '../UserSearch';
import { faUserMinus, faUserTie, faUserXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface AdminsProps {}

const Admins: React.FC<AdminsProps> = ({}) => {
    const [users, setUsers] = useState<IUser[]>([]);
    const [selectedUser, setSelectedUser] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const toast = useToast();
    const search_disclosure = useDisclosure();

    useEffect(() => {
        getAdmins().then((res) => {
            setUsers(res.data.users);
        }).catch((error) => {
            toast({
                title: "Erreur",
                description: "Erreur lors de la récupération des administrateurs",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const handleCheckboxChange = (userId: string) => {
        if (selectedUser == userId) {
            setSelectedUser("");
        } else {
            setSelectedUser(userId);
        }
    };

    const handleRemoveAdmins = () => {
        removeAdmin(selectedUser).then((res) => {
            setUsers(users.filter((user) => user.matricule !== selectedUser));
            toast({
                title: "Succès",
                description: "Administrateur retiré avec succès",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
        }).catch((error) => {
            toast({
                title: "Erreur",
                description: "Erreur lors de la suppression d'un administrateur",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        });
    }

    const add_admin_callback = (matricule: string) => {
        addAdmin(matricule).then((res) => {
            setUsers([...users, res.data.user]);
            const userData:IUser = res.data.user;
            toast({
                title: "Succès",
                description: "Administrateur ajouté avec succès",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
        }).catch((error) => {
            toast({
                title: "Erreur",
                description: "Erreur lors de l'ajout d'un administrateur",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        });
    }

    return (
        <>
            {UserSearchModal(search_disclosure.isOpen, search_disclosure.onClose, add_admin_callback)}
            <Text p={5} pt={0}>
                Cette liste est la liste des membres du Bureau. Vous pouvez choisir d'en ajouter ou d'en retirer.
            </Text>
            <TableContainer bgColor={"white"} border={"1pt solid #e2e2dd"} p={5}>
                <Table variant='simple' size='sm'>
                    <Thead>
                        <Tr>
                            <Th>Nom</Th>
                            <Th>E-mail</Th>
                            <Th isNumeric></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {users.map((user, index) => (
                            <Tr key={index}>
                                <Td>{user.firstname} {user.lastname}</Td>
                                <Td>{user.email}</Td>
                                <Td isNumeric>
                                    <Checkbox
                                        isChecked={selectedUser == (user.matricule)}
                                        onChange={() => handleCheckboxChange(user.matricule)}
                                    />
                                </Td>
                            </Tr>
                        ))}
                        {!loading && users.length === 0 && (
                            <Tr>
                                <Td colSpan={3}>Aucun utilisateur.</Td>
                            </Tr>
                        )} 
                        {loading && 
                        [1, 2, 3, 4].map((index) => (
                            <Tr key={index}>
                                <Td><Skeleton height={"15px"} 
                                    width={
                                    Math.floor(Math.random() * 30) + 70 + "px"} /></Td>
                                <Td><Skeleton height={"15px"} 
                                    width={
                                    Math.floor(Math.random() * 80) + 120 + "px"} /></Td>
                                <Td></Td>
                            </Tr>))
                        }
                    </Tbody>
                </Table>
            </TableContainer>

            <Flex mt={5} me={5}>
                <Spacer />
                
                <Button 
                    onClick={search_disclosure.onOpen}
                    p={6} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                    rightIcon={<FontAwesomeIcon icon={faUserTie} />}
                    >
                    AJOUTER UN ADMINISTRATEUR
                </Button>
                <Button
                    ms={3}
                    p={6}
                    color={"white"}
                    _hover={{ backgroundColor: "#d92a2e" }}
                    bgColor={"#b32326"}
                    size="md"
                    fontSize={'10pt'}
                    fontWeight={'700'}
                    letterSpacing={'.8px'}
                    onClick={handleRemoveAdmins}
                    isDisabled={loading || selectedUser.length === 0}
                    rightIcon={<FontAwesomeIcon icon={faUserXmark} />}
                >
                    RETIRER DU BUREAU
                </Button>
            </Flex>
        </>
    );
};

export default Admins;