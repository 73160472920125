import { Box, Divider, Flex, Skeleton, SkeletonText, Spacer, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { getNotifications, watchNotifications } from "./API/notifications";
import { INotification, NotificationType } from "./types";
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, formatDate } from "date-fns";
import { Link } from "react-router-dom";

interface Props {
}

export const NotificationsContent: React.FunctionComponent<Props> = (props: Props) => {
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getNotifications().then((res) => {
            const notifs:INotification[] = res.data.notifications;
            setNotifications(notifs);
        }).catch((err) => {
        }).finally(() => {
            setLoading(false);
        });
        watchNotifications();
    }, []);

    const getLink = (notif:INotification) => {
        switch(notif.event_type) {
            case NotificationType.DossierAccepted:
                return `/dossiers/${notif.event_id}`;
            case NotificationType.DossierRefused:
                return `/account`;
        }
    }

    const getTitle = (notif:INotification) => {
        switch(notif.event_type) {
            case NotificationType.DossierAccepted:
                return `Dossier`;
            case NotificationType.DossierRefused:
                return `Dossier`;
        }
    }

    const getDate = (notif:INotification) => {
        // If under 1min, return difference in seconds
        // if under 1h, return difference in minutes
        // if under 1d, return difference in hours
        // else return difference in days
        // if higher than 7d, return date formatted
        const diff = differenceInSeconds(new Date(), notif.createdAt);
        if (diff < 60) {
            return `${diff}s`;
        }
        const diffM = differenceInMinutes(new Date(), notif.createdAt);
        if (diffM < 60) {
            return `${diffM}m`;
        }
        const diffH = differenceInHours(new Date(), notif.createdAt);
        if (diffH < 24) {
            return `${diffH}h`;
        }
        const diffD = differenceInDays(new Date(), notif.createdAt);
        if (diffD < 7) {
            return `${diffD} jours`;
        }

        return formatDate(notif.createdAt, "dd/MM/yyyy");
    }

    return <>
        {!loading && notifications.map((notif, index) => {
            return <Link key={index} to={getLink(notif)}>
                <Box>
                    <Flex mb={2}>
                        <Text fontSize={"sm"} fontWeight={700}>{getTitle(notif)}</Text>
                        <Spacer />
                        <Text fontSize={"sm"} color={"gray.500"}>Il y a {getDate(notif)}</Text>
                    </Flex>
                    <Text fontWeight={(notif.seen) ? 500 : 700} fontSize={"sm"}>{notif.content}</Text>
                </Box>
                <Divider mb={(index == notifications.length-1) ? 0 : 5} mt={5} />
            </Link>
        })}
        {loading && [1, 2, 3].map((_, index) => {
            return <Box key={index} mb={8}>
                        <Flex mb={3}>
                            <Skeleton height={'10px'} width={"75px"} />
                            <Spacer />
                            <Skeleton height={'10px'} width={"60px"} />
                        </Flex>
                        <Skeleton height={'10px'} width={Math.floor(Math.random() * 110) + 100 + "px"} />
                    </Box>
        })}
        {!loading && notifications.length === 0 && <Flex justifyContent={"center"}><Text fontSize={"sm"} color={"gray.400"}>Aucune notification</Text></Flex>
        }
    </>
}