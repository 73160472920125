import React, { useEffect, useState } from 'react';
import '../Home.css';
import { Box, Button, Center, Circle, Container, Divider, Flex, Grid, HStack, Heading, Image, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spacer, Stack, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';
import { Layout } from '../Layout';
import { CheckIcon, PhoneIcon, SearchIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faBullhorn, faComment, faEye, faFile, faFolderClosed, faFolderOpen, faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import LoremIpsum from 'react-lorem-ipsum';
import { Link as ReactLink, useLocation} from 'react-router-dom';
import { IArticle } from '../types';
import { getArticles } from '../API/news';
import ArticleCard from './ArticleCard';
import { ArticleCardSkeleton } from './ArticleCardSkeleton';
import ReactPaginate from 'react-paginate';
import Pagination from '../components/Pagination';

function News() {
  
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [articlesFetched, setArticlesFetched] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const ARTICLES_PER_PAGE = 9;

  useEffect(() => {
    getArticles(ARTICLES_PER_PAGE, 0, "createdAt", true).then((resp) => {
      setArticles(resp.data.articles);
      setTotalCount(resp.data.count);
      setArticlesFetched(true);
    });
  }, []);

  const page_change = (selectedItem: { selected: number }) => {
    const new_page:number = selectedItem.selected; // starts from 0
    
    setArticlesFetched(false);
    getArticles(ARTICLES_PER_PAGE, new_page*ARTICLES_PER_PAGE, "createdAt", true).then((resp) => {
      setArticles(resp.data.articles);
      setTotalCount(resp.data.count);
      setArticlesFetched(true);
    });
  }

  return (<>
    <Layout>
      <Text fontWeight={'900'} color={"#b3b3af"} fontSize={['5xl', '5xl', '7xl']} letterSpacing={"-.1rem"} mb={5} mt={5}>ADERE News</Text>
      
      <Box height={"100px"} />

      <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={5}>
        {articlesFetched && articles.map((article, index) => {
          return <Box mb={"100px"} me={index == 2 ? 0 : 10} key={index}><ArticleCard article={article} /></Box>;

        })}
      </Grid>

      
      {!articlesFetched && (<Flex>
        {[1, 2, 3].map((_, i) => <Box key={i}>{ArticleCardSkeleton(i == 3)}</Box>)}
      </Flex>)}

      {articlesFetched && articles.length == 0 && (
        <Center>
          <Text fontSize={"15pt"} color={"gray.500"}>Aucun article pour le moment.</Text>
        </Center>
      )}

      {totalCount > ARTICLES_PER_PAGE && (
        <Center mt={10}>
          <Pagination 
            page_change_callback={page_change} 
            totalCount={totalCount} 
            itemsPerPage={ARTICLES_PER_PAGE} 
          />
        </Center>
      )}
    </Layout>
    </>);
}

export default News;