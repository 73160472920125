import { Avatar, AvatarBadge, Box, Center, Flex, Grid, Skeleton, Spacer, Spinner, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text, useToast } from "@chakra-ui/react";
import { Layout } from "../Layout";
import { useUser } from "../UserContext";
import { useEffect, useState } from "react";
import { getMyDossierRequests, getMyDossiers } from "../API/dossier";
import { IDossier, IDossierRequest } from "../types";
import DossierCard from "../Dossiers/DossierCard";
import DossierRequestCard from "./DossierRequestCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faCircleQuestion, faQuestion, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { getProfilePicture, uploadFiles } from "../API/file";
import { setProfilePicture } from "../API/users";
import Cookies from "js-cookie";

export default function Account() {
    const { user, setUser } = useUser();
    const enum TabsEnum {
      DOSSIERS,
      REQUESTS
    };
    const [selectedTab, setSelectedTab] = useState<TabsEnum>(TabsEnum.DOSSIERS);
    const [dossiers, setDossiers] = useState<IDossier[]>([]);
    const [dossiersRequests, setDossiersRequests] = useState<IDossierRequest[]>([]);
    const [dossiersFetched, setDossiersFetched] = useState<boolean>(false);
    const [requestsFetched, setRequestsFetched] = useState<boolean>(false);
    const toast = useToast();
    const ALLOWED_FORMATS = ["image/jpeg", "image/png", "image/jpg", "image/gif", "image/svg", "image/webp"];
    const [b64Image, setB64Image] = useState<string | null>(null);
    const [avatarUploading, setAvatarUploading] = useState<boolean>(true);
    
    useEffect(() => {
        if (selectedTab === TabsEnum.DOSSIERS)
            getMyDossiers().then((res) => {
                setDossiers(res.data.dossiers);
                setDossiersFetched(true);
            }).catch((err) => {
                toast({
                    title: "Erreur",
                    description: "Une erreur s'est produite lors du chargement de vos dossiers.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }).finally(() => {
            });
        else
            getMyDossierRequests().then((res) => {
                setDossiersRequests(res.data.dossierRequests);
                setRequestsFetched(true);
            }).catch((err) => {
                toast({
                    title: "Erreur",
                    description: "Une erreur s'est produite lors du chargement de vos demandes de dossier.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }).finally(() => {
            });
    }, [selectedTab]);

    useEffect(() => {
        if (!user?.matricule) return;

        // Load profile picture
        getProfilePicture(user?.matricule ?? "").then((res) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                setB64Image(reader.result as string);
            };
            reader.readAsDataURL(res.data);
            setAvatarUploading(false);
        }).catch((err) => {
        }).finally(() => {
            setAvatarUploading(false);
        });
    }, [user]);

    const upload_picture = () => {
        document.getElementById('upload-file')?.click();
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
          const new_file = Array.from(e.target.files)[0];
  
          // Max 10MB
          if (new_file.size > 10000000) {
            toast({
              title: "Erreur",
              description: "L'image est trop lourde.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            return;
          }
          if (!ALLOWED_FORMATS.includes(new_file.type)) {
            toast({
              title: "Erreur",
              description: "Format d'image non supporté.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            return;
          }
  
          // Upload file
          setAvatarUploading(true);
          const formData = new FormData();
          formData.append('files', new_file);
          uploadFiles(formData).then((res) => {
              if (res.status === 201) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setB64Image(reader.result as string);
                };
                reader.readAsDataURL(new_file);
                setAvatarUploading(false);
                setProfilePicture(res.data.ids[0]).then((res) => {
                    if (res.status === 200) {
                        toast({
                            title: "Succès",
                            description: "Votre photo de profil a été mise à jour.",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                }).catch((err) => {
                    toast({
                        title: "Erreur",
                        description: "Une erreur s'est produite lors de la mise à jour de votre photo de profil.",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }).finally(() => {
                    setAvatarUploading(false);
                });
              }
          });
        }
    }

    const logout = () => {
        // Remove JWT token
        Cookies.remove('jwt_adere');
        setUser({
            matricule: "",
            firstname: "",
            lastname: "",
            rank: "",
            whitelist: false,
        });
        document.dispatchEvent(new Event('TOKEN_ERROR'));
    }

    return <Layout>
        <Flex mb={5}>
            <Text fontWeight={'900'} color={"#b3b3af"} fontSize={['5xl', '5xl', '7xl']} letterSpacing={"-.1rem"}> Mon compte</Text>
            <Spacer />
            <Text me={5} onClick={() => {
                window.location.href = "/welcome";
            }} mt={"55px"} cursor={"pointer"} color={"#005ca9"} opacity={0.8} fontSize={"sm"} fontWeight={900}><FontAwesomeIcon icon={faCircleQuestion} style={{marginRight:"5px"}} />VOIR LE TUTORIEL</Text>
            <Text onClick={logout} mt={"55px"} cursor={"pointer"} color={"#610002"} opacity={0.8} fontSize={"sm"} fontWeight={900}><FontAwesomeIcon icon={faRightFromBracket} style={{marginRight:"5px"}} />DÉCONNEXION</Text>
        </Flex>

        <Center mt={8} mb={8}>

            {avatarUploading && 
            <Spinner size={"xl"} thickness="4px" />
            }

            {!avatarUploading && <Avatar className="hvr-grow" me={4} size={'2xl'} name={user?.firstname+" "+user?.lastname} src={b64Image ?? "https://www.uliege.be/plugins/WebServiceTrombiPlugin/images/profile.jpg"}>
                <AvatarBadge onClick={upload_picture} id="avatar-badge" cursor={"pointer"} border={"none"} boxSize='0.7em' bg='gray.600'>
                    <Text mt={1} fontSize={"20px"}><FontAwesomeIcon icon={faCamera} color="#232830" /></Text>
                </AvatarBadge>
                <input 
                    multiple={false} 
                    accept={ALLOWED_FORMATS.join(',')}
                    id='upload-file' 
                    type="file" 
                    name="photo" 
                    style={{opacity:0, position:"absolute", zIndex:-10}}
                    onChange={handleFileChange}
                />
            </Avatar>
            }
        </Center>

        <Tabs position="relative" variant="unstyled" mt={5} isLazy={true} onChange={
            (index) => {
                setSelectedTab(index);
            }
        }>
            <TabList>
                <Tab fontWeight={800} p={3} fontSize={'sm'}>MES DOSSIERS</Tab>
                <Tab fontWeight={800} p={3} fontSize={'sm'}>MES DEMANDES DE DOSSIER</Tab>
            </TabList>
            <TabIndicator
                mt="-1.5px"
                height="3px"
                bg="#004851"
                borderRadius="1px"
            />
            <TabPanels>
                <TabPanel p={0} pt={8}>
                    
                    {dossiersFetched && dossiers.length === 0 &&
                        <Text textAlign={"center"} fontSize={'lg'} fontWeight={'400'}>Vous n'avez ouvert aucun dossier.</Text>
                    }
                    {dossiersFetched && dossiers.length > 0 &&
                        <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(3, 1fr)']} gap={6} mt={3} mb={6}>
                            {dossiers.map((dossier, index) => {
                                return (
                                <DossierCard key={index} dossier={dossier} />
                            )})}
                        </Grid>
                    }

                    {!dossiersFetched &&
                        <Grid templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(3, 1fr)']} gap={6} mt={3} mb={6}>
                        {[1, 2, 3, 4, 5].map((_, index) => {
                            return (
                            <Skeleton key={index} boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} height={'150px'} width={"310px"} />
                            )})}
                        </Grid>
                    }
                </TabPanel>
                <TabPanel p={0} pt={8}>
                    {requestsFetched && dossiersRequests.map((request, index) => {
                        return (
                        <DossierRequestCard key={index} dossier={request} handle_callback={() => {}} categories={[]} />
                    )})}

                    {requestsFetched && dossiersRequests.length === 0 &&
                        <Text textAlign={"center"} fontSize={'lg'} fontWeight={'400'}>Vous n'avez fait aucune demande d'ouverture de dossier.</Text>
                    }

                    {!requestsFetched && dossiersRequests.length === 0 &&
                        <Text textAlign={"center"} fontSize={'lg'} fontWeight={'400'}>Vous n'avez fait aucune demande de dossier.</Text>
                    }

                    {!requestsFetched &&
                        [1, 2, 3].map((_, index) => {
                            return (
                                <Skeleton mb={5} width={"100%"} height={"90px"} key={index} />
                            )
                        })
                    }
                </TabPanel>
            </TabPanels>
        </Tabs>
    </Layout>
}