import { Box, Button, Center, Grid, Skeleton, Spinner, Text, useToast } from "@chakra-ui/react";
import { Image as ChakraImage } from "@chakra-ui/react";
import logo from './resources/adere_logo.svg';
import { useEffect, useState } from "react";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { IDossier } from "./types";
import { searchDossierByTag } from "./API/dossier";
import { Layout } from "./Layout";
import DossierCard from "./Dossiers/DossierCard";

export const Search: React.FunctionComponent = () => {
    const params = useParams();
    const tag = params.tag;
    const [dossiers, setDossiers] = useState<IDossier[]>([]);
    const [dossiersFetched, setDossiersFetched] = useState<boolean>(false);
    const toast = useToast();
    const [tagError, setTagError] = useState<boolean>(false);

    useEffect(() => {
        if (!tag) return;
        if (tag.length < 3) {
            setTagError(true);
            setDossiersFetched(true);
        }
        else {
            searchDossierByTag(tag).then((resp) => {
                setDossiers(resp.data.dossiers);
            }).catch((error) => {
                toast({
                    title: "Erreur",
                    description: "Impossible de récupérer les dossiers",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
            }).finally(() => {
                setDossiersFetched(true);
            });
        }
    }, [tag]);
return (<Layout>
    <Box mb={5} mt={"50px"}>
        <Text mb={3} ms={3} color={"#00707f"} fontWeight={'800'} fontSize={'sm'}>RÉSULTATS DE LA RECHERCHE "{tag}"</Text>
        <Box mb={10} height={'4px'} bgColor={"#00707f"} width={'100%'} />
    </Box>

    {!tagError && dossiersFetched && dossiers.length === 0 &&
        <Text textAlign={"center"} color={"#3e3e3e"} fontSize={"md"}>Aucun dossier trouvé</Text>
    }
    
    {tagError &&
        <Text textAlign={"center"} color={"red.500"} fontSize={"md"}>Veuillez écrire au moins trois lettres dans la recherche.</Text>
    }

    {dossiersFetched &&
        <Grid templateColumns='repeat(3, 2fr)' gap={6} mt={3} mb={6}>
            {dossiers.map((dossier, index) => {
            return (
                <DossierCard key={index} dossier={dossier} />
            )})}
        </Grid>
    }

    
    {!dossiersFetched &&
        <Grid templateColumns='repeat(3, 2fr)' gap={6} mt={3} mb={6}>
          {[1, 2, 3, 4, 5].map((_, index) => {
            return (
              <Skeleton key={index} boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} height={'150px'} width={"310px"} />
            )})}
        </Grid>
      }
</Layout>);
}