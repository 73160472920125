import { AxiosResponse } from "axios";
import API, { API_URL, ApiDataType } from "./api";


/**
 * GET request to the server to get news articles
 */
export const getArticles = async ( limit:number, offset:number=0, sort:string="createdAt", lightweight:boolean=true): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/articles`,
            { limit, offset, sort, lightweight }
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getArticleById = async (id: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/articles/id/${id}`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getArticleBySlug = async (slug: string, lightweight:boolean): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/articles/${slug}`,
            { lightweight }
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const newArticle = async (title: string, content: string, category: string, picture: string | null): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/articles/new`,
            { title, content, category, picture },
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const updateArticle = async (id: string, title: string, content: string, category: string, picture: string | null): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.put(
            `${API_URL}/articles/${id}`,
            { title, content, category, picture }
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const deleteArticle = async (id: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.delete(
            `${API_URL}/articles/${id}`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}