import React, { useRef } from 'react';
import '../Home.css';
import { Box, Button, Collapse, Divider, Flex, FormControl, FormHelperText, FormLabel, Input, InputGroup, InputRightAddon, Radio, RadioGroup, Spacer, Stack, Text, Textarea, Tooltip, useToast } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus, faPlus, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import { IDossier, PollType } from '../types';
import { createPoll } from '../API/poll';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { loadCldr } from '@syncfusion/ej2-base';
import * as gregorian from 'cldr-data/main/fr/ca-gregorian.json';
import * as numbers from 'cldr-data/main/fr/numbers.json';
import * as timeZoneNames from 'cldr-data/main/fr/timeZoneNames.json';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as weekData from 'cldr-data/supplemental/weekData.json';// To load the culture based first day of week
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);


export function NewPoll(isOpen:boolean, onOpen:any, onClose:any, dossier_id:string="", submit_handler:Function|null=null) {
const newPollTitle = useRef<HTMLInputElement>(null);
const newPollDescription = useRef<HTMLTextAreaElement>(null);
const [newPollType, setNewPollType] = React.useState(PollType.SingleChoice);
const [newPollAnswers, setNewPollAnswers] = React.useState<string[]>(['', '']);
const [deadline, setDeadline] = React.useState<Date>(new Date());
const toast = useToast();
const [isSubmitting, setIsSubmitting] = React.useState(false);

const handleNewPollTypeChange = (value: PollType) => {
    setNewPollType(value);
};

const handleNewPollAnswerChange = (index: number, value: string) => {
    const updatedAnswers = [...newPollAnswers];
    updatedAnswers[index] = value;
    setNewPollAnswers(updatedAnswers);
};

const handleAddNewPollAnswer = () => {
    setNewPollAnswers([...newPollAnswers, '']);
};

const handleRemoveNewPollAnswer = (index: number) => {
    if (newPollAnswers.length > 2) {
    const updatedAnswers = [...newPollAnswers];
    updatedAnswers.splice(index, 1);
    setNewPollAnswers(updatedAnswers);
    }
};

const submit_new_poll = () => {
    // Check if there is at least 2 answers, not empty
    if (newPollAnswers.length < 2 || newPollAnswers.filter((answer) => answer.trim() === '').length > 0) {
        toast({
            title: "Erreur",
            description: "Veuillez entrer au moins 2 réponses pour le sondage.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
            size: "md",
        });
        return;
    }

    // Check if answers are not the same
    if (new Set(newPollAnswers).size !== newPollAnswers.length) {
        toast({
            title: "Erreur",
            description: "Veuillez entrer des réponses différentes pour le sondage.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
            size: "md",
        });
        return;
    }

    // Check if the title is not empty
    if (newPollTitle.current?.value === '') {
        toast({
            title: "Erreur",
            description: "Veuillez entrer un titre pour le sondage.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
            size: "md",
        });
        return;
    }

    // Check if the deadline is not in the past and not empty
    if (deadline === null || deadline < new Date()) {
        toast({
            title: "Erreur",
            description: "Veuillez entrer une date de fin pour le sondage.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
            size: "md",
        });
        return;
    }

    // Send to DB
    if (!submit_handler) {
        setIsSubmitting(true);
        createPoll(newPollTitle.current!.value, newPollDescription.current!.value, newPollType, newPollAnswers, deadline, dossier_id).then((resp) => {
            if (resp.status === 201) {
                handleNewPollCreated();
            }}).catch((error) => {
                toast({
                    title: "Erreur",
                    description: "Une erreur est survenue lors de la création du sondage. ("+error.message +")",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "top-right",
                    size: "md",
                });
        }).finally(() => {
            setIsSubmitting(false);
        });
    } else {
        submit_handler(newPollTitle.current!.value, newPollDescription.current!.value, newPollType, newPollAnswers, deadline);
        handleNewPollCreated();
    }
}

const handleNewPollCreated = () => {
    newPollTitle.current!.value = '';
    newPollDescription.current!.value = '';
    setNewPollType(PollType.SingleChoice);
    setNewPollAnswers(['', '']);
    onClose();

    // Show success message
    toast({
    title: "Sondage créé",
    description: "Le sondage a bien été créé et ajouté à ce dossier !",
    status: "success",
    duration: 5000,
    isClosable: true,
    position: "top-right",
    size: "md",
    });
}

return (<Collapse in={isOpen} animateOpacity>
    <Box pt={0} mt={5} mb={5}>
        <Box boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} p={5} border={"1pt solid #e2e2dd"} bgColor={"white"} width={"100%"}>
            <Flex>
                {/* <Text mb={2} fontWeight={'800'} fontSize={'3xl'} color={"#4a4a4a"} letterSpacing={"-.1rem"}>Nouveau sondage dans ce dossier</Text> */}
                <Text mb={3} ms={3} color={"#4a4a4a"} fontWeight={'800'} fontSize={'sm'}>NOUVEAU SONDAGE</Text>
                <Spacer />
                {/* Close button */}
                <FontAwesomeIcon icon={faXmark} style={{cursor:"pointer"}} onClick={onClose} />
            </Flex>

            <Box mb={5} height={'4px'} bgColor={"#4a4a4a"} width={'100%'} />

            <FormControl>
                <FormLabel>Titre du sondage</FormLabel>
                <Input 
                    type='text' 
                    ref={newPollTitle}/>
            </FormControl>
            
            <FormControl mt={3}>
                <FormLabel fontStyle="italic">Description du sondage (optionnel)</FormLabel>
                <Textarea 
                    ref={newPollDescription}
                />
            </FormControl>


            <FormControl mt={3}>
                <FormLabel>Type de sondage</FormLabel>
                <RadioGroup value={newPollType} onChange={handleNewPollTypeChange}>
                    <Stack direction='row'>
                    <Radio value={PollType.SingleChoice}>
                        <Tooltip hasArrow p={2} placement='top' label="Les participant·e·s ne pourront sélectionner qu'une seule réponse">
                        <Text>Choix unique</Text>
                        </Tooltip>  
                    </Radio>
                    <Radio value={PollType.MultiChoice}>
                        <Tooltip hasArrow p={2} placement='top' label="Les participant·e·s pourront sélectionner plusieurs réponses">
                            <Text>Choix multiple</Text>
                    </Tooltip>
                    </Radio>
                        <Radio value={PollType.Sort}>
                        <Tooltip hasArrow p={2} placement='top' label="Les participant·e·s pourront classer les réponses par ordre de préférence">
                            <Text>Tri par préférence</Text>
                        </Tooltip>
                        </Radio>
                    </Stack>
                </RadioGroup>
            </FormControl>

            <FormControl mt={3}>
                <FormLabel>Deadline</FormLabel>
                <DateTimePickerComponent 
                    locale='fr' 
                    width={'250px'} 
                    id="datetimepicker" 
                    min={new Date()}
                    value={deadline}
                    allowEdit={false}
                    onChange={(e:any) => {setDeadline(e.value);}}
                />
                <FormHelperText>Une deadline est obligatoire.</FormHelperText>
            </FormControl>
            <Divider mt={3} mb={3} />

            <Text fontWeight={500}>
                Réponses possibles
            </Text>

            <Box p={2}>
                {newPollAnswers.map((answer, index) => (
                <InputGroup size='sm' key={index} mb={2}>
                    <Input placeholder='Réponse...' value={answer} onChange={(e) => handleNewPollAnswerChange(index, e.target.value)} />
                    {index >= 2 && (
                    <InputRightAddon cursor={"pointer"} onClick={() => handleRemoveNewPollAnswer(index)}>
                        <FontAwesomeIcon icon={faTrashCan} />
                    </InputRightAddon>
                    )}
                </InputGroup>
                ))}
                <Flex>
                {/* <Spacer /> */}
                <Button size={"sm"} colorScheme={'gray'} mt={1} rightIcon={<FontAwesomeIcon icon={faPlus} />} rounded={0} onClick={handleAddNewPollAnswer} variant={'outline'}>Ajouter une réponse</Button>
                </Flex>
            </Box>

            <Flex>
                <Spacer />
                <Button isDisabled={isSubmitting} colorScheme={'telegram'} mt={4} rightIcon={<FontAwesomeIcon icon={faFolderPlus} />} rounded={0} onClick={submit_new_poll} variant={'outline'}>Ajouter ce sondage au dossier</Button>
            </Flex>
                
        </Box>
    </Box>
</Collapse>)
}