import { useRef, useState } from 'react';
import { Box, Button, Divider, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spacer, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faMagnifyingGlass, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { IUser } from '../types';
import { searchUser} from '../API/elections';

export function UserSearchModal (isOpen:boolean, onClose:()=>void, set_matricule:Function) {
    const [result, setResult] = useState<IUser|null>(null);
    const email = useRef<HTMLInputElement>(null);
    const name = useRef<HTMLInputElement>(null);
    const matricule = useRef<HTMLInputElement>(null);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [searching, setSearching] = useState<boolean>(false);

    const search_user = () => {
        if (searching) return;

        // Check that at least one field is filled
        if (!email.current?.value && !name.current?.value && !matricule.current?.value) return;

        setNotFound(false);
        setSearching(true);
        searchUser(matricule.current?.value, email.current?.value, name.current?.value).then((resp) => {
            setResult(resp.data.user);
        }).catch((error) => {
            setNotFound(true);
        }).finally(() => {
            setSearching(false);
        });
    }

    const close_modal = () => {
        setResult(null);
        setNotFound(false);
        setSearching(false);
        email.current!.value = "";
        name.current!.value = "";
        matricule.current!.value = "";
        onClose();
    }
    

    return <Modal
    onClose={onClose} 
    size={"3xl"} 
    isOpen={isOpen}
    motionPreset='scale'
    closeOnOverlayClick={true}
    >
        <ModalOverlay backdropFilter='blur(5px)' />
        <ModalContent>
            <ModalHeader>
            <Text fontWeight={'900'} color={"#314e89"} fontSize={'xx-large'} letterSpacing={"-.1rem"}>Recherche de membre</Text>
            <Divider mt={3} />
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody mt={0} ps={8} pe={8} pb={8} pt={0}>
                <Text mb={5}>
                    Utilisez un des champs ci-dessous pour rechercher un membre de l'ADERE.
                </Text>
                <Text mb={5}>
                    <FontAwesomeIcon icon={faCircleExclamation} style={{marginRight:"5px"}} />Il n'est pas nécessaire de remplir tous les champs.
                </Text>
                <FormControl>
                    <FormLabel fontSize={'sm'} fontWeight={700}>ADRESSE E-MAIL</FormLabel>
                    <Input 
                        isDisabled={searching} type='email' maxLength={255} ref={email} 
                        onKeyDown={(e) => {if (e.key === "Enter") search_user();}}
                    />
                </FormControl>
                <FormControl mt={3}>
                    <FormLabel fontSize={'sm'} fontWeight={700}>NOM/PRÉNOM</FormLabel>
                    <Input 
                        isDisabled={searching} type='text' maxLength={255} ref={name} 
                        onKeyDown={(e) => {if (e.key === "Enter") search_user();}}
                    />
                </FormControl>
                <FormControl mt={3}>
                    <FormLabel fontSize={'sm'} fontWeight={700}>MATRICULE</FormLabel>
                    <Input 
                        isDisabled={searching} type='text' maxLength={10} ref={matricule}
                        onKeyDown={(e) => {if (e.key === "Enter") search_user();}}
                    />
                </FormControl>

                {notFound &&
                <Text mt={5} fontSize={'sm'} fontWeight={800} color={"red.500"}>AUCUN RÉSULTAT</Text>
                }
                {result &&<>
                <Text mt={5} fontSize={'sm'} fontWeight={800} color={"green.500"}>RÉSULTAT : </Text>
                <Box mt={2} border={"1pt solid #e2e2dd"} p={5}>
                    <Text fontWeight={"700"} fontSize={'md'}>{result.firstname} {result.lastname.toUpperCase()}</Text>
                    <Text fontSize={"md"}>{result.email}</Text>
                </Box></>
                }

                <Flex>
                    <Spacer />
                    <Button 
                    onClick={search_user}
                    isDisabled={searching}
                    p={6} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                    me={2}
                    leftIcon={<FontAwesomeIcon icon={faMagnifyingGlass} />} >
                        CHERCHER
                    </Button>

                    {result && 
                        <Button 
                        isDisabled={searching}
                        p={6} color={"white"} _hover={{backgroundColor:"#00d05d"}} bgColor={"#00aa4c"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                        onClick={() => {set_matricule(result.matricule); close_modal();}}
                        leftIcon={<FontAwesomeIcon icon={faUserPlus} />} >
                            SÉLECTIONNER
                        </Button>
                    }
                </Flex>
            </ModalBody>
        </ModalContent>
    </Modal>
}