import { ICategory, IDossierRequest, RequestStatus } from "../../types";
import { useEffect, useState } from "react";
import { getCategories, getDossiersRequests } from "../../API/dossier";
import { Center, Skeleton, Text } from "@chakra-ui/react";
import RefusedDossierCard from "./RefusedDossierCard";
import Pagination from "../../components/Pagination";
// import ReactPaginate from "react-paginate";

export interface RefusedDossiersProps {}

const RefusedDossiers: React.FC<RefusedDossiersProps> = ({}) => {

    const [requests, setRequests] = useState<IDossierRequest[]>([]);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [totalRequests, setTotalRequests] = useState<number>(0);
    const MAX_DOSSIERS_PER_PAGE = 10;
    useEffect(() => {
        getDossiersRequests(RequestStatus.Refused, MAX_DOSSIERS_PER_PAGE, 0).then((resp) => {
            setRequests(resp.data.dossierRequests);
            setTotalRequests(resp.data.count);
            setLoading(false);
        });
        getCategories().then((resp) => {
            setCategories(resp.data.categories);
        });
    }, []);

    const page_change = (selectedItem: { selected: number }) => {
        const page = selectedItem.selected;
        setLoading(true);
        getDossiersRequests(RequestStatus.Refused, MAX_DOSSIERS_PER_PAGE, page*MAX_DOSSIERS_PER_PAGE).then((resp) => {
            setRequests(resp.data.dossierRequests);
            setTotalRequests(resp.data.count);
            setLoading(false);
        });
    }

    return <>
        {!loading && requests.length > 0 && requests.map((request: IDossierRequest) => {
            // Get the categories that have their id in the request's categories array
            const requestCategories:ICategory[] = categories.filter((category: ICategory) => {
                return request.categories.includes(category._id);
            });
            return <RefusedDossierCard key={request._id} dossier={request} categories={requestCategories} />
        })}
        {!loading && requests.length === 0 && 
        <Center>
            <Text fontSize={'md'}>Aucun dossier refusé</Text>
        </Center>
        }
        {loading &&<>
            {[1, 2, 3].map((i) => {
                return <Skeleton key={i} height={"120px"} width={"100%"} mb={3} />
            })}
        </>}

        
        {totalRequests > MAX_DOSSIERS_PER_PAGE && <Center>
                <Pagination itemsPerPage={MAX_DOSSIERS_PER_PAGE} page_change_callback={page_change} totalCount={totalRequests}/>
        </Center>}
    </>
}

export default RefusedDossiers;