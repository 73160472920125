import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, Center, Checkbox, Flex, Skeleton, Spacer, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useToast } from '@chakra-ui/react';
import '../../Home.css';
import { IUser } from '../../types';
import { useEffect, useState } from 'react';
import { acceptUsers, getRefusedUsers } from '../../API/users';
import { faUserCheck, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../../components/Pagination';

export interface BlacklistProps {}

const Blacklist: React.FC<BlacklistProps> = ({}) => {
    const [users, setUsers] = useState<IUser[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const toast = useToast();

    useEffect(() => {
        getRefusedUsers(0, MAX_USERS_PER_PAGE).then((res) => {
            setUsers(res.data.users);
            setTotalUsers(res.data.count);
        }).catch((error) => {
            toast({
                title: "Erreur",
                description: "Erreur lors de la récupération des utilisateurs",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const handleCheckboxChange = (userId: string) => {
        if (selectedUsers.includes(userId)) {
            setSelectedUsers(selectedUsers.filter((id) => id !== userId));
        } else {
            setSelectedUsers([...selectedUsers, userId]);
        }
    };

    const updateRemainingTime = (remainingTime:number) => {
        if (remainingTime === 0) {
            setMailTimeout(false);
        }

        if (remainingTime > 0) {
            setTimeout(() => {
                setTsxRemainingTime(remainingTime - 1);
                updateRemainingTime(remainingTime - 1);
            }, 1000);
        }
    }

    const [accepting, setAccepting] = useState<boolean>(false);
    const [mailTimeout, setMailTimeout] = useState<boolean>(false);
    const [tsxRemainingTime, setTsxRemainingTime] = useState<number>(0);
    const handleAcceptUsers = () => {
        setAccepting(true);
        acceptUsers(selectedUsers).then((res) => {
            setUsers(users.filter((user) => !selectedUsers.includes(user.matricule)));
            toast({
                title: "Succès",
                description: "Utilisateur(s) accepté(s) et e-mail(s) envoyé(s) avec succès.",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
        }).catch((error) => {
            toast({
                title: "Erreur",
                description: "Erreur lors de l'acceptation d'un utilisateur",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }).finally(() => {
            setAccepting(false);
        });
        setSelectedUsers([]);
    };
    
    const [totalUsers, setTotalUsers] = useState<number>(0);
    const MAX_USERS_PER_PAGE = 20;
    const page_change = (selectedItem: { selected: number }) => {
        const page:number = selectedItem.selected;

        getRefusedUsers(page*MAX_USERS_PER_PAGE, MAX_USERS_PER_PAGE).then((res) => {
            setUsers(res.data.users);
            setTotalUsers(res.data.count);
        }).catch((error) => {
            toast({
                title: "Erreur",
                description: "Erreur lors de la récupération des demandes d'accès",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <Text p={5} pt={0}>
                La Blacklist est une liste d'utilisateurs qui ont réalisé une demande d'accès et qui ont été refusés.
            </Text>
            <TableContainer bgColor={"white"} border={"1pt solid #e2e2dd"} p={5}>
                <Table variant='simple' size='sm'>
                    <Thead>
                        <Tr>
                            <Th>Nom</Th>
                            <Th>Prénom</Th>
                            <Th>E-mail</Th>
                            <Th isNumeric></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {users.map((user, index) => (
                            <Tr key={index}>
                                <Td>{user.lastname}</Td>
                                <Td>{user.firstname}</Td>
                                <Td>{user.email}</Td>
                                <Td isNumeric>
                                    <Checkbox
                                        isChecked={selectedUsers.includes(user.matricule)}
                                        onChange={() => handleCheckboxChange(user.matricule)}
                                    />
                                </Td>
                            </Tr>
                        ))}
                        {!loading && users.length === 0 && (
                            <Tr>
                                <Td colSpan={4}>Aucun utilisateur.</Td>
                            </Tr>
                        )} 
                        {loading && 
                        [1, 2, 3, 4].map((index) => (
                            <Tr key={index}>
                                <Td><Skeleton height={"15px"} 
                                    width={
                                    Math.floor(Math.random() * 30) + 70 + "px"} /></Td>
                                <Td><Skeleton height={"15px"} 
                                    width={
                                    Math.floor(Math.random() * 80) + 120 + "px"} /></Td>
                                <Td></Td>
                            </Tr>))
                        }
                    </Tbody>
                    <Tfoot>
                        <Tr>
                            <Th></Th>
                            <Th></Th>
                            <Th></Th>
                            <Th isNumeric pt={3}>
                                Tout sélectionner
                                <Checkbox
                                    ms={3}
                                    isChecked={selectedUsers.length === users.length && users.length > 0}
                                    onChange={() => {
                                        if (selectedUsers.length === users.length) {
                                            setSelectedUsers([]);
                                        } else {
                                            setSelectedUsers(users.map((user) => user.matricule));
                                        }
                                    }}
                                />
                            </Th>
                        </Tr>
                    </Tfoot>
                </Table>
            </TableContainer>

            {mailTimeout && 
                <Alert status='error' mt={5}>
                    <AlertIcon />
                    <AlertTitle>Trop de mails envoyés</AlertTitle>
                    <AlertDescription>Afin de ne pas vous faire bloquer par le serveur mail de l'ULiège, veuillez attendre {tsxRemainingTime} secondes, merci.</AlertDescription>
                </Alert>
            }

            {totalUsers > MAX_USERS_PER_PAGE && <Center>
                <Pagination itemsPerPage={MAX_USERS_PER_PAGE} page_change_callback={page_change} totalCount={totalUsers}/>
            </Center>}

            <Flex mt={5} me={5}>
                <Spacer />
                <Button
                    ms={3}
                    p={6}
                    color={"white"}
                    _hover={{ backgroundColor: "#00aa4c" }}
                    bgColor={"#007635"}
                    size="md"
                    fontSize={'10pt'}
                    fontWeight={'700'}
                    letterSpacing={'.8px'}
                    onClick={handleAcceptUsers}
                    isDisabled={accepting || mailTimeout || loading || selectedUsers.length === 0}
                    rightIcon={<FontAwesomeIcon icon={faUserCheck} />}
                >
                    PLACER EN WHITELIST
                </Button>
            </Flex>
        </>
    );
};

export default Blacklist;