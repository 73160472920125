import { AbsoluteCenter, Box, Button, Center, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { Image as ChakraImage } from "@chakra-ui/react";
import logo from './resources/adere_logo.svg';
import { useEffect, useState } from "react";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import { askAccess, getAccessStatus } from "./API/users";
import { IUser } from "./types";
import { useUser } from "./UserContext";

export const Whitelist: React.FunctionComponent = () => {
    const [loading, setLoading] = useState(true);
    const toast = useToast();
    const [accessRequested, setAccessRequested] = useState(false);
    const { user, setUser } = useUser();

    useEffect(() => {
        // Preload the image
        const img = new Image();
        img.src = logo;

        getAccessStatus().then((resp) => {
            const user:IUser|null = resp.data.user;
            const token:string = resp.data.token;
            if (user && user.whitelist) {
                setUser({
                    rank: user.rank,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    matricule: user.matricule,
                    whitelist: user.whitelist,
                });
                Cookies.set('jwt_adere', token);
                setLoading(false);
            }
            if (user) {
                setAccessRequested(true);
            }
        }).catch((error) => {})
        .finally(() => {
            setLoading(false);
        });
    }, []);

    const cancel = () => {
        // Destroy the JWT token
        Cookies.remove('jwt_adere');
        window.location.href = "https://www.uliege.be/";
    }

    const ask_access = () => {
        askAccess().then((resp) => {
            if (resp.status === 200) {
                setAccessRequested(true);
                toast({
                    title: "Demande d'accès envoyée",
                    description: "Votre demande d'accès a bien été envoyée au Bureau de l'ADERE.",
                    status: "success",
                    position: "top",
                    duration: 9000,
                    isClosable: true,
                });
            }
            else {
                toast({
                    title: "Erreur",
                    description: "Une erreur est survenue lors de l'envoi de votre demande d'accès.",
                    status: "error",
                    position: "top",
                    duration: 9000,
                    isClosable: true,
                });
            }
        }).catch((error) => {
            toast({
                title: "Erreur",
                description: "Une erreur est survenue lors de l'envoi de votre demande d'accès.",
                status: "error",
                position: "top",
                duration: 9000,
                isClosable: true,
            });
        }
        );
    }

return (<>

    {loading &&
        <AbsoluteCenter mt={"250px"}>
            <Spinner
                thickness='5px'
                speed='0.65s'
                emptyColor='gray.200'
                color='#a8589e'
                size='xl'
            />
        </AbsoluteCenter>
    }
    
    {!loading &&
        <Box>
            <Center mt={20}>
            <ChakraImage src={logo} width={'300px'} className='noselect' />
            </Center>

            {!accessRequested && <>
                <Center mt={"40px"}>
                    <Text color={"#131313"}>En cliquant sur ce bouton, une demande d'accès sera envoyée au Bureau de l'ADERE.</Text>
                </Center>
                <Center mb={"30px"}>
                    <Text color={"#131313"}>Une fois votre demande validée, vous pourrez accéder à la plateforme.</Text>
                </Center>

                <Center>
                    <Flex>
                        <Button
                        onClick={cancel}
                        p={6} m={3} color={"white"} _hover={{backgroundColor:"#d8d5c8"}} bgColor={"#b2b0a5"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} >
                        ANNULER
                        </Button>
                        <Button
                        onClick={ask_access}
                        p={6} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                        leftIcon={<FontAwesomeIcon icon={faRightToBracket} />} >
                        DEMANDER L'ACCÈS
                        </Button>
                    </Flex>
                </Center>
            </>}

            
            {accessRequested && <>
                <Center mt={"40px"}>
                    <Text color={"#131313"}>Votre demande d'accès à la plateforme est en cours de traitement.</Text>
                </Center>
            </>}
        </Box>
    }
</>);
}