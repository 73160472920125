import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { IElection, IElectionCandidate } from "../../types";
import { Box, Button, Divider, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Skeleton, Spacer, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getCandidates, getVotesPerCandidate, stopElection } from "../../API/elections";
import { formatDate } from "date-fns";

export interface OngoingElectionProps {
    election:IElection;
}

const stopElectionModal = (isOpen:boolean, onClose:()=>void, submit_callback:Function) => {

    return <Modal
    onClose={onClose} 
    size={"3xl"} 
    isOpen={isOpen}
    motionPreset='scale'
    closeOnOverlayClick={true}
    >
        <ModalOverlay backdropFilter='blur(5px)' />
        <ModalContent>
            <ModalHeader>
            <Text fontWeight={'900'} color={"#314e89"} fontSize={'xx-large'} letterSpacing={"-.1rem"}>Arrêter l'élection</Text>
            <Divider mt={3} />
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody mt={0} ps={8} pe={8} pb={8} pt={0}>
                <Text>Voulez-vous vraiment arrêter l'élection en avance ? Les membres ne pourront plus voter.</Text>
                
                <Flex>
                    <Spacer />
                    <Button 
                        width={"250px"}
                        onClick={() => {submit_callback(); onClose();}}
                        p={6} ps={8} pe={8} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={8} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}>
                        CONFIRMER
                    </Button>
                </Flex>
            </ModalBody>
        </ModalContent>
    </Modal>
}

const OngoingElection: React.FC<OngoingElectionProps> = ({election}) => {
    const toast = useToast();
    const [votesPerCandidate, setVotesPerCandidate] = useState<{name:string, votes:number}[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [chartRendered, setChartRendered] = useState<boolean>(false);

    useEffect(() => {
        getVotesPerCandidate(election._id).then((resp) => {
            const candidates:IElectionCandidate[] = resp.data.electionCandidates;
            let votes:{name:string, votes:number}[] = [];
            candidates.forEach((candidate) => {
                votes.push({name:candidate.user.firstname + " " + candidate.user.lastname, votes:candidate.votes_number || 0});
            });
            setVotesPerCandidate(votes);
            setLoading(false);
        }).catch((err) => {
            toast({
                title: "Erreur",
                description: "Une erreur est survenue lors de la récupération des votes",
                status: "error",
                duration: 6000,
                isClosable: true,
                position: "top-right"
            });
        });
    }, [election]);

    const stopElectionDisclosure = useDisclosure();
    const [electionStopped, setElectionStopped] = useState<boolean>(false);

    const stop_election = () => {
        stopElection(election._id).then((resp) => {
            setElectionStopped(true);
            toast({
                title: "Succès",
                description: "L'élection a été arrêtée avec succès",
                status: "success",
                duration: 6000,
                isClosable: true,
                position: "top-right"
            });
        }).catch((err) => {
            toast({
                title: "Erreur",
                description: "Une erreur est survenue lors de l'arrêt de l'élection",
                status: "error",
                duration: 6000,
                isClosable: true,
                position: "top-right"
            });
        });
    }

    return <>
        {stopElectionModal(stopElectionDisclosure.isOpen, stopElectionDisclosure.onClose, stop_election)}
        <Box width={"100%"} height={"400px"} bgColor={"white"} p={4} pb={5} border={"1pt solid #e2e2dd"}>
        <Text pb={2} fontWeight={700}>Nombres actuels de votes</Text>
        {!loading && <>
            {!chartRendered && <Skeleton height={"100%"} /> }
            <ResponsiveContainer width="100%" height="95%" onResize={() => {setChartRendered(true)}}>
                <BarChart width={150} height={40} data={votesPerCandidate}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis dataKey="votes"/>
                    <Bar dataKey="votes" fill="#004851" />
                </BarChart>
            </ResponsiveContainer>
        </>}
        {loading &&
            <Skeleton height={"80%"} />
        }
        </Box>

        <Text mt={5} fontWeight={700}>Fin de l'élection prévue le {formatDate(election.deadline, "dd/MM/yyyy 'à' HH:mm")}
        </Text>

        <Flex>
            <Spacer />
            <Button 
                width={"250px"}
                isDisabled={electionStopped}
                onClick={stopElectionDisclosure.onOpen}
                p={6} ps={8} pe={8} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={8} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}>
                TERMINER L'ÉLECTION EN AVANCE
            </Button>
        </Flex>
    </>
}

export default OngoingElection;