import { useEffect, useState } from 'react';
import '../../Home.css';
import { Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Button, Checkbox, Flex, Skeleton, Spacer, Table, Tooltip, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useToast, Box, ModalOverlay, ModalContent, ModalHeader, Divider, Modal, ModalCloseButton, ModalBody, useDisclosure } from '@chakra-ui/react';
import { IArticle } from '../../types';
import { deleteArticle, getArticles } from '../../API/news';
import { formatDate } from 'date-fns';
import { faFilePen, faPenNib, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditArticle from './EditArticle';


export function DeleteArticleModal (isOpen:boolean, onClose:()=>void, confirm_callback:Function) {
    return <Modal
    onClose={onClose} 
    size={"3xl"} 
    isOpen={isOpen}
    motionPreset='scale'
    closeOnOverlayClick={true}
    >
        <ModalOverlay backdropFilter='blur(5px)' />
        <ModalContent>
            <ModalHeader>
            <Text fontWeight={'900'} color={"#314e89"} fontSize={'xx-large'} letterSpacing={"-.1rem"}>Supprimer un article</Text>
            <Divider mt={3} />
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody mt={0} ps={8} pe={8} pb={8} pt={0}>
                Voulez-vous vraiment supprimer cet article ?
                
                <Flex mt={5}>
                    <Spacer />
                    <Button 
                        p={6} color={"white"} _hover={{backgroundColor:"#b2b0a5"}} bgColor={"#8c8b82"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                        onClick={() => onClose()}
                        >
                        NON
                    </Button>
                    <Button
                        ms={3}
                        p={6} color={"white"} _hover={{backgroundColor:"#d92a2e"}} bgColor={"#b32326"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                        onClick={() => confirm_callback()}
                        >
                        OUI
                    </Button>
                </Flex>
            </ModalBody>
        </ModalContent>
    </Modal>;
}

export interface BlogProps {}

const Blog: React.FC<BlogProps> = ({}) => {
    const [articles, setArticles] = useState<IArticle[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [editing, setEditing] = useState<boolean>(false);
    const [editedArticle, setEditedArticle] = useState<string | null>(null);
    const [deletedArticle, setDeletedArticle] = useState<string>("");
    const toast = useToast();
    const delete_disclosure = useDisclosure();

    useEffect(() => {
        getArticles(0, 0).then((res) => {
            setArticles(res.data.articles);
        }).catch((err) => {
            toast({
                title: "Erreur",
                description: "Impossible de charger les articles.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const exit_callback = () => {
        setEditing(false);
        setEditedArticle(null);
        getArticles(0, 0).then((res) => {
            setArticles(res.data.articles);
        }).catch((err) => {
            toast({
                title: "Erreur",
                description: "Impossible de charger les articles.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    const confirm_delete = () => {
        deleteArticle(deletedArticle).then((res) => {
            toast({
                title: "Succès",
                description: "L'article a bien été supprimé.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            setArticles(articles.filter((article) => article._id !== deletedArticle));
            delete_disclosure.onClose();
        }).catch((err) => {
            console.error(err);
            toast({
                title: "Erreur",
                description: "Impossible de supprimer l'article.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }).finally(() => {
            setDeletedArticle("");
        });
    }

    return (
    <Box mt={5}>
        {DeleteArticleModal(delete_disclosure.isOpen, delete_disclosure.onClose, confirm_delete)}
        {editing &&
            <EditArticle article_id={editedArticle} exit_callback={exit_callback} />
        }
        {!editing && <>
        <TableContainer bgColor={"white"} border={"1pt solid #e2e2dd"} p={5}>
            <Table variant='simple' size='sm'>
                <Thead>
                    <Tr>
                        <Th>Titre</Th>
                        <Th>Auteur·trice</Th>
                        <Th>Date de publication</Th>
                        <Th isNumeric></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {articles.map((article, index) => (
                        <Tr key={index}>
                            <Td>{article.title}</Td>
                            <Td>{article.author}</Td>
                            <Td>{formatDate(article.createdAt, "dd/MM/yyyy 'à' HH:mm")}</Td>
                            <Td isNumeric>
                                <Tooltip hasArrow label="Modifier" aria-label="Modifier">
                                    <Button onClick={() => {setEditedArticle(article._id); setEditing(true);}} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size='sm'><FontAwesomeIcon icon={faFilePen} /></Button>
                                </Tooltip>
                                <Tooltip hasArrow label="Supprimer" aria-label="Supprimer">
                                    <Button
                                        onClick={() => {setDeletedArticle(article._id); delete_disclosure.onOpen();}}
                                        ms={2} size='sm' colorScheme='red'><FontAwesomeIcon icon={faTrash} /></Button>
                                </Tooltip>
                            </Td>
                        </Tr>
                    ))}
                    {!loading && articles.length === 0 && (
                        <Tr>
                            <Td colSpan={3}>Aucun article.</Td>
                        </Tr>
                    )} 
                    {loading && 
                    [1, 2, 3, 4].map((index) => (
                        <Tr key={index}>
                            <Td>
                                <Skeleton height={"15px"} 
                                    width={Math.floor(Math.random() * 80) + 120 + "px"} />
                            </Td>
                            <Td>
                                <Skeleton height={"15px"} 
                                    width={Math.floor(Math.random() * 30) + 70 + "px"} />
                            </Td>
                            <Td>
                                <Skeleton height={"15px"} 
                                    width={Math.floor(Math.random() * 80) + 120 + "px"} />
                            </Td>
                            <Td />
                        </Tr>))
                    }
                </Tbody>
            </Table>
        </TableContainer>

        <Flex mt={5} me={5}>
            <Spacer />
            <Button 
                // onClick={search_disclosure.onOpen}
                isDisabled={loading}
                p={6} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                rightIcon={<FontAwesomeIcon icon={faPenNib} />}
                onClick={() => setEditing(true)}
                >
                RÉDIGER UN ARTICLE
            </Button>
        </Flex>
        </>}
    </Box>);
}

export default Blog;