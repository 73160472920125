import { AxiosResponse } from "axios";
import API, { API_URL, ApiDataType } from "./api";


/**
 * Gets users that are not in the whitelist nor the blacklist
 */
export const getAccessRequests = async (offset:number=0, limit:number=0): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/users/access-requests?offset=${offset}&limit=${limit}`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getAcceptedUsers = async (offset:number=0, limit:number=0): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/users/accepted?offset=${offset}&limit=${limit}`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getRefusedUsers = async (offset:number=0, limit:number=0): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/users/refused?offset=${offset}&limit=${limit}`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getAdmins = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/users/admins`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const removeAdmin = async (matricule: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.delete(
            `${API_URL}/users/admins/${matricule}`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const addAdmin = async (matricule: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/users/admins/${matricule}`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

/**
 * Accepts a user in the whitelist
 * @param matricule
 */
export const acceptUsers = async (matricules: string[]): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/users/accept`, {matricules: matricules}
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const askAccess = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/users/ask-access`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getAccessStatus = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/users/access-status`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

/**
 * Refuses a user in the blacklist
 * @param matricule
 */
export const refuseUsers = async (matricules: string[]): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/users/refuse`, {matricules: matricules}
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const setProfilePicture = async (id: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.put(
            `${API_URL}/users/pp/${id}`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}

export const getTutoStatus = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const resp: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/users/tuto-status`
        );
        return resp;
    } catch (error) {
        throw error;
    }
}