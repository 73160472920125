import { L10n } from '@syncfusion/ej2-base';
// Rich text editor
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Table, Toolbar, ToolbarType } from '@syncfusion/ej2-react-richtexteditor';
import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Spacer, Text } from '@chakra-ui/react';
import './texteditor.css';
import { API_URL } from '../API/api';

export interface TextEditorProps {
    content?: string;
    disabled?: boolean;
    maxLength?: number;
    update_callback: Function;
    seed?: number;
    withImage?: boolean;
}

const TextEditor: React.FC<TextEditorProps> = ({content, update_callback, maxLength=0, disabled, seed, withImage=true}) => {
    L10n.load({
        'fr': {
        'richtexteditor': {
            alignments: 'Alignement',
            justifyLeft: 'Gauche',
            justifyCenter: 'Centre',
            justifyRight: 'Droite',
            justifyFull: 'Justifié',
            fontName: 'Nom de la police',
            fontSize: 'Taille de la police',
            fontColor: 'Couleur de la police',
            backgroundColor: 'Couleur de fond',
            bold: 'GRAS',
            italic: 'Italique',
            underline: 'Souligné',
            strikethrough: 'Barré',
            clearFormat: 'Effacer le formatage',
            clearAll: 'Tout effacer',
            cut: 'Couper',
            copy: 'Copier',
            paste: 'Coller',
            unorderedList: 'Liste à puces',
            orderedList: 'Liste numérotée',
            indent: 'Augmenter le retrait',
            outdent: 'Diminuer le retrait',
            undo: 'Annuler',
            redo: 'Rétablir',
            superscript: 'Exposant',
            subscript: 'Indice',
            createLink: 'Insérer un lien',
            openLink: 'Ouvrir un lien',
            editLink: 'Modifier le lien',
            removeLink: 'Supprimer le lien',
            image: 'Insérer une image',
            replace: 'Remplacer',
            align: 'Aligner',
            caption: 'Légende de l\'image',
            remove: 'Supprimer',
            insertLink: 'Insérer un lien',
            display: 'Affichage',
            altText: 'Texte alternatif',
            dimension: 'Modifier la taille',
            fullscreen: 'Plein écran',
            maximize: 'Maximiser',
            minimize: 'Minimiser',
            lowerCase: 'Minuscules',
            upperCase: 'Majuscules',
            print: 'Imprimer',
            formats: 'Formats',
            sourcecode: 'Afficher le code',
            preview: 'Aperçu',
            viewside: 'Afficher le côté',
            insertCode: 'Insérer du code',
            linkText: 'Texte à afficher',
            linkTooltipLabel: 'Titre',
            linkWebUrl: 'Adresse web',
            linkTitle: 'Titre',
            linkurl: 'http://example.com',
            linkOpenInNewWindow: 'Ouvrir dans une nouvelle fenêtre',
            linkHeader: 'Insérer un lien',
            dialogInsert: 'Insérer',
            dialogCancel: 'Annuler',
            dialogUpdate: 'Mettre à jour',
            imageHeader: 'Insérer une image',
            imageLinkHeader: 'Vous pouvez également fournir un lien depuis le web',
            mdimageLink: 'Veuillez fournir une URL pour votre image',
            imageUploadMessage: 'Déposez l\'image ici ou parcourez pour télécharger',
            imageDeviceUploadMessage: 'Cliquez ici pour télécharger',
            imageAlternateText: 'Texte alternatif',
            alternateHeader: 'Texte alternatif',
            browse: 'Parcourir',
            imageUrl: 'http://example.com/image.png',
            imageCaption: 'Légende',
            imageSizeHeader: 'Taille de l\'image',
            imageHeight: 'Hauteur',
            imageWidth: 'Largeur',
            textPlaceholder: 'Saisir du texte',
            inserttablebtn: 'Insérer un tableau',
            tabledialogHeader: 'Insérer un tableau',
            tableWidth: 'Largeur',
            cellpadding: 'Marge intérieure',
            cellspacing: 'Espacement des cellules',
            columns: 'Nombre de colonnes',
            rows: 'Nombre de lignes',
            tableRows: 'Lignes du tableau',
            tableColumns: 'Colonnes du tableau',
            tableCellHorizontalAlign: 'Alignement horizontal de la cellule',
            tableCellVerticalAlign: 'Alignement vertical de la cellule',
            createTable: 'Créer un tableau',
            removeTable: 'Supprimer le tableau',
            tableHeader: 'En-tête du tableau',
            tableRemove: 'Supprimer le tableau',
            tableCellBackground: 'Couleur de fond de la cellule',
            tableEditProperties: 'Modifier les propriétés du tableau',
            styles: 'Styles',
            insertColumnLeft: 'Insérer une colonne à gauche',
            insertColumnRight: 'Insérer une colonne à droite',
            deleteColumn: 'Supprimer la colonne',
            insertRowBefore: 'Insérer une ligne avant',
            insertRowAfter: 'Insérer une ligne après',
            deleteRow: 'Supprimer la ligne',
            tableEditHeader: 'Modifier le tableau',
            TableHeadingText: 'En-tête',
            TableColText: 'Colonne',
            imageInsertLinkHeader: 'Insérer un lien',
            editImageHeader: 'Modifier l\'image',
            alignmentsDropDownLeft: 'Gauche',
            alignmentsDropDownCenter: 'Centre',
            alignmentsDropDownRight: 'Droite',
            alignmentsDropDownJustify: 'Justifié',
            imageDisplayDropDownInline: 'Inline',
            imageDisplayDropDownBreak: 'Saut de ligne',
            tableInsertRowDropDownBefore: 'Insérer une ligne avant',
            tableInsertRowDropDownAfter: 'Insérer une ligne après',
            tableInsertRowDropDownDelete: 'Supprimer la ligne',
            tableVerticalAlignDropDownTop: 'Aligner en haut',
            tableVerticalAlignDropDownMiddle: 'Aligner au centre',
            tableVerticalAlignDropDownBottom: 'Aligner en bas',
            tableStylesDropDownDashedBorder: 'Bordure en pointillés',
            tableStylesDropDownAlternateRows: 'Lignes alternées',
            pasteFormat: 'Coller le format',
            pasteFormatContent: 'Choisissez l\'action de formatage',
            plainText: 'Texte brut',
            cleanFormat: 'Nettoyer',
            keepFormat: 'Conserver',
            pasteDialogOk: 'OK',
            pasteDialogCancel: 'Annuler',
            fileManager: 'Gestionnaire de fichiers',
            fileDialogHeader: 'Navigateur de fichiers',
            formatsDropDownParagraph: 'Paragraphe',
            formatsDropDownCode: 'Code',
            formatsDropDownQuotation: 'Citation',
            formatsDropDownHeading1: 'Titre 1',
            formatsDropDownHeading2: 'Titre 2',
            formatsDropDownHeading3: 'Titre 3',
            formatsDropDownHeading4: 'Titre 4',
            fontNameSegoeUI: 'Segoe UI',
            fontNameArial: 'Arial',
            fontNameGeorgia: 'Georgia',
            fontNameImpact: 'Impact',
            fontNameTahoma: 'Tahoma',
            fontNameTimesNewRoman: 'Times New Roman',
            fontNameVerdana: 'Verdana',
            numberFormatListNumber: 'Numérotation',
            numberFormatListLowerAlpha: 'Minuscules alphabétiques',
            numberFormatListUpperAlpha: 'Majuscules alphabétiques',
            numberFormatListLowerRoman: 'Chiffres romains en minuscules',
            numberFormatListUpperRoman: 'Chiffres romains en majuscules',
            numberFormatListLowerGreek: 'Minuscules grecques',
            bulletFormatListDisc: 'Disque',
            bulletFormatListCircle: 'Cercle',
            bulletFormatListSquare: 'Carré',
            numberFormatListNone: 'Aucun',
            bulletFormatListNone: 'Aucun'
        }
    }
    });
    let items = ['Bold', 'Italic' ,'Underline', 'StrikeThrough', 'FontColor', 'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent',
        'CreateLink', 'Image', '|', 'ClearFormat'
        , '|', 'Undo', 'Redo'
    ];
    if (!withImage) {
        items.splice(items.indexOf('Image'), 1);
    }
    const [characters, setCharacters] = React.useState<number>(0);

    const update_callback_wrapper = (e:any) => {
        if (e == null)
            e = "";
        update_callback(e);

        // Set the length of the text without html tags
        let tmp = document.createElement("DIV");
        tmp.innerHTML = e;
        let e2 = tmp.textContent || tmp.innerText || "";
        setCharacters(e2.length);
    }

    const editorRef = React.useRef<RichTextEditorComponent>(null);

    return (<Box>
        <RichTextEditorComponent
                locale="fr"
                key={seed}
                ref={editorRef}
                value={content || ''}
                maxLength={maxLength > 0 ? maxLength : undefined}
                readonly={disabled}
                change={(e:any) => update_callback_wrapper(e.value)}
                autoSaveOnIdle={true} 
                saveInterval={100}
                fontSize={{default:'16px', width:'70px',items: [{text:'Petit', value:'13px'}, {text:'Normal', value:'15px'}]}}
                toolbarSettings={{
                    items: items,
                    type: ToolbarType.Expand
                }}
                quickToolbarSettings={{
                    image: [
                        'Replace', 'Align', 'Caption', 'Remove', 'InsertLink', 'OpenImageLink', '-', 'EditImageLink', 'RemoveImageLink', 'Display', 'AltText', 'Dimension'
                    ]
                }}
                insertImageSettings={{
                    saveFormat: 'Base64',
                }}
                
                >
            <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
        </RichTextEditorComponent>

        <Flex>
            <Text color={"gray.500"} fontWeight={400} fontSize={'xs'} ms={1} mt={1} >{maxLength > 0 ? "Max."+maxLength+" caractères - " : ""}Note : pour éviter de coller du texte avec son formatage, clic droit - coller sans formatage</Text>
            <Spacer />
            {characters > (maxLength-100) && maxLength > 0 ? <Text color={"red.500"} fontWeight={600} fontSize={'xs'} ms={1} mt={1} >({characters}/{maxLength})</Text> : null
            }
        </Flex>
    </Box>);
}

export default TextEditor;