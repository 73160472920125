export interface IUser {
    matricule: string;
    rank: string;
    firstname: string;
    lastname: string;
    email: string;
    picture: string;
    whitelist?: boolean;
    blacklist?: boolean;
    manual?: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export interface IElection {
    _id: string;
    open: boolean; // If it should be displayed
    startline: Date;
    deadline: Date;
    candidates: IElectionCandidate[];
    posted: boolean;
    posted_end: Date;
    createdAt: Date;
    updatedAt: Date;
}

export interface IElectionCandidate {
    _id: string;
    user : IUser;
    application: string; // HTML
    votes_number?: number;
    createdAt: Date;
    updatedAt: Date;
}

export interface IElectionVote {
    _id: string;
    user : string; // matricule
    election: string;
    choices: ICandidateChoice[];
    submitted: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export interface ICandidateChoice {
    candidate: string; // ElectionCandidate _id
    points: number;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface IArticle {
	_id: string;
    author: string;
    title: string;
    slug: string;
    category: string;
    picture: string;
    content: string;
    views: string[];
    createdAt: Date;
    updatedAt: Date;
}

export interface IDossier {
	_id: string;
    author: string;
    author_name: string;
    title: string;
    slug: string;
    tags: string[];
    deadline: Date;
    category: string;
    content: string;
    attached_files: {id:string, name:string}[];
    polls: string[];
    threads: string[];
    views: string[];
    views_count?: number;
    high_priority: boolean;
    bureau: boolean;
    approved: boolean;
    user_seen?: boolean;
    defaultOpen?: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export enum PollType {
    SingleChoice = "SingleChoice",
    Sort = "Sort",
    MultiChoice = "MultiChoice"
}
export const pollTypeToString = (type: PollType) => {
    switch (type) {
        case PollType.SingleChoice:
            return "Choix unique";
        case PollType.Sort:
            return "Classement";
        case PollType.MultiChoice:
            return "Choix multiple";
    }
}

export interface IPoll {
    _id: string;
    author: string;
    title: string;
    type: PollType;
    description: string;
    options: string[];
    deadline: Date;
    votes_count?: number;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface IPollVote {
    _id: string;
    poll: string;
    user: string;
    option: any;
    createdAt: Date;
}

export interface IThread {
    _id: string;
    author: string;
    title: string;
    dossier_id?: string;
    dossier_slug?: string;
    category: string;
    posts: string[];
    posts_count?: number;
    views: string[];
    views_count?: number;
    defaultOpen?: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export interface IThreadPost {
    _id: string;
    author: string;
    author_name?: string;
    author_picture?: string;
    content: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface ICategory {
    _id: string;
    name: string;
    description: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export enum RequestStatus {
    Pending = "Pending",
    Accepted = "Accepted",
    Refused = "Refused"
}

export interface IDossierRequest {
    _id: string;
    author: string;
    author_mail: string;
    title: string;
    tags: string[];
    categories: string[];
    description: string;
    deadline: Date|null;
    high_priority: boolean;
    priority_reason: string;
    polls: IPoll[];
    draft: boolean;
    status: RequestStatus;
    status_reason?: string;
    status_date?: Date;
    manager?: string;
    attached_files: any[];
    createdAt?: Date;
    updatedAt?: Date;
}

export enum NewsFeedType {
    Article = "Article", // Article slug
    Dossier = "Dossier", // Dossier _id
    Thread = "Thread", // Thread _id
    Poll = "Poll", // Dossier _id
    ThreadPost = "ThreadPost", // Thread _id
}

export interface INewsFeed {
    _id: string;
    user: string;
    profile_pic?: string;
    event_type: NewsFeedType;
    event_id: string;
    event:any;
    likes: number;
    user_liked: boolean;
    createdAt?: Date;
    updatedAt?: Date;
}

export enum NotificationType {
    DossierAccepted = "DossierAccepted",
    DossierRefused = "DossierRefused",
}

export interface INotification {
    _id: string;
    matricule: string;
    event_type: NotificationType;
    event_id: string;
    content: string;
    event?: any;
    seen: boolean;
    createdAt: Date;
    updatedAt?: Date;
}

export interface IContactForm {
    _id: string;
    matricule: string;
    author?: string;
    archived: boolean;
    subject: string;
    content: string;
    attached_files: any[];
    draft: boolean;
    createdAt: Date;
    updatedAt?: Date;
}