import { Button, Center, Checkbox, Flex, Skeleton, Spacer, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useToast } from '@chakra-ui/react';
import '../../Home.css';
import { IUser } from '../../types';
import { useEffect, useState } from 'react';
import { getAcceptedUsers, refuseUsers } from '../../API/users';
import { faUserXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../../components/Pagination';

export interface WhitelistProps {}

const Whitelist: React.FC<WhitelistProps> = ({}) => {
    const [users, setUsers] = useState<IUser[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const toast = useToast();
    const MAX_USERS_PER_PAGE = 20;

    useEffect(() => {
        getAcceptedUsers(0, MAX_USERS_PER_PAGE).then((res) => {
            setUsers(res.data.users);
            setTotalUsers(res.data.count);
        }).catch((error) => {
            toast({
                title: "Erreur",
                description: "Erreur lors de la récupération des utilisateurs",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const handleCheckboxChange = (userId: string) => {
        if (selectedUsers.includes(userId)) {
            setSelectedUsers(selectedUsers.filter((id) => id !== userId));
        } else {
            setSelectedUsers([...selectedUsers, userId]);
        }
    };

    const handleRefuseUsers = () => {
        refuseUsers(selectedUsers).then((res) => {
            setUsers(users.filter((user) => !selectedUsers.includes(user.matricule)));
        }).catch((error) => {
            toast({
                title: "Erreur",
                description: "Erreur lors du refus d'un utilisateur",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        });
        setSelectedUsers([]);
    };

    const [totalUsers, setTotalUsers] = useState<number>(0);
    const page_change = (selectedItem: { selected: number }) => {
        const page:number = selectedItem.selected;

        getAcceptedUsers(page*MAX_USERS_PER_PAGE, MAX_USERS_PER_PAGE).then((res) => {
            setUsers(res.data.users);
            setTotalUsers(res.data.count);
        }).catch((error) => {
            toast({
                title: "Erreur",
                description: "Erreur lors de la récupération des demandes d'accès",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <Text p={5} pt={0}>
                La Whitelist est une liste d'utilisateurs autorisés à accéder au site web. Les utilisateurs qui ne sont pas dans la whitelist doivent demander l'accès.
            </Text>
            <TableContainer bgColor={"white"} border={"1pt solid #e2e2dd"} p={5}>
                <Table variant='simple' size='sm'>
                    <Thead>
                        <Tr>
                            <Th>Nom</Th>
                            <Th>Prénom</Th>
                            <Th>E-mail</Th>
                            <Th>S'est déjà connecté</Th>
                            <Th isNumeric></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {users.map((user, index) => (
                            <Tr key={index}>
                                <Td>{user.lastname}</Td>
                                <Td>{user.firstname}</Td>
                                <Td>{user.email}</Td>
                                <Td>{user.manual ? "Non" : "Oui"}</Td>
                                <Td isNumeric>
                                    <Checkbox
                                        isChecked={selectedUsers.includes(user.matricule)}
                                        onChange={() => handleCheckboxChange(user.matricule)}
                                    />
                                </Td>
                            </Tr>
                        ))}
                        {!loading && users.length === 0 && (
                            <Tr>
                                <Td colSpan={3}>Aucun utilisateur.</Td>
                            </Tr>
                        )} 
                        {loading && 
                        [1, 2, 3, 4].map((index) => (
                            <Tr key={index}>
                                <Td><Skeleton height={"15px"} 
                                    width={
                                    Math.floor(Math.random() * 30) + 70 + "px"} /></Td>
                                <Td><Skeleton height={"15px"} 
                                    width={
                                    Math.floor(Math.random() * 80) + 120 + "px"} /></Td>
                                <Td></Td>
                            </Tr>))
                        }
                    </Tbody>
                    <Tfoot>
                        <Tr>
                            <Th></Th>
                            <Th></Th>
                            <Th></Th>
                            <Th isNumeric pt={3}>
                                Tout sélectionner
                                <Checkbox
                                    ms={3}
                                    isChecked={selectedUsers.length === users.length && users.length > 0}
                                    onChange={() => {
                                        if (selectedUsers.length === users.length) {
                                            setSelectedUsers([]);
                                        } else {
                                            setSelectedUsers(users.map((user) => user.matricule));
                                        }
                                    }}
                                />
                            </Th>
                        </Tr>
                    </Tfoot>
                </Table>
            </TableContainer>

            
            {totalUsers > MAX_USERS_PER_PAGE && <Center>
                <Pagination itemsPerPage={MAX_USERS_PER_PAGE} page_change_callback={page_change} totalCount={totalUsers}/>
            </Center>}

            <Flex mt={5} me={5}>
                <Spacer />
                <Button
                    p={6}
                    color={"white"}
                    _hover={{ backgroundColor: "#d92a2e" }}
                    bgColor={"#b32326"}
                    size="md"
                    fontSize={'10pt'}
                    fontWeight={'700'}
                    letterSpacing={'.8px'}
                    onClick={handleRefuseUsers}
                    isDisabled={loading || selectedUsers.length === 0}
                    rightIcon={<FontAwesomeIcon icon={faUserXmark} />}
                >
                    PLACER EN BLACKLIST
                </Button>
            </Flex>
        </>
    );
};

export default Whitelist;