import React, { useEffect } from 'react';
import '../Home.css';
import { Box, Button, Center, Flex, Skeleton, Spacer, Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faEye } from '@fortawesome/free-solid-svg-icons';
import { Thread } from './Thread';
import { getThreadById, getThreadsFromCategory, getThreadsFromDossier } from '../API/thread';
import { IDossier, IThread, ICategory } from '../types';
import { NewThread } from './NewThread';
import { useLocation, useParams } from 'react-router-dom';
import Pagination from '../components/Pagination';

export interface MainThreadsProps {
  category?: ICategory|null;
  categories?: ICategory[];
}

const MainThreads: React.FC<MainThreadsProps> = ({category, categories}) => {
  const thread_disclosure = useDisclosure();
  const new_thread_disclosure = useDisclosure();
  const [threads, setThreads] = React.useState<IThread[]>([]);
  const [currentThread, setCurrentThread] = React.useState<IThread|null>(null);
  const [locked, setLocked] = React.useState<boolean>(false);
  const [fetched, setFetched] = React.useState<boolean>(false);
  const MAX_THREADS_PER_PAGE = 10;
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [threadsCount, setThreadsCount] = React.useState<number>(0);
  const [showPagination, setShowPagination] = React.useState<boolean>(false);

  const page_change = (selectedItem: { selected: number }) => {
    const page:number = selectedItem.selected;
    setCurrentPage(page);
    getThreadsFromCategory(category?._id || "", page*MAX_THREADS_PER_PAGE, MAX_THREADS_PER_PAGE, true).then((resp) => {
      setThreadsCount(resp.data.count);
      if (resp.data.count > MAX_THREADS_PER_PAGE) {
        setShowPagination(true);
      }
      setThreads(resp.data.threads);
      setFetched(true);
    });
  }

  const params = useParams();
  const threadId = params.threadId;

  useEffect(() => {
    if (fetched && threadId) {
      getThreadById(threadId).then((resp) => {
        const openedThread = resp.data.thread;
        openedThread.defaultOpen = true;
        // If it is not in the list, add it, otherwise, update it
        const index = threads.findIndex((t) => t._id === openedThread._id);
        if (index !== -1) {
          threads[index] = openedThread;
          setThreads([...threads]);
        } else {
          setThreads([openedThread, ...threads]);
        }
        setCurrentThread(openedThread);
        thread_disclosure.onOpen();
      }).catch((err) => {});
    }
  }, [threadId, fetched]);

  useEffect(() => {
    setFetched(false);
    getThreadsFromCategory(category?._id || "", 0, MAX_THREADS_PER_PAGE, true).then((resp) => {
      setThreadsCount(resp.data.count);
      if (resp.data.count > MAX_THREADS_PER_PAGE) {
        setShowPagination(true);
      }
      setThreads(resp.data.threads);
      setFetched(true);
    });
  }, [category]);

  const create_thread_callback = (thread:IThread) => {
    setThreads([...threads, thread]);
  }

  const activity = (thread: IThread) => {
    const updated_at = new Date(thread.updatedAt);
    const now = new Date();

    const diffInMinutes = Math.floor((now.getTime() - updated_at.getTime()) / (1000 * 60));
    if (diffInMinutes < 60) {
      return `${diffInMinutes} minutes`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `${diffInHours} heures`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) {
      return `${diffInDays} jours`;
    }

    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 4) {
      return `${diffInWeeks} semaines`;
    }

    return `${diffInWeeks} semaines`;
  }

  const thread_updated_callback = () => {
    getThreadsFromCategory(category?._id || "", currentPage*MAX_THREADS_PER_PAGE, MAX_THREADS_PER_PAGE, true).then((resp) => {
      setThreads(resp.data.threads);
      setThreadsCount(resp.data.count);
      if (resp.data.count > MAX_THREADS_PER_PAGE) {
        setShowPagination(true);
      }
    });
  }

  const handleOpenThread = (thread:IThread) => {
    setCurrentThread(thread); 
    thread_disclosure.onOpen();
    // change the URL to add the thread id at the end
    if (window.location.pathname.includes("/forum"))
      window.history.pushState({}, "", `/forum/${thread._id}`);
  }

  const handleClosedThread = () => {
    // Filter the list of threads to set them all as defaultOpen false
    setThreads(threads.map((thread) => {
      thread.defaultOpen = false;
      return thread;
    }));
    setCurrentThread(null);
    thread_disclosure.onClose();
    
    if (window.location.pathname.includes("/forum")) {
      window.history.pushState({}, "", "/forum");
    }
  }

  return (<>
        <TableContainer border={"1pt solid #e2e2dd"} boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} bgColor={"white"}>
          <Table colorScheme='blackAlpha'>
            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
            <Thead>
              <Tr>
                <Th>Sujet</Th>
                <Th isNumeric>Réponses</Th>
                <Th isNumeric><FontAwesomeIcon icon={faEye} /></Th>
                <Th isNumeric>Activité</Th>
              </Tr>
            </Thead>
            <Tbody>
              {fetched && categories?.map((cat:ICategory, index) => (
              <>
                {threads.filter((thread) => thread.category === cat.name).length > 0 && <>
                    <Tr key={index} bgColor={"#e3e3e3"} fontWeight={"700"} fontSize={"lg"} color={"#707070"}>
                      <Td colSpan={4}>{cat.name}</Td>
                    </Tr>
                    {threads.filter((thread) => thread.category === cat.name).map((thread, index) => (
                      <Tr key={index} cursor={"pointer"} onClick={() => handleOpenThread(thread)}>
                        <Td>{thread.title.length > 80 ? `${thread.title.slice(0, 80)}...` : thread.title}</Td>
                        <Td isNumeric>{thread.posts_count}</Td>
                        <Td isNumeric>{thread.views_count}</Td>
                        <Td isNumeric>Il y a {activity(thread)}</Td>
                      </Tr>))}
                </>}
              </>))}

              {!fetched && [1, 2, 3, 4].map((_, index) => (
                <Tr key={index}>
                  <Td>
                    <Skeleton height={"15px"} width={Math.floor(Math.random() * (200 - 100 + 1)) + 100 + "px"}/>
                  </Td>
                  <Td>
                    <Skeleton height={"15px"} width={Math.floor(Math.random() * (40 - 10 + 1)) + 10 + "px"}/>
                  </Td>
                  <Td>
                    <Skeleton height={"15px"} width={Math.floor(Math.random() * (40 - 10 + 1)) + 10 + "px"}/>
                  </Td>
                  <Td>
                    <Skeleton height={"15px"} width={Math.floor(Math.random() * (200 - 100 + 1)) + 100 + "px"}/>
                  </Td>
                </Tr>
              ))}
              {fetched && threads.length === 0 && <Tr><Td colSpan={5}>Discussion vide</Td></Tr> }
            </Tbody>
            <Tfoot>
              <Tr>
                <Th>Page {currentPage+1}</Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>

        {showPagination &&
          <Center mt={5}>
            <Pagination itemsPerPage={MAX_THREADS_PER_PAGE} page_change_callback={page_change} totalCount={threadsCount} />
          </Center>
        }
        
        <Flex>
          <Spacer />
          <Button isDisabled={locked} onClick={new_thread_disclosure.onOpen} variant={'outline'} rounded={0} colorScheme={'blackAlpha'} mt={5} rightIcon={<FontAwesomeIcon icon={faComment} />}>Ouvrir un sujet</Button>
        </Flex>
        {Thread(thread_disclosure.isOpen, thread_disclosure.onOpen, handleClosedThread, currentThread, null , thread_updated_callback, locked)}

        {NewThread(new_thread_disclosure.isOpen, new_thread_disclosure.onOpen, new_thread_disclosure.onClose, create_thread_callback, null, category, categories)}
        </>);
}

export default MainThreads;