import { Box, Flex, Spacer, Text, useDisclosure } from "@chakra-ui/react";
import { faBriefcase, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IDossier } from "../types";
import { differenceInDays, differenceInHours, differenceInMinutes, formatDate } from 'date-fns';
import { fr } from 'date-fns/locale';
import { format } from "path";
import { Dossier } from "./Dossier";
import { DOSSIER_COLORS } from "../colors";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { useEffect, useState } from "react";

export interface DossierCardProps {
    dossier:IDossier;
    defaultOpen?:boolean;
    fake?:boolean;
}

const DossierCard: React.FC<DossierCardProps> = ({dossier, defaultOpen, fake}) => {
    const folder_disclosure = useDisclosure();
    const color = () => {
        // If deadline is in less than 3 days, return red
        // If deadline is in less than 7 days, return orange
        // Else return green
        // If its in the past, return gray
        if (differenceInMinutes(dossier.deadline, new Date()) < 0) return DOSSIER_COLORS.gray;
        if (differenceInDays(dossier.deadline, new Date()) < 3) {
            return DOSSIER_COLORS.red;
        } else if (differenceInDays(dossier.deadline, new Date()) < 7) {
            return DOSSIER_COLORS.orange;
        } else {
            return DOSSIER_COLORS.green;
        }
    }

    const deadlinePassed = () => {
        return differenceInMinutes(dossier.deadline, new Date()) < 0;
    }

    useEffect(() => {
      if (defaultOpen) {
        folder_disclosure.onOpen();
      }
    }, [defaultOpen]);

    const handleOpenDossier = () => {
      if (fake)
        return;
      folder_disclosure.onOpen();
      // Add the slug to the URL
     
      // check if the current URL contains "/dossiers"
      if (window.location.pathname.includes("/dossiers")) {
        window.history.pushState({}, '', `/dossiers/${dossier.slug}`);
      } else {
        window.history.pushState({}, '', `/archives/${dossier.slug}`);
      }
    }

    const close_dossier_wrapper = () => {
      folder_disclosure.onClose();
      if (window.location.pathname.includes("/dossiers")) {
        window.history.pushState({}, '', `/dossiers`);
      } else if (window.location.pathname.includes("/archives")) {
        window.history.pushState({}, '', `/archives`);
      }
    }

    return (<>
        <Box onClick={handleOpenDossier} boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} className='hvr-float' border={"1pt solid #e2e2dd"} bgColor={"white"} cursor={'pointer'} position={'relative'} minWidth={"300px"}>
          {!dossier.user_seen && (
            <Box bgColor={"#ba0105"} color={"white"} width={'fit-content'} pe={2} ps={2} pt={1} pb={1} fontSize={'xs'} fontWeight={'800'} border={"1pt solid #610002"} position={'absolute'} right={"-10px"} top={"-10px"}>
              NOUVEAU
            </Box>
          )}

          <Flex maxHeight={"150px"}>
            <Box minWidth={"35px"} maxWidth={"35px"} bgColor={color()[1]} minHeight={'100%'} maxHeight={'100%'}>
              <svg height="100%" width="100%">
                <polygon points="-10,0 150, 200 100,0" fill={color()[0]} />
              </svg>
            </Box>

            <Box p={4} width={"100%"} height={"100%"}>
                <Text letterSpacing={"-.01rem"} lineHeight={"1.2"} fontWeight={'800'} fontSize={'lg'} color={"#00707f"} >{dossier.title.length > 50 ? `${dossier.title.slice(0, 50)}...` : dossier.title}</Text>
                {dossier.bureau && (
                    <Text fontWeight={'400'} fontSize={'sm'} color={"#adb5bd"}><FontAwesomeIcon icon={faBriefcase} style={{marginRight:"5px"}} />Publié par le Bureau ADERE</Text>
                )}
                {!dossier.bureau && (
                  <Text fontWeight={'400'} fontSize={'sm'} color={"#adb5bd"}>Publié par {dossier.author_name}</Text>
                )}
              {/* <Box height={'35px'} /> */}

              <Flex mt={3} position={"absolute"} bottom={"15px"}>
                {!dossier.deadline && 
                  <Box width={"20px"} />
                }
                {dossier.deadline && !deadlinePassed() && differenceInDays(dossier.deadline, new Date()) > 0 && (
                    <Text fontSize={'sm'} color={"red.500"}><FontAwesomeIcon icon={faCalendar} style={{marginRight:"5px"}} />
                        Deadline: Dans {differenceInDays(dossier.deadline, new Date())} jour{differenceInDays(dossier.deadline, new Date()) > 1 ? "s" : ""}
                    </Text>
                )}
                {dossier.deadline && !deadlinePassed() && differenceInHours(dossier.deadline, new Date()) > 0 && differenceInDays(dossier.deadline, new Date()) == 0 && (
                    <Text fontSize={'sm'} color={"red.500"}><FontAwesomeIcon icon={faCalendar} style={{marginRight:"5px"}} />
                    Deadline: Dans {differenceInHours(dossier.deadline, new Date())} heure{differenceInHours(dossier.deadline, new Date()) > 1 ? "s" : ""}
                    </Text>
                )}
                {dossier.deadline && !deadlinePassed() && differenceInHours(dossier.deadline, new Date()) == 0 && (
                    <Text fontSize={'sm'} color={"red.500"}><FontAwesomeIcon icon={faCalendar} style={{marginRight:"5px"}} />
                    Deadline: Dans {differenceInMinutes(dossier.deadline, new Date())} minute{differenceInMinutes(dossier.deadline, new Date()) > 1 ? "s" : ""}
                    </Text>
                )}

                {/* If deadline is in the past, just display the deadline date */}
                {dossier.deadline && differenceInMinutes(dossier.deadline, new Date()) < 0 && (
                    <Text fontSize={'sm'} color={color()[1]}><FontAwesomeIcon icon={faCalendar} style={{marginRight:"5px"}} />
                    Clôturé le: {formatDate(dossier.deadline, 'dd/MM/yyyy', {locale: fr})}
                    </Text>
                )}
                <Spacer />
              </Flex>

              <Text 
                position={"absolute"}
                bottom={"15px"}
                right={"15px"}
                fontSize={'sm'} 
                color={"#adb5bd"}>
                  { // If fake, display a random number of views between 100 and 500
                    fake ? Math.floor(Math.random() * 400) + 100 : dossier.views_count} <FontAwesomeIcon icon={faEye} style={{marginLeft:"5px"}} />
                </Text>
            </Box>
          </Flex>
        </Box>

    {Dossier(folder_disclosure.isOpen, folder_disclosure.onOpen, close_dossier_wrapper, dossier._id)}
    </>)
}
export default DossierCard;