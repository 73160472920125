import { Box, Button, Center, Circle, Flex, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger, Spacer, Spinner, Text } from "@chakra-ui/react";
import { Image as ChakraImage } from "@chakra-ui/react";
import logo from './resources/adere_logo.svg';
import { useEffect, useState } from "react";
import { faBell, faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotificationsContent } from "./NotificationsContent";
import { INotification } from "./types";
import { getNotifications } from "./API/notifications";

export const Notifications: React.FunctionComponent = () => {
    const [unseen, setUnseen] = useState<boolean>(false);

    useEffect(() => {
        getNotifications().then((res) => {
            const notifs:INotification[] = res.data.notifications;
            // Check if there are unseen notifications
            let unseen = false;
            for (let i=0; i<notifs.length; i++) {
                if (!notifs[i].seen) {
                    unseen = true;
                    break;
                }
            }
            setUnseen(unseen);
        })
        .catch((err) => {})
        .finally(() => {});
    }, []);

return (<Popover isLazy>
    <PopoverTrigger>
        <Circle onClick={() => {setUnseen(false)}} cursor={'pointer'} me={3} className="hvr-grow" bgColor={"#f5f5f5"} p={3} color={"#adb5bd"}>
            {unseen && 
                <Circle 
                    bgColor={'#d30003'} 
                    minWidth={'10px'}
                    minHeight={'10px'}
                    position={'absolute'}
                    top={'0px'}
                    right={'0px'}
                />
            }
            <FontAwesomeIcon icon={faBell} />
        </Circle>
    </PopoverTrigger>
    <PopoverContent me={3} _focusVisible={{outline:"none"}} outline={"none"}>
        <PopoverHeader p={4} fontWeight='800'>Notifications</PopoverHeader>
        <PopoverArrow />
        <PopoverBody p={4}>
            <NotificationsContent />
        </PopoverBody>
    </PopoverContent>
</Popover>);
}