import { Box, Button, Center, Container, Divider, Flex, FormControl, FormLabel, Grid, Input, Progress, Spacer, Text, useToast } from "@chakra-ui/react";
import { createRef, useEffect, useState } from "react";
import { faPaperclip, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IContactForm } from "./types";
import { Layout } from "./Layout";
import TextEditor from "./components/TextEditor";
import { downloadFile, getFilenames, uploadFiles } from "./API/file";
import { getCurrentContactDraft, updateCurrentContactDraft } from "./API/contact";

export const Contact: React.FunctionComponent = () => {
    const ALLOWED_FORMATS = ["image/jpeg", "image/png", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "text/plain", "text/csv", "application/rtf", "text/html", "application/zip", "audio/mpeg", "audio/x-ms-wma", "audio/ogg", "audio/wav", "video/mp4", "video/quicktime", "video/x-ms-wmv", "video/avi", "video/mpeg", "video/3gpp", "video/3gpp2", "application/zip", "application/x-rar-compressed", "application/x-tar", "application/x-gzip", "application/x-7z-compressed", "application/x-zip-compressed"];
    const toast = useToast();
    const [files, setFiles] = useState<File[]>([]);
    const [filenames, setFilenames] = useState<string[]>([]);
    const [currentContent, setCurrentContent] = useState<IContactForm|null>(null);
    const [filesUploading, setFilesUploading] = useState<boolean>(false);
    const MAX_FILES_NB = 5;

    const subject = createRef<HTMLInputElement>();
    const [content, setContent] = useState<string>('');
    const [loaded, setLoaded] = useState<boolean>(false);
    const [sent, setSent] = useState<boolean>(false);

    useEffect(() => {
        getCurrentContactDraft().then((resp) => {
            const contactForm:IContactForm = resp.data.contactForm;
            setCurrentContent(contactForm);
            subject.current!.value = contactForm.subject;
            setContent(contactForm.content);
            setLoaded(true);
            fetch_filenames(contactForm.attached_files);
          });
    }, []);

    const fetch_filenames = async (ids:string[]) => {
        getFilenames(ids).then((resp) => {
          setFilenames(resp.data.filenames);
        });
    }
    
    const updateSubjectHandler = () => {
        if (subject.current?.value) {
            updateContactDraft('subject', subject.current.value);
        }
    }

    const updateContactDraft = (attribute: string, value: any, updateDb=true) => {
        setCurrentContent((prevContent) => {
          if (prevContent) {
            const updatedContent = { ...prevContent, [attribute]: value };
            if (updateDb) {
                updateCurrentContactDraft(updatedContent);
            }
            return updatedContent;
          }
          return null;
        });
    };

    const download_file = (filename:string) => {
        // Get the file ID
        const file_index = filenames.indexOf(filename);
        const file_id = currentContent?.attached_files[file_index];
        if (!file_id) return;
  
        downloadFile(file_id).then((resp) => {
          const contentDisposition = resp.headers['content-disposition'];
          const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        });
    }

    const removeFile = (filename:string) => {
        // Get the file ID from the currentContent (it is at the same index as the filename in the filenames array)
        const file_index = filenames.indexOf(filename);
        const file_id = currentContent?.attached_files[file_index];
        if (!file_id) return;
        // Update the draft
        updateContactDraft('attached_files', currentContent?.attached_files.filter((id) => id !== file_id));
        // Remove the file from the filenames array
        setFiles(files.filter((file) => file.name !== filename));
        setFilenames(filenames.filter((name) => name !== filename));
  
        toast({
          title: "Fichier supprimé",
          description: "Le fichier a bien été supprimé.",
          status: "info",
          duration: 5000,
          isClosable: true,
          position: "top-right",
          size: "md",
        });
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
          const new_files = Array.from(e.target.files);
          if (new_files.length > MAX_FILES_NB) {
            toast({
              title: "Erreur",
              description: "Vous ne pouvez pas ajouter plus de " + MAX_FILES_NB + " fichiers à la fois.",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-right",
              size: "md",
            });
            return;
          }
          setFilesUploading(true);
  
          // Upload files and get the IDs, then update the draft with the IDs
          const formData = new FormData();
          new_files.forEach((file, index) => {
            formData.append('files', file);
          });
          uploadFiles(formData).then((resp) => {
            if (resp.status === 201) {
              setFilesUploading(false);
              const ids = resp.data.ids;
              updateContactDraft('attached_files', [...currentContent?.attached_files || [], ...ids]);
              setFiles([...files, ...new_files]);
              setFilenames([...filenames, ...new_files.map((file) => file.name)]);
              toast({
                title: "Fichiers envoyés",
                description: "Les fichiers ont bien été envoyés.",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top-right",
                size: "md",
              });
            }
          }).catch((error) => {
            toast({
              title: "Erreur",
              description: "Une erreur est survenue lors de l'envoi des fichiers : "+error.response.data.message,
              status: "error",
              duration: 8000,
              isClosable: true,
              position: "top-right",
              size: "md",
            });
            setFilesUploading(false);
          });
        }
    }
    
    const update_content = (e:any) => {
        setContent(e);
        updateContactDraft('content', e);
      }

    const submit_handler = () => {

        // Check if subject and content are not empty
        if (!subject.current?.value || !content) {
            toast({
                title: "Erreur",
                description: "Veuillez remplir le sujet et le contenu de votre message.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top",
                size: "md",
            });
            return;
        }

        // Update the draft (set draft to false) & send to DB
        updateContactDraft('draft', false);
      
        // Open modal to validate and be redirected to home afterwards
        toast({
            title: "Succès",
            description: "Votre message a bien été envoyé.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "bottom",
            size: "md",
        });
        setSent(true);
    }

return (<Layout>
    <Text mb={5} mt={5} fontWeight={'900'} color={"#b3b3af"} fontSize={['5xl', '5xl', '7xl']} letterSpacing={"-.1rem"}> Contacter le Bureau</Text>

    <Container maxW="4xl" bgColor={"white"} p={8} boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"}>
        <Text mb={3} ms={3} color={"#b44e50"} fontWeight={'800'} fontSize={'sm'}>FORMULAIRE DE CONTACT</Text>
        <Box mb={10} height={'4px'} bgColor={"#940104"} width={'100%'} />

        {sent && <Box>
          
          <Center mt={5} mb={5}>
            <Text fontSize={'lg'} fontWeight={'500'}>Votre message a bien été envoyé.</Text>
          </Center>
        </Box>}

        {!sent && <>
        <FormControl>
            <FormLabel>Sujet de votre message</FormLabel>
            <Input disabled={!loaded} onBlur={updateSubjectHandler} ref={subject} type='text' bgColor={"white"} />
        </FormControl>

        <FormControl mt={5}>
            <FormLabel>Votre message</FormLabel>
            <TextEditor update_callback={(e:any) => {update_content(e)}} content={content} disabled={!loaded} />
        </FormControl>
        
        {!filesUploading &&
            <Grid mt={2} templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }} gap={4}>
                {filenames.map((filename, index) => {
                return (
                    <Flex bgColor={'white'} key={index} border={"1pt solid #e2e2dd"} p={2} rounded={"md"}>
                        <Box onClick={() => {download_file(filename)}} cursor={"pointer"}>{filename.length > 20 ? filename.substring(0, 20) + "..." : filename}</Box>
                        <Spacer />
                        <Box onClick={() => {removeFile(filename);}} ms={2} cursor={"pointer"}><FontAwesomeIcon icon={faTrashCan} /></Box>
                    </Flex>
                )
                })}
            </Grid>
        }

        <Divider mt={5} />

        <Flex>
            <Box mt={3}>
                <FormLabel cursor={'pointer'} mt={3} border={"1pt solid #e2e2dd"} ps={3} pe={3} pt={2} pb={2} htmlFor="upload-file"><FontAwesomeIcon style={{marginRight:'5px'}} icon={faPaperclip} />Ajouter une pièce jointe</FormLabel>
                <input multiple={true} accept={ALLOWED_FORMATS.join(',')} id='upload-file' type="file" name="photo" style={{opacity:0, position:"absolute", zIndex:-10}}
                onChange={handleFileChange}
                disabled={filesUploading}
                />
                <Text me={3} align={"left"} fontSize={"sm"} color={"#4a5568"} mt={1}>Max. 15 Mo, Max. 5 fichiers</Text>
            </Box>
            <Spacer />
            <Button
              isDisabled={!loaded || filesUploading}
              width={'fit-content'} p={6} m={3} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'} onClick={submit_handler}>
                ENVOYER
            </Button>
        </Flex>

        {filesUploading &&
            <Box>
                <Progress size='md' mt={2} isIndeterminate />
                <Center mt={5}>Téléversement de vos fichiers...</Center>
            </Box>
        }

        </>}
    </Container>
</Layout>);
}