import '../../Home.css';
import { Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import PendingDossiers from './PendingDossiers';
import RefusedDossiers from './RefusedDossiers';
import Messages from './Messages';

export interface DossiersProps {}

const Dossiers: React.FC<DossiersProps> = ({}) => {

    return (
    <Tabs position="relative" variant="unstyled" mt={5} isLazy={true}>
        <TabList>
            <Tab fontWeight={800} p={3} fontSize={'sm'}>DEMANDES D'OUVERTURE</Tab>
            <Tab fontWeight={800} p={3} fontSize={'sm'}>DEMANDES REFUSÉES</Tab>
            <Tab fontWeight={800} p={3} fontSize={'sm'}>MESSAGES DES MEMBRES</Tab>
        </TabList>
        <TabIndicator
            mt="-1.5px"
            height="3px"
            bg="#004851"
            borderRadius="1px"
        />
        <TabPanels>
            <TabPanel p={0} pt={8}>
                <PendingDossiers />
            </TabPanel>
            <TabPanel p={0} pt={8}>
                <RefusedDossiers />
            </TabPanel>
            <TabPanel p={0} pt={8}>
                <Messages />
            </TabPanel>
        </TabPanels>
  </Tabs>);
}

export default Dossiers;