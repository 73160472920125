import '../../Home.css';
import { Box, Button, Center, Checkbox, Collapse, Divider, Flex, FormControl, FormHelperText, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Skeleton, Spacer, Tab, TabIndicator, TabList, TabPanel, TabPanels, Table, TableContainer, Tabs, Tbody, Td, Text, Textarea, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import { ICategory } from '../../types';
import { useEffect, useRef, useState } from 'react';
import { deleteCategory, getCategories, getDossierNumberByCategory, newCategory } from '../../API/dossier';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getThreadsNumberFromCategory } from '../../API/thread';

interface DelProps {
    isOpen: boolean;
    onClose: () => void;
    category: ICategory;
    categories: ICategory[];
    submit_callback: Function;
}

const DeletingCategoryModal: React.FC<DelProps> = ({isOpen, onClose, category, categories, submit_callback}) => {
    const [nbDossiers, setNbDossiers] = useState<number>(0);
    const [nbThreads, setNbThreads] = useState<number>(0);
    const [nbFetched, setNbFetched] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<ICategory|null>(categories.filter((cat) => cat._id !== category._id)[0] ?? null);

    const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selected_category = categories.find((category) => category.name === event.target.value);
        if (selected_category)
            setSelectedCategory(selected_category);
    }

    useEffect(() => {
        if (!isOpen) return;

        getDossierNumberByCategory(category._id).then((resp) => {
            setNbDossiers(resp.data.count);
        }).finally(() => {
            getThreadsNumberFromCategory(category._id).then((resp) => {
                setNbThreads(resp.data.count);
                setNbFetched(true);
            });
        });
    }, [isOpen]);

    return <Modal
    onClose={onClose} 
    size={"3xl"} 
    isOpen={isOpen}
    motionPreset='scale'
    closeOnOverlayClick={true}
    >
        <ModalOverlay backdropFilter='blur(5px)' />
        <ModalContent>
            <ModalHeader>
            <Text fontWeight={'900'} color={"#314e89"} fontSize={'xx-large'} letterSpacing={"-.1rem"}>Supprimer la catégorie</Text>
            <Divider mt={3} />
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody mt={0} ps={8} pe={8} pb={8} pt={0}>

                {nbFetched && nbDossiers == 0 && nbThreads == 0 &&
                    <Text mb={2}>
                        Aucun dossier ou thread n'est associé à cette catégorie. Vous pouvez la supprimer sans problème.
                    </Text>
                }
                {nbFetched && (nbDossiers > 0 || nbThreads > 0) &&<>
                    <Text mb={2}>
                        <Text as='span' fontWeight={700}>
                        {nbDossiers} dossier{nbDossiers > 1 ? "s" :""}</Text> {nbDossiers > 1 ? "sont":"est"} associé{nbDossiers > 1 ? "s" : ""} à cette catégorie, et 
                        <Text as='span' fontWeight={700}> {nbThreads} fil{nbThreads > 1 ? "s" : ""}</Text> {nbThreads > 1 ? "sont":"est"} associé{nbThreads > 1 ? "s" : ""} à cette catégorie. Dans quelle catégorie souhaitez-vous déplacer ces dossiers et/ou threads avant de la supprimer ?
                    </Text>

                    {categories.filter((cat) => cat._id !== category._id).length > 0 &&
                        <FormControl mb={4} p={4} pe={0}>
                            <FormLabel fontSize={"sm"}>
                            CATÉGORIE
                            </FormLabel>
                            <Select className='prettyOption' rounded={0} me={2} bgColor={"white"} size='lg' value={selectedCategory?.name || ''} onChange={handleCategoryChange}>
                            {categories.filter((cat) => cat._id !== category._id)
                            .map((cat) => (
                                <option key={cat._id} value={cat.name}>
                                {cat.name}
                                </option>
                            ))}
                            </Select>
                        </FormControl>
                    }
                    {categories.filter((cat) => cat._id !== category._id).length == 0 && <Center mt={5} mb={5}>
                        <Text fontSize={"sm"} color={"gray.500"}>Il n'y a aucune autre catégorie. Veuillez en créer d'autres d'abord, avant de supprimer celle-ci.</Text>
                    </Center>}
                </>}
                <Flex>
                    <Spacer />
                    {categories.filter((cat) => cat._id !== category._id).length > 0 &&
                        <Button 
                        onClick={() => {submit_callback(selectedCategory); onClose();}}
                        p={6} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" mt={5} fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                        me={2}>
                            SUPPRIMER
                        </Button>
                    }
                </Flex>
            </ModalBody>
        </ModalContent>
    </Modal>
}

export interface SiteProps {}
const Site: React.FC<SiteProps> = ({}) => {
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>("");
    const newCategoryTitleRef = useRef<HTMLInputElement>(null);
    const newCategoryDescriptionRef = useRef<HTMLTextAreaElement>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const newCategory_coll = useDisclosure();
    const delCategory_coll = useDisclosure();
    const toast = useToast();
    const [requestLoading, setRequestLoading] = useState<boolean>(false);
    useEffect(() => {
        getCategories().then((resp) => {
            setCategories(resp.data.categories);
            setLoading(false);
        }).catch((err) => {
            toast({
                title: "Erreur",
                description: "Impossible de récupérer les catégories.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        });
    }, []);

    const handleCheckboxChange = (category_id: string) => {
        if (selectedCategory == category_id) {
            setSelectedCategory("");
        } else {
            setSelectedCategory(category_id);
        }
    }

    const add_category = () => {
        // Check if the title is not empty
        if (!newCategoryTitleRef.current?.value) {
            toast({
                title: "Erreur",
                description: "Le nom de la catégorie ne peut pas être vide.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        // Send the request
        setRequestLoading(true);
        newCategory(newCategoryTitleRef.current!.value, newCategoryDescriptionRef.current!.value).then((resp) => {
            const category: ICategory = resp.data.category;
            setCategories([...categories, category]);
            newCategory_coll.onClose();
            newCategoryTitleRef.current!.value = "";
            newCategoryDescriptionRef.current!.value = "";
            toast({
                title: "Catégorie ajoutée",
                description: "La catégorie a été ajoutée avec succès.",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
        }).catch((err) => {
            toast({
                title: "Erreur",
                description: "Impossible d'ajouter la catégorie.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }).finally(() => {
            setRequestLoading(false);
        });
    }

    const delete_category = (replacement:ICategory|null=null) => {
        if (selectedCategory == "") {
            toast({
                title: "Erreur",
                description: "Veuillez sélectionner une catégorie.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        setRequestLoading(true);
        deleteCategory(selectedCategory, replacement).then((resp) => {
            setCategories(categories.filter((category) => category._id !== selectedCategory));
            setSelectedCategory("");
            toast({
                title: "Catégorie supprimée",
                description: "La catégorie a été supprimée avec succès.",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
        }).catch((err) => {
            toast({
                title: "Erreur",
                description: "Impossible de supprimer la catégorie.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }).finally(() => {
            setRequestLoading(false);
        });
    }

    return (<>
    {delCategory_coll.isOpen &&
        <DeletingCategoryModal
            isOpen={delCategory_coll.isOpen}
            onClose={delCategory_coll.onClose}
            category={categories.find((category) => category._id === selectedCategory)!}
            categories={categories}
            submit_callback={delete_category} />
    }
    <Tabs position="relative" variant="unstyled" mt={5} isLazy={true}>
        <TabList>
            <Tab fontWeight={800} p={3} fontSize={'sm'}>CATÉGORIES</Tab>
        </TabList>
        <TabIndicator
            mt="-1.5px"
            height="3px"
            bg="#004851"
            borderRadius="1px"
        />
        <TabPanels>
            <TabPanel p={0} pt={8}>
                <Text mb={3}>Liste des catégories du forum et des dossiers :</Text>
                <TableContainer bgColor={"white"} border={"1pt solid #e2e2dd"} p={5}>
                <Table variant='simple' size='sm'>
                    <Thead>
                        <Tr>
                            <Th>Nom de la catégorie</Th>
                            <Th>Description</Th>
                            <Th isNumeric></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {categories.map((category, index) => (
                            <Tr key={index}>
                                <Td>{category.name}</Td>
                                <Td>{category.description}</Td>
                                <Td isNumeric>
                                    <Checkbox
                                        isChecked={selectedCategory == category._id}
                                        onChange={() => handleCheckboxChange(category._id)}
                                    />
                                </Td>
                            </Tr>
                        ))}
                        {!loading && categories.length === 0 && (
                            <Tr>
                                <Td colSpan={3}>Aucune catégorie pour l'instant.</Td>
                            </Tr>
                        )} 
                        {loading && 
                        [1, 2, 3, 4].map((index) => (
                            <Tr key={index}>
                                <Td><Skeleton height={"15px"} 
                                    width={
                                    Math.floor(Math.random() * 30) + 70 + "px"} /></Td>
                                <Td></Td>
                            </Tr>))
                        }
                    </Tbody>
                </Table>
            </TableContainer>

            <Collapse in={newCategory_coll.isOpen} animateOpacity>
                <Box mt={5} border={"1pt solid #e2e2dd"} bgColor={"white"} p={5}>
                    <FormControl>
                        <FormLabel>Nom de la catégorie</FormLabel>
                        <Input maxLength={255} ref={newCategoryTitleRef} type="text" />
                        <FormHelperText>Max. 255 caractères</FormHelperText>
                        <FormLabel mt={3}>Description <Text as={"span"} fontStyle={'italic'}>(optionnel)</Text></FormLabel>
                        <Textarea maxLength={255} ref={newCategoryDescriptionRef} />
                        <FormHelperText>Max. 255 caractères</FormHelperText>
                    </FormControl>
                    <Flex>
                        <Spacer />
                        <Button 
                            isDisabled={requestLoading || loading}
                            mt={3}
                            onClick={add_category}
                            p={6} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                            rightIcon={<FontAwesomeIcon icon={faPlus} />}
                            >
                            AJOUTER
                        </Button>
                    </Flex>
                </Box>
            </Collapse>
            
            <Flex mt={5} me={5}>
                <Spacer />
                
                <Button 
                    onClick={() => {
                        newCategory_coll.onToggle();
                    }}
                    isDisabled={loading || requestLoading}
                    p={6} color={"white"} _hover={{backgroundColor:"#006977"}} bgColor={"#004851"} size="md" fontSize={'10pt'} fontWeight={'700'} letterSpacing={'.8px'}
                    rightIcon={<FontAwesomeIcon icon={newCategory_coll.isOpen ? faMinus : faPlus} />}
                    >
                    NOUVELLE CATÉGORIE
                </Button>
                <Button
                    ms={3}
                    p={6}
                    color={"white"}
                    _hover={{ backgroundColor: "#d92a2e" }}
                    bgColor={"#b32326"}
                    size="md"
                    fontSize={'10pt'}
                    fontWeight={'700'}
                    letterSpacing={'.8px'}
                    onClick={delCategory_coll.onOpen}
                    isDisabled={requestLoading || loading || selectedCategory == ""}
                    rightIcon={<FontAwesomeIcon icon={faTrash} />}
                >
                    SUPPRIMER
                </Button>
            </Flex>

            </TabPanel>
        </TabPanels>
  </Tabs></>);
}

export default Site;