import { Box, Divider, Skeleton, SkeletonText } from "@chakra-ui/react";

export function ArticleCardSkeleton(isLast:boolean=false) {
    return <Box position={"relative"} me={isLast ? 0 : 10}>
    <Skeleton position={"absolute"} width={"320px"} height={"180px"} top={"-100px"} left={"-15px"} zIndex={-1} />
    
    <Box boxShadow={"rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"} border={"1pt solid #e2e2dd"} bgColor={"white"} zIndex={2} maxWidth={"300px"} minWidth={"290px"} minHeight={"500px"} maxHeight={"500px"} style={{display:"flex", flexDirection:"column"}}>
      <Box p={8} width={"100%"} height={"100%"}>

        {/* TITLE */}
        <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='5' />

        {/* CONTENT */}
        <Box maxHeight={"270px"} overflowY={"hidden"}>
          <SkeletonText mt='8' noOfLines={4} spacing='4' skeletonHeight='2' />
        </Box>

        <Divider mt={4} />
      </Box>
    </Box>
  </Box>
}