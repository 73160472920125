import React, { useEffect, useState } from 'react';
import '../Home.css';
import { Box, Button, Center, Circle, Container, Divider, Flex, Grid, HStack, Heading, Image, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spacer, Stack, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';
import { Layout } from '../Layout';
import { CheckIcon, PhoneIcon, SearchIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faBullhorn, faComment, faEye, faFile, faFolderClosed, faFolderOpen, faFolderPlus, faLink } from '@fortawesome/free-solid-svg-icons';
import LoremIpsum from 'react-lorem-ipsum';
import { Link as ReactLink, useLocation, useNavigate} from 'react-router-dom';
import { IArticle, IPoll } from '../types';
import { getArticles } from '../API/news';
import ReactPaginate from 'react-paginate';
import Pagination from '../components/Pagination';
import { getAllPolls, getPolls } from '../API/dossier';
import moment from 'moment';
import { Poll } from '../Poll/Poll';

interface Props {
}

export const Polls: React.FunctionComponent<Props> = (props: Props) => {
  const [openPolls, setOpenPolls] = useState<{ [dossierId: string]: IPoll[] }>({});
  const [pollsLoading, setPollsLoading] = useState<boolean>(true);
  const [mapping, setMapping] = useState<{ [key: string]: { name: string; slug: string } }>({});
  const [currentDossierIndex, setCurrentDossierIndex] = useState<number>(0);
  const [nbDossiers, setnbDossiers] = useState<number>(0);
  const dossierIds = Object.keys(openPolls);

  useEffect(() => {
    getAllPolls().then((response) => {
      if (response.status === 200) {
        const polls: IPoll[] = response.data.polls;
        const mapping: { [key: string]: { name: string; slug: string } } = response.data.dossier_mapping;

        // Filter open polls
        const openPolls = polls.filter((poll) => moment(poll.deadline).isAfter(moment()));

        // Group open polls by matching the poll's ID with the mapping
        const groupedPolls: { [dossierSlug: string]: IPoll[] } = {};
        openPolls.forEach((poll) => {
          const pollId = poll._id;

          // Find the dossier ID and details from the mapping
          const dossierDetails = mapping[pollId];
          if (dossierDetails) {
            const dossierSlug = dossierDetails.slug;

            if (!groupedPolls[dossierSlug]) {
              groupedPolls[dossierSlug] = [];
            }
            groupedPolls[dossierSlug].push(poll);
          }
        });

        setOpenPolls(groupedPolls);
        setMapping(mapping);
        setPollsLoading(false);

        setnbDossiers(Object.keys(groupedPolls).length);
      }
    });
  }, []);

  const handlePrevious = () => {
    if (currentDossierIndex > 0) {
      setCurrentDossierIndex(currentDossierIndex - 1);
      // scroll to the top of the page
      window.scrollTo(0, 0);
    }
  };

  const handleNext = () => {
    if (currentDossierIndex < dossierIds.length - 1) {
      setCurrentDossierIndex(currentDossierIndex + 1);
      window.scrollTo(0, 0);
    }
  };

  const navigate = useNavigate();

  return (
    <>
      <Layout>
        <Text cursor={'default'} fontWeight={'900'} color={"#b3b3af"} fontSize={['5xl', '5xl', '7xl']} letterSpacing={"-.1rem"} mb={5} mt={5}>Sondages</Text>
        
        <Box mb={4}>
          <Text cursor={'default'} mb={3} ms={3} color={"#00707f"} fontWeight={'800'} fontSize={'sm'}>SONDAGES OUVERTS</Text>
          <Box height={'4px'} bgColor={"#00707f"} width={'100%'} />
        </Box>

        {nbDossiers > 0 && 
        <Text ms={2} mb={5}>Il y a actuellement {nbDossiers} dossier{nbDossiers > 1 ? 's' : ''} ouvert{nbDossiers > 1 ? 's' : ''} dont vous pouvez parcourir les sondages ouverts en utilisant les boutons au bas de la page.</Text>
        }
        
        {dossierIds.length > 0 ? (
          <Box mb={10}>
            {openPolls[dossierIds[currentDossierIndex]].map((poll, index) => (
              <Box key={poll._id} mb={'25px'}>
                {index == 0 && <Box mb={3} ms={2} width={'fit-content'}>
                  <Text 
                    onClick={() => navigate(`/dossiers/${mapping[poll._id].slug}`)} 
                    cursor={'pointer'} fontWeight={800} color={'#00707f'} fontSize={'2xl'}>
                      {mapping[poll._id].name}<FontAwesomeIcon icon={faLink} />
                    </Text>
                  </Box>}
                <Poll
                  dossier_slug={dossierIds[currentDossierIndex]}
                  poll={poll}
                />
              </Box>
            ))}
          </Box>
        ) : (
          !pollsLoading && <Center><Text ms={5} mb={10}>Il n'y a pas de sondages ouverts pour le moment.</Text></Center>
        )}

        <Box display="flex" justifyContent="space-between" mt={10}>
          <Button
            onClick={handlePrevious}
            isDisabled={currentDossierIndex === 0 || dossierIds.length === 0}
            colorScheme="teal"
            size={'lg'}
          >
            Dossier précédent
          </Button>
          <Button
            onClick={handleNext}
            isDisabled={currentDossierIndex >= dossierIds.length - 1 || dossierIds.length === 0}
            colorScheme="teal"
            size={'lg'}
          >
            Dossier suivant
          </Button>
        </Box>

        <Box height={'150px'} />
      </Layout>
    </>
  );
};

export default Polls;